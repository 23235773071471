import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
  CSelect,
  CRow,
  CNavbar,
  CModal,
  CForm,
  CSwitch,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CTextarea,
  CContainer,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CAlert,
  CImg
} from '@coreui/react'
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { DataTable, ReactSelect, ReactDatePicker } from "src/components";
import { FUNCIndoDate, FUNCMonthToString, FUNCNumberFormat, FUNCSetFullName, FUNCYearToString } from "src/config";
import { setBiayaKeanggotaans, setDataCombo, setDataNewAnggota, setDataNewAnggotas } from "src/config/redux/action";
import './ViewDetil.css';

const ViewRegistrasiDetil = ({data}) => {
    useEffect(() => {
        if(data?.id != 0){
            reset();
            dispatch(setBiayaKeanggotaans(data.id));
            showModalViewRegistrasi();
            setHeadingForm("Lihat Detil Calon Anggota")
        }
    }, [data])
  const { register:register, handleSubmit:handleSubmit, formState: { errors:errors }, control:control, setValue:setValue, getValues:getValues, reset:reset, watch } = useForm();
  const { 
    fields:filePersyaratanRegistrasiFields,
    append:filePersyaratanRegistrasiAppend, 
    prepend:filePersyaratanRegistrasiPrepend, 
    remove:filePersyaratanRegistrasiRemove,
    swap:filePersyaratanRegistrasiSwap,
    move:filePersyaratanRegistrasiMove,
    insert:filePersyaratanRegistrasiInsert,
    update:filePersyaratanRegistrasiUpdate
   } = useFieldArray({
    control,
    name: "DataFilePersyaratans"
  });
  const { dataNewAnggota, dataBiayaKeanggotaan, modalViewRegistrasi } = useSelector(
    (state) => state.registrasiReducer
  );
  const { 
    dataComboJenisKelamin,
    dataComboProvinsi,
    dataComboPendidikan,
    dataComboAgama,
    dataComboPekerjaan,
    dataComboWargaNegara,
    dataComboKabkot,
    dataComboJenisIdentitas,
   } = useSelector((state) => state.comboReducer);

  const dispatch = useDispatch();
  const onChangeProvinsi = (IdProvinsi) => {
    dispatch(setDataCombo("DATA_COMBO_KABKOT",{idProvinsi:IdProvinsi}));
  }
  
  useEffect(() => {
    dispatch(setDataCombo("DATA_COMBO_TIPE_FILE"));
    dispatch(setDataCombo("DATA_COMBO_JENIS_IDENTITAS"));

    dispatch(setDataCombo("DATA_COMBO_JENIS_KELAMIN"));
    dispatch(setDataCombo("DATA_COMBO_PROVINSI"));
    dispatch(setDataCombo("DATA_COMBO_PENDIDIKAN"));
    dispatch(setDataCombo("DATA_COMBO_AGAMA"));
    dispatch(setDataCombo("DATA_COMBO_PEKERJAAN"));
    dispatch(setDataCombo("DATA_COMBO_WARGA_NEGARA"));

    dispatch(setDataCombo("DATA_COMBO_PERIODE_SIMPANAN_WAJIB"));
  }, [dispatch]);

  useEffect(() => {
    if(dataNewAnggota){
        setValue("id",dataNewAnggota.id);
        setValue("firstName",dataNewAnggota.firstName);
        setValue("middleName",dataNewAnggota.middleName);
        setValue("lastName",dataNewAnggota.lastName);
        setValue("email",dataNewAnggota.email);
        setValue("address",dataNewAnggota.address);
        setValue("phoneNumber",dataNewAnggota.phoneNumber);
        setValue("mobileNumber",dataNewAnggota.mobileNumber);
        setValue("nomorIdentitas",dataNewAnggota.nomorIdentitas);
        setValue("idJenisIdentitas",{value:dataNewAnggota.identitas.id,label:dataNewAnggota.identitas.name});

        if(dataNewAnggota.profil != null){
            setValue("IdJenisKelamin",{value:dataNewAnggota.profil.jenisKelamin.id,label:dataNewAnggota.profil.jenisKelamin.name});
            setValue("IdAgama",{value:dataNewAnggota.profil.agama.id,label:dataNewAnggota.profil.agama.name});
            setValue("Alamat",dataNewAnggota.profil.alamat);
            setValue("AlamatAhliWaris",dataNewAnggota.profil.alamatAhliWaris);
            setValue("HubunganAhliWaris",dataNewAnggota.profil.hubunganAhliWaris);
            setValue("IdKabkot",{value:dataNewAnggota.profil.kabkot.id,label:dataNewAnggota.profil.kabkot.name});
            setValue("KodePos",dataNewAnggota.profil.kodePos);
            setValue("NamaAhliWaris",dataNewAnggota.profil.namaAhliWaris);
            setValue("NamaIbuKandung",dataNewAnggota.profil.namaIbuKandung);
            setValue("NamaPekerjaan",dataNewAnggota.profil.namaPekerjaan);
            setValue("NamaPerusahaan",dataNewAnggota.profil.namaPerusahaan);
            setValue("NoHandphoneAhliWaris",dataNewAnggota.profil.noHandphoneAhliWaris);
            setValue("IdPekerjaan",{value:dataNewAnggota.profil.pekerjaan.id,label:dataNewAnggota.profil.pekerjaan.name});
            setValue("IdPendidikan",{value:dataNewAnggota.profil.pendidikan.id,label:dataNewAnggota.profil.pendidikan.name});
            setValue("IdProvinsi",{value:dataNewAnggota.profil.provinsi.id,label:dataNewAnggota.profil.provinsi.name});
            setValue("TanggalLahir",new Date(dataNewAnggota.profil.tanggalLahir));
            setValue("TempatLahir",dataNewAnggota.profil.tempatLahir);
            setValue("IdWargaNegara",{value:dataNewAnggota.profil.wargaNegara.id,label:dataNewAnggota.profil.wargaNegara.name});
            if(dataNewAnggota.profil.isKoperasiLain != null){
                setValue("namaKoperasiLain", dataNewAnggota.profil.namaKoperasiLain);
            }
        }

        filePersyaratanRegistrasiRemove();
        dataNewAnggota.files && dataNewAnggota.files.map((item,index) => {
            filePersyaratanRegistrasiAppend({Id:item.id,Nama:item.judul,Jenis:item.jenis.id,Judul:item.nam,NamaFile:item.nama,FormFile:""});
        }); 
    }
  }, [dataNewAnggota])


  const [headingForm, setHeadingForm] = useState(false);

  const showModalViewRegistrasi = () => dispatch({ type: 'MODAL_VIEW_REGISTRASI', payload: !modalViewRegistrasi });

  return (
    <div>
      {/* SECTION MODAL Calon Anggota  */}
      <CModal
        show={modalViewRegistrasi}
        onClose={showModalViewRegistrasi}
        size={"xl"}
    >
        <CModalHeader closeButton>
            {headingForm}
        </CModalHeader>
        <CModalBody>
            <CContainer>
                <form className="form-horizontal">
                    <fieldset disabled aria-readonly>
                        <input {...register("id")} name="id" type={"hidden"} className="form-control"  />
                        {dataNewAnggota && <div className="form-group">
                            <div className="form-row">
                                <div className="col-md-3">
                                    <CLabel className={"font-weight-bold"}>Status</CLabel>
                                    <div>{dataNewAnggota.status}</div>
                                </div>
                                <div className="col-md-6">
                                    <CLabel className={"font-weight-bold"}>Status Anggota</CLabel>
                                    <div style={{overflowY:"scroll", height:70, overflowX: "hidden"}}>
                                    {dataNewAnggota.statusMember.status} {dataNewAnggota.statusMember.statusProses} {dataNewAnggota.statusMember.catatan}
                                    </div>
                                    {/* <div className='form-group'>
                                        <textarea name="catatanStatusMember" className="form-control" {...register("catatanStatusMember", { required: 'Kolom wajib diisi' })}>{dataNewAnggota.statusMember.catatan}</textarea>
                                        {errors.catatanStatusMember && <span className="text-danger">{errors.catatanStatusMember?.message}</span>}
                                    </div> */}
                                </div>
                                <div className="col-md-3">
                                    <CLabel className={"font-weight-bold"}>Role</CLabel>
                                    <div>{dataNewAnggota.role?.name}</div>
                                </div>
                            </div>
                        </div>}
                        <div className='form-group'>
                            <CLabel>Nama Lengkap</CLabel>
                            <CRow>
                                <CCol>
                                    <div>
                                        <input name="firstName" className="form-control" placeholder="Nama Depan..." {...register("firstName", { required: 'Kolom wajib diisi' })}  />
                                        {errors.firstName && <span className="text-danger">{errors.firstName?.message}</span>}
                                    </div>
                                </CCol>
                                <CCol>
                                    <div>
                                        <input name="middleName" className="form-control" placeholder="Nama Tengah..." {...register("middleName")}  />
                                        {errors.middleName && <span className="text-danger">{errors.middleName?.message}</span>}
                                    </div>
                                </CCol>
                                <CCol>
                                    <div>
                                        <input name="lastName" className="form-control" placeholder="Nama Belakang..." {...register("lastName")}  />
                                        {errors.lastName && <span className="text-danger">{errors.lastName?.message}</span>}
                                    </div>
                                </CCol>
                            </CRow>
                        </div>
                        <CRow>
                            <CCol>
                                <div className="form-group">
                                    <CLabel>Jenis Identitas</CLabel>
                                    <Controller
                                        control={control}
                                        name={`idJenisIdentitas`}
                                        render={({field, value, name, ref }) => (
                                            <ReactSelect
                                                inputRef={ref}
                                                value={getValues(`idJenisIdentitas`)}
                                                options={dataComboJenisIdentitas}
                                                onChange={(val) => { field.onChange(val)}}
                                            />
                                        )}
                                        rules={{required:false}}
                                    />
                                    {errors?.idJenisIdentitas && <span className="text-danger">{"kolom ini wajib di isi"}</span>}
                                </div>
                            </CCol>
                            <CCol>
                                <div className='form-group'>
                                    <CLabel>Nomor Identitas</CLabel>
                                    <input name="nomorIdentitas" className="form-control" placeholder="Ketikkan Nomor Identitas..." {...register("nomorIdentitas", { required: 'Kolom wajib diisi' })}  />
                                    {errors.nomorIdentitas && <span className="text-danger">{errors.nomorIdentitas?.message}</span>}
                                </div>
                            </CCol>
                        </CRow>
                        <div className="form-row">
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <CLabel>Email</CLabel>
                                    <input name="email" className="form-control" placeholder="Ketikkan Email..." {...register("email", { required: 'Kolom wajib diisi' })}  />
                                    {errors.email && <span className="text-danger">{errors.email?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Alamat</CLabel>
                                    <textarea name="address" className="form-control" placeholder="Ketikkan Alamat Lengkap..." {...register("address", { required: 'Kolom wajib diisi' })}></textarea>
                                    {errors.address && <span className="text-danger">{errors.address?.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <CLabel>No Telpon</CLabel>
                                    <input name="phoneNumber" className="form-control" placeholder="Ketikkan No Telpon..." {...register("phoneNumber", { required: 'Kolom wajib diisi' })}  />
                                    {errors.phoneNumber && <span className="text-danger">{errors.phoneNumber?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>No HP</CLabel>
                                    <input name="mobileNumber" className="form-control" placeholder="Ketikkan No HP..." {...register("mobileNumber", { required: 'Kolom wajib diisi' })}  />
                                    <span id="mobileNumberHelp" class="form-text text-muted">Contoh: +628123456789</span>
                                    {errors.mobileNumber && <span className="text-danger">{errors.mobileNumber?.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className='alert alert-primary mt-3'>
                                <h5> Dokumen Calon Anggota</h5>
                            </div>
                            <div style={{whiteSpace:"nowrap",overflowx:"auto",overflowy:"auto",maxHeight:500}} className="table-responsive mb-3">
                                <table className="table table-striped table-bordered table-hover nowrap mb-3" style={{width:"100%"}}>
                                    <thead>
                                        <tr>
                                            <th>Nama File</th>
                                            <th>File</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {   
                                        dataNewAnggota && dataNewAnggota.files && dataNewAnggota.files.map((item,i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.jenis.name}</td>
                                                <td>{item.nama != null ? <a href={item.nama} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</td>
                                            </tr>
                                            );
                                        })
                                    }
                                    {
                                        dataNewAnggota?.formRegistrasiTtd && 
                                    <tr>
                                        <td>
                                            File Form Registrasi
                                        </td>
                                        <td>
                                             <a href={dataNewAnggota.formRegistrasiTtd} className="badge badge-info p-2" target={"_blank"}>Lihat File</a>
                                        </td>
                                    </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                            <div className='alert alert-primary'>
                                <h5>Biodata Diri</h5>
                            </div>
                            <CRow>
                                <CCol>
                                <div className="form-group">
                                    <CLabel>Jenis Kelamin</CLabel>
                                    <Controller
                                    control={control}
                                    name={`IdJenisKelamin`}
                                    render={({field, value, name, ref }) => (
                                        <ReactSelect
                                        inputRef={ref}
                                        value={getValues(`IdJenisKelamin`)}
                                        options={dataComboJenisKelamin}
                                        onChange={(val) => { field.onChange(val)}}
                                        />
                                    )}
                                    rules={{required:false}}
                                    />
                                    {errors?.IdJenisKelamin && <span className="text-danger">{"kolom ini wajib di isi"}</span>}
                                </div>
                                </CCol>
                                <CCol>
                                <div className="form-group">
                                    <CLabel>Agama</CLabel>
                                    <Controller
                                    control={control}
                                    name={`IdAgama`}
                                    render={({field, value, name, ref }) => (
                                        <ReactSelect
                                        inputRef={ref}
                                        value={getValues(`IdAgama`)}
                                        options={dataComboAgama}
                                        onChange={(val) => { field.onChange(val)}}
                                        />
                                    )}
                                    rules={{required:false}}
                                    />
                                    {errors?.IdAgama && <span className="text-danger">{"kolom ini wajib di isi"}</span>}
                                </div>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                <div className='form-group'>
                                    <CLabel>Tempat Lahir</CLabel>
                                    <input name="TempatLahir" className="form-control" placeholder="Tempat lahir..." {...register("TempatLahir", { required: 'Kolom wajib diisi' })}  />
                                    {errors.TempatLahir && <span className="text-danger">{errors.TempatLahir?.message}</span>}
                                </div>
                                </CCol>
                                <CCol>
                                <div className='form-group'>
                                    <CLabel>Tanggal Lahir</CLabel>
                                    <Controller
                                    control={control}
                                    defaultValue={""}
                                    name="TanggalLahir"
                                    render={({field, value, name, ref }) => (
                                        <ReactDatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={getValues("TanggalLahir")}
                                            onChange={(date) => field.onChange(date)}
                                            placeholderText="- Pilih Tanggal -"
                                        />
                                    )}
                                    rules={{required:true}}
                                    />
                                    {errors.TanggalLahir && <span className="text-danger">{errors.TanggalLahir?.message}</span>}
                                </div>
                                </CCol>
                            </CRow>
                            <div className='form-group'>
                                <CLabel>Alamat</CLabel>
                                <div style={{ backgroundColor:"#c4a330", color:"white"}} dangerouslySetInnerHTML={{ __html: watch("Alamat")}} className="form-control"></div>
                                {/* <textarea name="Alamat" className="form-control" placeholder="Ketikkan Alamat..." {...register("Alamat", { required: 'Kolom wajib diisi' })}></textarea>
                                {errors.Alamat && <span className="text-danger">{errors.Alamat?.message}</span>} */}
                            </div>
                            <CRow>
                                <CCol>
                                <div className='form-group'>
                                    <CLabel>Nama Ibu Kandung</CLabel>
                                    <input name="NamaIbuKandung" className="form-control" placeholder="Nama Ibu Kandung..." {...register("NamaIbuKandung", { required: 'Kolom wajib diisi' })}  />
                                    {errors.NamaIbuKandung && <span className="text-danger">{errors.NamaIbuKandung?.message}</span>}
                                </div>
                                </CCol>
                                <CCol>
                                <div className="form-group">
                                    <CLabel>Warga Negara</CLabel>
                                    <Controller
                                    control={control}
                                    name={`IdWargaNegara`}
                                    render={({field, value, name, ref }) => (
                                        <ReactSelect
                                        inputRef={ref}
                                        value={getValues(`IdWargaNegara`)}
                                        options={dataComboWargaNegara}
                                        onChange={(val) => { field.onChange(val)}}
                                        />
                                    )}
                                    rules={{required:false}}
                                    />
                                    {errors?.IdWargaNegara && <span className="text-danger">{"kolom ini wajib di isi"}</span>}
                                </div>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                <div className="form-group">
                                    <CLabel>Pendidikan</CLabel>
                                    <Controller
                                    control={control}
                                    name={`IdPendidikan`}
                                    render={({field, value, name, ref }) => (
                                        <ReactSelect
                                        inputRef={ref}
                                        value={getValues(`IdPendidikan`)}
                                        options={dataComboPendidikan}
                                        onChange={(val) => { field.onChange(val)}}
                                        />
                                    )}
                                    rules={{required:false}}
                                    />
                                    {errors?.IdPendidikan && <span className="text-danger">{"kolom ini wajib di isi"}</span>}
                                </div>
                                </CCol>
                                <CCol>
                                <div className="form-group">
                                    <CLabel>Pekerjaan</CLabel>
                                    <Controller
                                    control={control}
                                    name={`IdPekerjaan`}
                                    render={({field, value, name, ref }) => (
                                        <ReactSelect
                                        inputRef={ref}
                                        value={getValues(`IdPekerjaan`)}
                                        options={dataComboPekerjaan}
                                        onChange={(val) => { field.onChange(val)}}
                                        />
                                    )}
                                    rules={{required:false}}
                                    />
                                    {errors?.IdPekerjaan && <span className="text-danger">{"kolom ini wajib di isi"}</span>}
                                </div>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                <div className='form-group'>
                                    <CLabel>Nama Pekerjaan</CLabel>
                                    <input name="NamaPekerjaan" className="form-control" placeholder="Nama Pekerjaan..." {...register("NamaPekerjaan", { required: 'Kolom wajib diisi' })}  />
                                    {errors.NamaPekerjaan && <span className="text-danger">{errors.NamaPekerjaan?.message}</span>}
                                </div>
                                </CCol>
                                <CCol>
                                <div className='form-group'>
                                    <CLabel>Nama Perusahaan</CLabel>
                                    <input name="NamaPerusahaan" className="form-control" placeholder="Nama Perusahaan..." {...register("NamaPerusahaan", { required: 'Kolom wajib diisi' })}  />
                                    {errors.NamaPerusahaan && <span className="text-danger">{errors.NamaPerusahaan?.message}</span>}
                                </div>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                <div className="form-group">
                                    <CLabel>Provinsi</CLabel>
                                    <Controller
                                    control={control}
                                    name={`IdProvinsi`}
                                    render={({field, value, name, ref }) => (
                                        <ReactSelect
                                        inputRef={ref}
                                        value={getValues(`IdProvinsi`)}
                                        options={dataComboProvinsi}
                                        onChange={(val) => { field.onChange(val); onChangeProvinsi(val.value) } }
                                        />
                                    )}
                                    rules={{required:false}}
                                    />
                                    {errors?.IdProvinsi && <span className="text-danger">{"kolom ini wajib di isi"}</span>}
                                </div>
                                </CCol>
                                <CCol>
                                <div className="form-group">
                                    <CLabel>Kabupaten/Kota</CLabel>
                                    <Controller
                                    control={control}
                                    name={`IdKabkot`}
                                    render={({field, value, name, ref }) => (
                                        <ReactSelect
                                        inputRef={ref}
                                        value={getValues(`IdKabkot`)}
                                        options={dataComboKabkot}
                                        onChange={(val) => { field.onChange(val)}}
                                        />
                                    )}
                                    rules={{required:false}}
                                    />
                                    {errors?.IdKabkot && <span className="text-danger">{"kolom ini wajib di isi"}</span>}
                                </div>
                                </CCol>
                            </CRow>
                            <div className='form-group'>
                                <CLabel>Kode Pos</CLabel>
                                <input name="KodePos" className="form-control" placeholder="Kode Pos..." {...register("KodePos", { required: 'Kolom wajib diisi' })}  />
                                {errors.KodePos && <span className="text-danger">{errors.KodePos?.message}</span>}
                            </div>
                            <CRow>
                                <CCol>
                                    <div className='form-group'>
                                        <CLabel>Nama Ahli Waris</CLabel>
                                        <input name="NamaAhliWaris" className="form-control" placeholder="Nama Ahli Waris..." {...register("NamaAhliWaris", { required: 'Kolom wajib diisi' })}  />
                                        {errors.NamaAhliWaris && <span className="text-danger">{errors.NamaAhliWaris?.message}</span>}
                                    </div>
                                </CCol>
                                <CCol>
                                    <div className='form-group'>
                                        <CLabel>Hubungan Ahli Waris</CLabel>
                                        <input name="HubunganAhliWaris" className="form-control" placeholder="Hubungan Ahli Waris..." {...register("HubunganAhliWaris", { required: 'Kolom wajib diisi' })}  />
                                        {errors.HubunganAhliWaris && <span className="text-danger">{errors.HubunganAhliWaris?.message}</span>}
                                    </div>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                <div className='form-group'>
                                    <CLabel>Alamat Ahli Waris</CLabel>
                                    <textarea name="AlamatAhliWaris" className="form-control" placeholder="Ketikkan Alamat Ahli Waris..." {...register("AlamatAhliWaris", { required: 'Kolom wajib diisi' })}></textarea>
                                    {errors.AlamatAhliWaris && <span className="text-danger">{errors.AlamatAhliWaris?.message}</span>}
                                </div>
                                </CCol>
                                <CCol>
                                <div className='form-group'>
                                    <CLabel>No Handphone Ahli Waris</CLabel>
                                    <input name="NoHandphoneAhliWaris" className="form-control" placeholder="No Handphone Ahli Waris..." {...register("NoHandphoneAhliWaris", { required: 'Kolom wajib diisi' })}  />
                                    {errors.NoHandphoneAhliWaris && <span className="text-danger">{errors.NoHandphoneAhliWaris?.message}</span>}
                                </div>
                                </CCol>
                            </CRow>
                            { dataNewAnggota?.profil?.isKoperasiLain && 
                                <div className='form-group'>
                                    <CLabel>Nama Koperasi Lain</CLabel>
                                    <input name="namaKoperasiLain" className="form-control" placeholder="Nama Koperasi Lain ..." {...register("namaKoperasiLain")}/>
                                    {errors.namaKoperasiLain && <span className="text-danger">{errors.namaKoperasiLain?.message}</span>}
                                </div>
                            }

                            {dataNewAnggota && <div className="form-group">
                                <div className='alert alert-primary mt-3'>
                                    <h5>Simpanan Calon Anggota</h5>
                                </div>
                                <div style={{whiteSpace:"nowrap",overflowx:"auto",overflowy:"auto",maxHeight:500}} className="table-responsive mb-3">
                                    <table className="table table-striped table-bordered table-hover nowrap mb-3" style={{width:"100%"}}>
                                        <thead>
                                            <tr>
                                            <th>Rupiah Pokok</th>
                                            <th>Rupiah Wajib</th>
                                            <th className="alert-info">Saldo Total</th>
                                            <th>Tunggakan Pokok</th>
                                            <th>Tunggakan Wajib</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Rp. {FUNCNumberFormat(dataNewAnggota.simpanan.rpTotalPokok)}</td>
                                                <td>Rp. {FUNCNumberFormat(dataNewAnggota.simpanan.rpTotalWajib)}</td>
                                                <td className="alert-info">Rp. {FUNCNumberFormat(dataNewAnggota.simpanan.rpTotalPokok + dataNewAnggota.simpanan.rpTotalWajib)}</td>
                                                <td>Rp. {FUNCNumberFormat(dataNewAnggota.simpanan.tgkPokok)}</td>
                                                <td>Rp. {FUNCNumberFormat(dataNewAnggota.simpanan.tgkWajib)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        
                            {dataBiayaKeanggotaan && dataBiayaKeanggotaan.details && dataBiayaKeanggotaan.details.length > 0 && <div className="form-group">
                                <div className='alert alert-primary mt-3'>
                                    <h5>Detil Simpanan Anggota</h5>
                                </div>
                                <div style={{whiteSpace:"nowrap",overflowx:"auto",overflowy:"auto",maxHeight:500}} className="table-responsive mb-3">
                                    <table className="table table-striped table-bordered table-hover nowrap mb-3" style={{width:"100%"}}>
                                        <thead>
                                            <tr>
                                                <th>Ref No</th>
                                                <th>Jenis Simpanan</th>
                                                <th>Jumlah Tunggakan</th>
                                                <th>Jatuh Tempo</th>
                                                <th>Jumlah Dibayar</th>
                                                <th>Tanggal Bayar</th>
                                                <th>File Bukti Bayar</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {   
                                            dataBiayaKeanggotaan.details.map((item,i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{item.refNo}</td>
                                                    <td>{item.jenisSimpanan.name}</td>
                                                    <td>Rp. {item.rp}</td>
                                                    <td>{FUNCIndoDate(item.tanggalJatuhTempo)}</td>
                                                    <td>Rp. {item.rpBayar}</td>
                                                    <td>{FUNCIndoDate(item.tanggalBayar)}</td>
                                                    <td>{item.fileBuktiBayar != null ? <a href={item.fileBuktiBayar} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</td>
                                                    <td>{item.status?.status} {item.status?.statusProses} {item.status?.catatan}</td>
                                                </tr>
                                                );
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                    </fieldset>
                </form>
            </CContainer>
        </CModalBody>
        <CModalFooter>
            <CButton color="danger" onClick={showModalViewRegistrasi}>TUTUP</CButton>{' '}
        </CModalFooter>
    </CModal>
    {/* END SECTION MODAL Calon Anggota  */}
    </div>
  );
};

export default ViewRegistrasiDetil;