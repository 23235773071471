import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
  CSelect,
  CRow,
  CNavbar,
  CModal,
  CForm,
  CSwitch,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CTextarea,
  CContainer,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CAlert,
  CImg,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText
} from '@coreui/react'
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { DataTable, ReactSelect, ReactDatePicker } from "src/components";
import { FUNCAngkaToRupiah, FUNCIndoDate, FUNCMonthToString, FUNCNumberFormat, FUNCSetFullName, FUNCYearToString } from "src/config";
import { apiAddProyekUsulan, apiEditroyekUsulan, apiVerifikasiUsulan, setDataCombo, setProyekUsulan, setProyekUsulans } from "src/config/redux/action";
import './ViewDetil.css';

const ViewProyekUsulanDetil = ({data}) => {
    useEffect(() => {
        if(data?.id != 0){
            reset();
            showModalViewProyekUsulan();
            setHeadingForm("Lihat Detil Pengajuan Pembiayaan")
        }
    }, [data])
  const { register:register, handleSubmit:handleSubmit, formState: { errors:errors }, control:control, setValue:setValue, getValues:getValues, reset:reset } = useForm();
  const dataUser = JSON.parse(localStorage.getItem("SessionLogin"))
  const { dataProyekUsulan, modalViewProyekUsulan } = useSelector(
    (state) => state.proyekUsulanReducer
  );
  
  const { 
    dataComboWaktu,
   } = useSelector((state) => state.comboReducer);

  const dispatch = useDispatch();
  
  const [isProyekAvailable, setIsProyekAvailable] = useState(false);
  useEffect(() => {
    if(dataProyekUsulan){
      setIsProyekAvailable(false);

      setValue("id",dataProyekUsulan.id);
      setValue("atasNama",dataProyekUsulan.atasNama);
      setValue("jabatan",dataProyekUsulan.jabatan);
      setValue("rp",FUNCNumberFormat(dataProyekUsulan.rp));
      setValue("tujuan",dataProyekUsulan.tujuan);
      setValue("histori",dataProyekUsulan.histori);
      setValue("noAnggota",dataProyekUsulan.noAnggota);
      setValue("namaAnggota",dataProyekUsulan.namaAnggota);
      setValue("emailAnggota",dataProyekUsulan.emailAnggota);
      setValue("nextStatus",dataProyekUsulan.nextStatusUsulan.status);
    }
    if(dataProyekUsulan?.proyek != null){
        dataProyekUsulan?.proyek?.kebutuhanModal && setValue("kebutuhanModal", FUNCNumberFormat(dataProyekUsulan?.proyek?.kebutuhanModal));
        setValue("nilaiProyek", FUNCNumberFormat(dataProyekUsulan?.proyek?.nilaiProyek));
        dataProyekUsulan?.proyek?.minTanggalProposal && setValue("minTanggalProposal", new Date(dataProyekUsulan?.proyek?.minTanggalProposal));
        dataProyekUsulan?.proyek?.maxTanggalProposal && setValue("maxTanggalProposal", new Date(dataProyekUsulan?.proyek?.maxTanggalProposal));
        setValue("namaPemohon",dataProyekUsulan.namaAnggota);
        setValue("statusAnggota",dataProyekUsulan.statusAnggota);
        setValue("tujuan",dataProyekUsulan?.tujuan);
        setValue("histori",dataProyekUsulan.histori);
        setValue("nilaiUsulan",FUNCNumberFormat(dataProyekUsulan.rp));
        
        setValue("nama",dataProyekUsulan.proyek?.nama);
        setValue("JangkaWaktu",dataProyekUsulan.proyek?.jangkaWaktu);
    }
    if(dataProyekUsulan?.proyek?.murabahah != null)
    {
        setIsProyekAvailable(true);
        setValue("Murabahah.Obyek", dataProyekUsulan?.proyek?.murabahah.obyek);
        setValue("Murabahah.Kuantiti", dataProyekUsulan?.proyek?.murabahah.kuantiti);
        dataProyekUsulan?.proyek?.murabahah.harga && setValue("Murabahah.Harga", FUNCNumberFormat(dataProyekUsulan?.proyek?.murabahah.harga));
        dataProyekUsulan?.proyek?.murabahah.biayaPerolehan && setValue("Murabahah.BiayaPerolehan", FUNCNumberFormat(dataProyekUsulan?.proyek?.murabahah.biayaPerolehan));
        dataProyekUsulan?.proyek?.murabahah.nilaiProyek && setValue("Murabahah.NilaiProyek", FUNCNumberFormat(dataProyekUsulan?.proyek?.murabahah.nilaiProyek));
        setValue("Murabahah.PctDp", dataProyekUsulan?.proyek?.murabahah.pctDp);
        dataProyekUsulan?.proyek?.murabahah.rpDp && setValue("Murabahah.RpDp", FUNCNumberFormat(dataProyekUsulan?.proyek?.murabahah.rpDp));
        dataProyekUsulan?.proyek?.kebutuhanModal && setValue("Murabahah.KebutuhanModal", FUNCNumberFormat(dataProyekUsulan?.proyek?.kebutuhanModal));
        dataProyekUsulan?.proyek?.jangkaWaktu && setValue("Murabahah.TotalJangkaWaktu", FUNCNumberFormat(dataProyekUsulan?.proyek?.jangkaWaktu));
        setValue("Murabahah.PctMargin", dataProyekUsulan?.proyek?.murabahah.pctMargin);
        dataProyekUsulan?.proyek?.murabahah.rpMargin && setValue("Murabahah.RpMargin", FUNCNumberFormat(dataProyekUsulan?.proyek?.murabahah.rpMargin));
        dataProyekUsulan?.proyek?.murabahah.rpProyeksiAngsuran && setValue("Murabahah.RpProyeksiAngsuran", FUNCNumberFormat(dataProyekUsulan?.proyek?.murabahah.rpProyeksiAngsuran));
        dataProyekUsulan?.proyek?.murabahah.hargaJual && setValue("Murabahah.HargaJual", FUNCNumberFormat(dataProyekUsulan?.proyek?.murabahah.hargaJual));
        dataProyekUsulan?.proyek?.murabahah.estimasiInvestor && setValue("Murabahah.RpEstimasiInvestor", FUNCNumberFormat(dataProyekUsulan?.proyek?.murabahah.estimasiInvestor));
        setValue("Murabahah.PctEstimasiInvestor", dataProyekUsulan?.proyek?.murabahah.pctEstimasiInvestor);
        setValue("Murabahah.PctEstimasiInvestorBulan", dataProyekUsulan?.proyek?.murabahah.pctEstimasiInvestorBulan);
        dataProyekUsulan?.proyek?.murabahah.tanggalJatuhTempoDp && setValue("Murabahah.TanggalJatuhTempoDp", new Date(dataProyekUsulan?.proyek?.murabahah.tanggalJatuhTempoDp));
        setValue("Murabahah.JumlahAngsuran", dataProyekUsulan?.proyek?.murabahah.jumlahAngsuran);
        dataProyekUsulan?.proyek?.murabahah.tanggalAwalAngsuran && setValue("Murabahah.TanggalAwalAngsuran", new Date(dataProyekUsulan?.proyek?.murabahah.tanggalAwalAngsuran));
        setValue("Murabahah.GracePeriod", dataProyekUsulan?.proyek?.murabahah.gracePeriod);
    }
    if(dataProyekUsulan?.proyek?.musyarakah != null)
    {
        setIsProyekAvailable(true);
        setValue("Musyarakah.IdWaktuTransfer", {value:dataProyekUsulan?.proyek?.musyarakah.idWaktuTransfer,label:dataProyekUsulan?.proyek?.musyarakah.waktuTransfer?.name});
        setValue("Musyarakah.JumlahTransfer", dataProyekUsulan?.proyek?.musyarakah.jumlahTransfer);
        dataProyekUsulan?.proyek?.musyarakah.tanggalMulaiTransfer && setValue("Musyarakah.TanggalMulaiTransfer", new Date(dataProyekUsulan?.proyek?.musyarakah.tanggalMulaiTransfer));
        dataProyekUsulan?.proyek?.musyarakah?.tanggalJatuhTempoBiayaSendiri && setValue("Musyarakah.TanggalJatuhTempoBiayaSendiri", new Date(dataProyekUsulan?.proyek?.musyarakah?.tanggalJatuhTempoBiayaSendiri));
        dataProyekUsulan?.proyek?.musyarakah.rpProyeksiMargin && setValue("Musyarakah.RpProyeksiMargin", FUNCNumberFormat(dataProyekUsulan?.proyek?.musyarakah.rpProyeksiMargin));
        setValue("Musyarakah.PctProyeksiMargin", dataProyekUsulan?.proyek?.musyarakah.pctProyeksiMargin);
        setValue("Musyarakah.RpHasilPengelola", FUNCNumberFormat(dataProyekUsulan?.proyek?.musyarakah.rpHasilPengelola));
        setValue("Musyarakah.PctHasilPengelola", dataProyekUsulan?.proyek?.musyarakah.pctHasilPengelola);
        setValue("Musyarakah.RpHasilInvestor", FUNCNumberFormat(dataProyekUsulan?.proyek?.musyarakah.rpHasilInvestor));
        setValue("Musyarakah.PctHasilInvestor", dataProyekUsulan?.proyek?.musyarakah.pctHasilInvestor);
        setValue("Musyarakah.RpBiayaSendiri", FUNCNumberFormat(dataProyekUsulan?.proyek?.musyarakah.rpBiayaSendiri));
        setValue("Musyarakah.PctBiayaSendiri", dataProyekUsulan?.proyek?.musyarakah.pctBiayaSendiri);
        setValue("Musyarakah.RpUjrah", FUNCNumberFormat(dataProyekUsulan?.proyek?.musyarakah.rpUjrah));
        setValue("Musyarakah.PctUjrah", dataProyekUsulan?.proyek?.musyarakah.pctUjrah);
        dataProyekUsulan?.proyek?.musyarakah.estimasiHasilBersihInvestor && setValue("Musyarakah.EstimasiHasilBersihInvestor", FUNCNumberFormat(dataProyekUsulan?.proyek?.musyarakah.estimasiHasilBersihInvestor));
        dataProyekUsulan?.proyek?.musyarakah.estimasiReturnBersihInvestor && setValue("Musyarakah.EstimasiReturnBersihInvestor", FUNCNumberFormat(dataProyekUsulan?.proyek?.musyarakah.estimasiReturnBersihInvestor));
        dataProyekUsulan?.proyek?.musyarakah.estimasiReturnBersihInvestorBulan && setValue("Musyarakah.EstimasiReturnBersihInvestorBulan", FUNCNumberFormat(dataProyekUsulan?.proyek?.musyarakah.estimasiReturnBersihInvestorBulan));

       
    }
  }, [dataProyekUsulan])


  const [headingForm, setHeadingForm] = useState(false);

  const showModalViewProyekUsulan = () => dispatch({ type: 'MODAL_VIEW_PROYEK_USULAN', payload: !modalViewProyekUsulan });

  return (
    <div>
      {/* SECTION MODAL USULAN PROYEK  */}
      <CModal
        show={modalViewProyekUsulan}
        onClose={showModalViewProyekUsulan}
        size={"xl"}
    >
        <CModalHeader closeButton>
            {headingForm}
        </CModalHeader>
        <CModalBody>
            <CContainer>
                <form className="form-horizontal">
                    <fieldset>
                        <input {...register("id")} name="id" type={"hidden"} className="form-control"  />
                        {dataProyekUsulan && <div className="form-group">
                            <div className="row">
                                <div className="col-md-2">
                                    <CLabel className={"font-weight-bold"}>Status Anggota</CLabel>
                                    <div>{dataProyekUsulan.statusAnggota}</div>
                                </div>
                                <div className="col-md-2">
                                    <CLabel className={"font-weight-bold"}>Status</CLabel>
                                    <div>{dataProyekUsulan.statusUsulan?.status} {dataProyekUsulan.statusUsulan?.statusProses} {dataProyekUsulan.statusUsulan?.catatan}</div>
                                </div>
                                <div className="col-md-2">
                                    <CLabel className={"font-weight-bold"}>Jenis Akad</CLabel>
                                    <div>{dataProyekUsulan?.proyek?.jenisProyek.name != null ? <div>{dataProyekUsulan?.proyek?.jenisProyek.name}</div>:<div>-</div>}</div>
                                </div>
                                <div className="col-md-2">
                                    <CLabel className={"font-weight-bold"}>File Usulan</CLabel>
                                    <div>{dataProyekUsulan.fileUsulan != null ? <a href={dataProyekUsulan.fileUsulan} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                                </div>
                                <div className="col-md-2">
                                    <CLabel className={"font-weight-bold"}>File Nota Usulan</CLabel>
                                    <div>{dataProyekUsulan?.proyek?.fileNotaUsulan != null ? <a href={dataProyekUsulan?.proyek?.fileNotaUsulan} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                                </div>
                                <div className="col-md-2">
                                    <CLabel className={"font-weight-bold"}>File Akad</CLabel>
                                    <div>{dataProyekUsulan?.proyek?.fileAkad != null ? <a href={dataProyekUsulan?.proyek?.fileAkad} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                                </div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <CLabel>Atas Nama</CLabel>
                                    <input readOnly name="atasNama" className="form-control" placeholder="Data Belum Tersedia..." {...register("atasNama", { required: 'Kolom wajib diisi' })}  />
                                    {errors.atasNama && <span className="text-danger">{errors.atasNama?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Jabatan</CLabel>
                                    <input readOnly name="jabatan" className="form-control" placeholder="Data Belum Tersedia..." {...register("jabatan", { required: 'Kolom wajib diisi' })}  />
                                    {errors.jabatan && <span className="text-danger">{errors.jabatan?.message}</span>}
                                </div>
                                {/* <div className='form-group'>
                                    <CLabel>Histori Pembiayaan</CLabel>
                                    <input readOnly name="histori" className="form-control" {...register("histori", { required: 'Kolom wajib diisi' })}  />
                                    {errors.histori && <span className="text-danger">{errors.histori?.message}</span>}
                                </div> */}
                                {/* <div className='form-group'>
                                    <CLabel>Pengajuan Nilai Proyek (Rupiah)</CLabel>
                                    <input readOnly name="rp" className="form-control" placeholder="Data Belum Tersedia..." {...register("rp", { required: 'Kolom wajib diisi' })}  />
                                    {errors.rp && <span className="text-danger">{errors.rp?.message}</span>}
                                </div> */}
                                <div className='form-group'>
                                    <CLabel>Tujuan</CLabel>
                                    <textarea readOnly name="tujuan" className="form-control" placeholder="Data Belum Tersedia..." {...register("tujuan", { required: 'Kolom wajib diisi' })}></textarea>
                                    {errors.tujuan && <span className="text-danger">{errors.tujuan?.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <CLabel>Nomor Anggota</CLabel>
                                    <input readOnly name="noAnggota" className="form-control" placeholder="Data Belum Tersedia..." {...register("noAnggota", { required: 'Kolom wajib diisi' })}  />
                                    {errors.noAnggota && <span className="text-danger">{errors.noAnggota?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Nama Anggota</CLabel>
                                    <input readOnly name="namaAnggota" className="form-control" placeholder="Data Belum Tersedia..." {...register("namaAnggota", { required: 'Kolom wajib diisi' })}  />
                                    {errors.namaAnggota && <span className="text-danger">{errors.namaAnggota?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Email Anggota</CLabel>
                                    <input readOnly name="emailAnggota" className="form-control" placeholder="Data Belum Tersedia..." {...register("emailAnggota", { required: 'Kolom wajib diisi' })}  />
                                    {errors.emailAnggota && <span className="text-danger">{errors.emailAnggota?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Proses Selanjutnya</CLabel>
                                    <input readOnly name="nextStatus" className="form-control" {...register("nextStatus", { required: 'Kolom wajib diisi' })}  />
                                    {errors.nextStatus && <span className="text-danger">{errors.nextStatus?.message}</span>}
                                </div>

                            </div>

                            { 
                                isProyekAvailable &&
                                <div className="col-md-12">
                                    <div className='alert alert-primary mt-3'>
                                        <h5>Detil Pengajuan Pembiayaan</h5>
                                    </div>
                                    <CRow>
                                        
                                        <CCol md="6">
                                            <div className='form-group'>
                                                <CLabel>Nama Pemohon</CLabel>
                                                <input readOnly name="namaPemohon" className="form-control" placeholder="Ketikkan Nama Pemohon..." {...register("namaPemohon")}/>
                                                {errors.namaPemohon && <span className="text-danger">{errors.namaPemohon?.message}</span>}
                                            </div>
                                        </CCol>
                                        <CCol md="6">
                                            <div className='form-group'>
                                                <CLabel>Status Anggota</CLabel>
                                                <input readOnly name="statusAnggota" className="form-control" placeholder="Ketikkan Status Anggota..." {...register("statusAnggota")}/>
                                                {errors.statusAnggota && <span className="text-danger">{errors.statusAnggota?.message}</span>}
                                            </div>
                                        </CCol>
                                        <CCol md="6">
                                            <div className='form-group'>
                                                <CLabel>Histori Pembiayaan</CLabel>
                                                <input readOnly name="histori" className="form-control" {...register("histori")}/>
                                                {errors.histori && <span className="text-danger">{errors.histori?.message}</span>}
                                            </div>
                                        </CCol>
                                        <CCol md="6">
                                            <div className='form-group'>
                                                <CLabel>Nilai Usulan</CLabel>
                                                <CInputGroup className="mb-3">
                                                    <CInputGroupPrepend>
                                                        <CInputGroupText className={"bg-white"}>
                                                        Rp
                                                        </CInputGroupText>
                                                    </CInputGroupPrepend>
                                                    <input readOnly name="nilaiUsulan" className="form-control" {...register("nilaiUsulan")}/>
                                                </CInputGroup>
                                                {errors.nilaiUsulan && <span className="text-danger">{errors.nilaiUsulan?.message}</span>}
                                            </div>
                                        </CCol>

                                        <CCol md="6">
                                            <div className='form-group'>
                                                <CLabel>Nama Proyek</CLabel>
                                                <input readOnly name="nama" className="form-control" placeholder="Ketikkan Nama Proyek..." {...register("nama")}/>
                                                {errors.nama && <span className="text-danger">{errors.nama?.message}</span>}
                                            </div>
                                        </CCol>
                                        <CCol md="6">

                                        </CCol>
                                        {/* <CCol md="6">
                                            <div className='form-group'>
                                                <CLabel>Kebutuhan Modal</CLabel>
                                                <Controller control={control} name={`kebutuhanModal`} 
                                                    render={({field, value, name, ref }) => (
                                                        <CInputGroup className="mb-3">
                                                            <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>
                                                                Rp
                                                                </CInputGroupText>
                                                            </CInputGroupPrepend>
                                                            <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="kebutuhanModal" className="form-control" placeholder="Kebutuhan Modal..."/>
                                                        </CInputGroup>
                                                    )}
                                                    rules={{required:false}}
                                                />
                                                {errors.kebutuhanModal && <span className="text-danger">{errors.kebutuhanModal?.message}</span>}
                                            </div>
                                        </CCol> */}
                                        {/* <CCol md="6">
                                            <div className='form-group'>
                                                <CLabel>Jangka Waktu Proyek</CLabel>
                                                <CInputGroup className="mb-3">
                                                    <input readOnly name="JangkaWaktu" className="form-control" placeholder="Ketikkan Jangka Waktu..." {...register("JangkaWaktu")}/>
                                                    <CInputGroupPrepend>
                                                        <CInputGroupText className={"bg-white"}>
                                                        Bulan
                                                        </CInputGroupText>
                                                    </CInputGroupPrepend>
                                                </CInputGroup>
                                                {errors.JangkaWaktu && <span className="text-danger">{errors.JangkaWaktu?.message}</span>}
                                            </div>
                                        </CCol> */}
                                        <CCol md="6">
                                            <div className='form-group'>
                                                <CLabel>Tanggal Awal Proposal</CLabel>
                                                <Controller
                                                    control={control}
                                                    defaultValue={""}
                                                    name="minTanggalProposal"
                                                    render={({field, value, name, ref }) => (
                                                        <ReactDatePicker
                                                            disabled
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={getValues("minTanggalProposal")}
                                                            onChange={(date) => field.onChange(date)}
                                                            placeholderText="- Pilih Tanggal -"
                                                        />
                                                    )}
                                                    rules={{required:false}}
                                                />
                                                {errors.minTanggalProposal && <span className="text-danger">{errors.minTanggalProposal?.message}</span>}
                                            </div>
                                        </CCol>
                                        <CCol md="6">
                                            <div className='form-group'>
                                                <CLabel>Tanggal Akhir Proposal</CLabel>
                                                <Controller
                                                    control={control}
                                                    defaultValue={""}
                                                    name="maxTanggalProposal"
                                                    render={({field, value, name, ref }) => (
                                                        <ReactDatePicker
                                                            disabled
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={getValues("maxTanggalProposal")}
                                                            onChange={(date) => field.onChange(date)}
                                                            placeholderText="- Pilih Tanggal -"
                                                        />
                                                    )}
                                                    rules={{required:false}}
                                                />
                                                {errors.maxTanggalProposal && <span className="text-danger">{errors.maxTanggalProposal?.message}</span>}
                                            </div>
                                        </CCol>
                                    </CRow>

                                    <div id="FormMusyarakah" style={{display:dataProyekUsulan?.proyek?.idJenisProyek == 1 ? "block" : "none"}}>
                                        <div className="row">
                                            <CCol md="6">
                                            <div className='form-group'>
                                                <CLabel>Nilai Proyek</CLabel>
                                                <Controller control={control} name={`nilaiProyek`} 
                                                render={({field, value, name, ref }) => (
                                                    <CInputGroup className="mb-3">
                                                        <CInputGroupPrepend>
                                                            <CInputGroupText className={"bg-white"}>
                                                            Rp
                                                            </CInputGroupText>
                                                        </CInputGroupPrepend>
                                                        <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="nilaiProyek" className="form-control" placeholder="Rupiah Nilai Proyek..."/>
                                                    </CInputGroup>
                                                )}
                                                rules={{required:false}}
                                                />
                                                {errors?.['nilaiProyek'] && <span className="text-danger">{errors?.['nilaiProyek'].message}</span>}
                                            </div>
                                            </CCol>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <CLabel>Self Financing/ Biaya Sendiri</CLabel>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='form-group'>
                                                            <Controller control={control} name={`Musyarakah.PctBiayaSendiri`} 
                                                                render={({field, value, name, ref }) => (
                                                                <CInputGroup className="mb-3">
                                                                    <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value)} value={field.value?.toString()} name="Musyarakah.PctBiayaSendiri" className="form-control" placeholder="-"/>
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText className={"bg-white"}>
                                                                            %
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                </CInputGroup>
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors?.['Musyarakah.PctBiayaSendiri'] && <span className="text-danger">{errors?.['Musyarakah.PctBiayaSendiri'].message}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className='form-group'>
                                                            <Controller control={control} name={`Musyarakah.RpBiayaSendiri`} 
                                                                render={({field, value, name, ref }) => (
                                                                <CInputGroup className="mb-3">
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText className={"bg-white"}>
                                                                            Rp
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value)} value={field.value?.toString()} name="Musyarakah.RpBiayaSendiri" className="form-control" placeholder="-"/>
                                                                </CInputGroup>
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors?.['Musyarakah.RpBiayaSendiri'] && <span className="text-danger">{errors?.['Musyarakah.RpBiayaSendiri'].message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <CCol md="12">
                                            <div style={{whiteSpace:"nowrap",overflowx:"auto",overflowy:"auto",maxHeight:500}} className="table-responsive mb-3">
                                                <table className="table table-striped table-bordered table-hover nowrap mb-3" style={{width:"100%"}}>
                                                <thead>
                                                    <tr>
                                                    {/* <th className="text-white">Self Financing/ Biaya Sendiri (Rp)</th> */}
                                                    <th>Tanggal Rencana Bayar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                    {/* <td>
                                                        <input readOnly name={`Musyarakah.RpBiayaSendiri`} className="form-control" placeholder="Ketikkan Biaya Sendiri (Rupiah)..." {...register(`Musyarakah.RpBiayaSendiri`)}/>
                                                        {errors[`Musyarakah.RpBiayaSendiri`] && <span className="text-danger">{errors[`Musyarakah.RpBiayaSendiri`]?.message}</span>}
                                                    </td> */}
                                                    <td>
                                                        <div className='form-group'>
                                                            <Controller
                                                                control={control}
                                                                defaultValue={""}
                                                                name={`Musyarakah.TanggalJatuhTempoBiayaSendiri`}
                                                                render={({field, value, name, ref }) => (
                                                                    <ReactDatePicker
                                                                        disabled
                                                                        dateFormat="dd-MM-yyyy"
                                                                        selected={getValues(`Musyarakah.TanggalJatuhTempoBiayaSendiri`)}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        placeholderText="- Tidak memiliki Self Financing -"
                                                                    />
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors[`Musyarakah.TanggalJatuhTempoBiayaSendiri`] && <span className="text-danger">{errors[`Musyarakah.TanggalJatuhTempoBiayaSendiri`]?.message}</span>}
                                                        </div>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                            </CCol>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <CLabel>Proyeksi Margin</CLabel>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='form-group'>
                                                            <Controller control={control} name={`Musyarakah.PctProyeksiMargin`} 
                                                                render={({field, value, name, ref }) => (
                                                                <CInputGroup className="mb-3">
                                                                    <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value)} value={field.value?.toString()} name="Musyarakah.PctProyeksiMargin" className="form-control" placeholder="Proyeksi Margin..."/>
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText className={"bg-white"}>
                                                                            %
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                </CInputGroup>
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors?.['Musyarakah.PctProyeksiMargin'] && <span className="text-danger">{errors?.['Musyarakah.PctProyeksiMargin'].message}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className='form-group'>
                                                            <Controller control={control} name={`Musyarakah.RpProyeksiMargin`} 
                                                                render={({field, value, name, ref }) => (
                                                                <CInputGroup className="mb-3">
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText className={"bg-white"}>
                                                                            Rp
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value)} value={field.value?.toString()} name="Musyarakah.RpProyeksiMargin" className="form-control" placeholder="Proyeksi Margin..."/>
                                                                </CInputGroup>
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors?.['Musyarakah.RpProyeksiMargin'] && <span className="text-danger">{errors?.['Musyarakah.RpProyeksiMargin'].message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <CLabel>Nisbah Pengelola</CLabel>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='form-group'>
                                                            <CInputGroup className="mb-3">
                                                                <input readOnly name="Musyarakah.PctHasilPengelola" className="form-control" placeholder="Ketikkan Nisbah Pengelola..." {...register("Musyarakah.PctHasilPengelola")}/>
                                                                <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}>
                                                                        %
                                                                    </CInputGroupText>
                                                                </CInputGroupPrepend>
                                                            </CInputGroup>
                                                            {errors?.['Musyarakah.PctHasilPengelola'] && <span className="text-danger">{errors?.['Musyarakah.PctHasilPengelola'].message}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className='form-group'>
                                                            <CInputGroup className="mb-3">
                                                                <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}>
                                                                        Rp
                                                                    </CInputGroupText>
                                                                </CInputGroupPrepend>
                                                                <input readOnly name="Musyarakah.RpHasilPengelola" className="form-control" placeholder="Ketikkan Nisbah Pengelola..." {...register("Musyarakah.RpHasilPengelola")}/>
                                                            </CInputGroup>
                                                            {errors?.['Musyarakah.RpHasilPengelola'] && <span className="text-danger">{errors?.['Musyarakah.RpHasilPengelola'].message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <CLabel>Nisbah Investor</CLabel>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='form-group'>
                                                            <CInputGroup className="mb-3">
                                                                <input readOnly name="Musyarakah.PctHasilInvestor" className="form-control" placeholder="Ketikkan Nisbah Investor..." {...register("Musyarakah.PctHasilInvestor")}/>
                                                                <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}>
                                                                        %
                                                                    </CInputGroupText>
                                                                </CInputGroupPrepend>
                                                            </CInputGroup>
                                                            {errors?.['Musyarakah.PctHasilInvestor'] && <span className="text-danger">{errors?.['Musyarakah.PctHasilInvestor'].message}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className='form-group'>
                                                            <CInputGroup className="mb-3">
                                                                <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}>
                                                                    Rp
                                                                    </CInputGroupText>
                                                                </CInputGroupPrepend>
                                                                <input readOnly name="Musyarakah.RpHasilInvestor" className="form-control" placeholder="Ketikkan Nisbah Investor..." {...register("Musyarakah.RpHasilInvestor")}/>
                                                            </CInputGroup>
                                                            {errors?.['Musyarakah.RpHasilInvestor'] && <span className="text-danger">{errors?.['Musyarakah.RpHasilInvestor'].message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <CLabel>Transfer Bagi Hasil</CLabel>
                                                    <Controller
                                                        control={control}
                                                        name="Musyarakah.IdWaktuTransfer"
                                                        render={({field, value, name, ref }) => (
                                                            <ReactSelect
                                                                readOnly
                                                                inputRef={ref}
                                                                classNamePrefix="addl-class"
                                                                options={dataComboWaktu}
                                                                value={getValues("Musyarakah.IdWaktuTransfer")}
                                                                onChange={
                                                                    (val) => {
                                                                        field.onChange(val);
                                                                    }
                                                                }
                                                                isClearable={true}
                                                            />
                                                        )}
                                                        rules={{
                                                            required:false
                                                        }}
                                                    />
                                                    {errors?.['Musyarakah.IdWaktuTransfer'] && <span className="text-danger">Kolom wajib diisi</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <CLabel>Ujrah</CLabel>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='form-group'>
                                                            <Controller control={control} name={`Musyarakah.PctUjrah`} 
                                                                render={({field, value, name, ref }) => (
                                                                <CInputGroup className="mb-3">
                                                                    <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value)} value={field.value?.toString()} name="Musyarakah.PctUjrah" className="form-control" placeholder="Ujrah..."/>
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText className={"bg-white"}>
                                                                            %
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                </CInputGroup>
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors?.['Musyarakah.PctUjrah'] && <span className="text-danger">{errors?.['Musyarakah.PctUjrah'].message}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className='form-group'>
                                                            <Controller control={control} name={`Musyarakah.RpUjrah`} 
                                                                render={({field, value, name, ref }) => (
                                                                <CInputGroup className="mb-3">
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText className={"bg-white"}>
                                                                            Rp
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value)} value={field.value?.toString()} name="Musyarakah.RpUjrah" className="form-control" placeholder="Ujrah..."/>
                                                                </CInputGroup>
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors?.['Musyarakah.RpUjrah'] && <span className="text-danger">{errors?.['Musyarakah.RpUjrah'].message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <CLabel>Jumlah Transfer Bagi Hasil (n-kali)</CLabel>
                                                    <input readOnly name="Musyarakah.JumlahTransfer" className="form-control" placeholder="Ketikkan Jumlah Transfer Bagi Hasil..." {...register("Musyarakah.JumlahTransfer")}/>
                                                    {errors?.['Musyarakah.JumlahTransfer'] && <span className="text-danger">{errors?.['Musyarakah.JumlahTransfer'].message}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <CLabel>Tanggal Mulai Transfer</CLabel>
                                                    <Controller
                                                        control={control}
                                                        defaultValue={""}
                                                        name="Musyarakah.TanggalMulaiTransfer"
                                                        render={({field, value, name, ref }) => (
                                                            <ReactDatePicker
                                                                disabled
                                                                dateFormat="dd-MM-yyyy"
                                                                selected={getValues("Musyarakah.TanggalMulaiTransfer")}
                                                                onChange={(date) => field.onChange(date)}
                                                                placeholderText="- Pilih Tanggal -"
                                                            />
                                                        )}
                                                        rules={{required:false}}
                                                    />
                                                    {errors?.['Musyarakah.TanggalMulaiTransfer'] && <span className="text-danger">{errors?.['Musyarakah.TanggalMulaiTransfer'].message}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <CLabel>Est. Hasil Bersih Investor</CLabel>
                                                    <Controller control={control} name={`Musyarakah.EstimasiHasilBersihInvestor`} 
                                                        render={({field, value, name, ref }) => (
                                                            <CInputGroup className="mb-3">
                                                            <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>
                                                                Rp
                                                                </CInputGroupText>
                                                            </CInputGroupPrepend>
                                                            <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Musyarakah.EstimasiHasilBersihInvestor" className="form-control" placeholder="Estimasi Hasil Bersih Investor..."/>
                                                            </CInputGroup>
                                                        )}
                                                        rules={{required:false}}
                                                    />
                                                    {errors?.['Musyarakah.EstimasiHasilBersihInvestor'] && <span className="text-danger">{errors?.['Musyarakah.EstimasiHasilBersihInvestor'].message}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <CLabel>Est. Return Bersih Investor</CLabel>
                                                    <Controller control={control} name={`Musyarakah.EstimasiReturnBersihInvestor`} 
                                                        render={({field, value, name, ref }) => (
                                                            <CInputGroup className="mb-3">
                                                            <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value)} value={field.value?.toString()} name="Musyarakah.EstimasiReturnBersihInvestor" className="form-control" placeholder="Estimasi Return Bersih Investor..."/>
                                                            <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>
                                                                %
                                                                </CInputGroupText>
                                                            </CInputGroupPrepend>
                                                            </CInputGroup>
                                                        )}
                                                        rules={{required:false}}
                                                    />
                                                    {errors?.['Musyarakah.EstimasiReturnBersihInvestor'] && <span className="text-danger">{errors?.['Musyarakah.EstimasiReturnBersihInvestor'].message}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                            <div className='form-group'>
                                                <CLabel>Est. Return Bersih Investor/Bulan</CLabel>
                                                <Controller control={control} name={`Musyarakah.EstimasiReturnBersihInvestorBulan`} 
                                                    render={({field, value, name, ref }) => (
                                                        <CInputGroup className="mb-3">
                                                        <input readOnly ref={ref} onChange={(e) => field.onChange(e.target.value)} value={field.value?.toString()} name="Musyarakah.EstimasiReturnBersihInvestorBulan" className="form-control" placeholder="Estimasi Return Bersih Investor/Bulan..."/>
                                                        <CInputGroupPrepend>
                                                            <CInputGroupText className={"bg-white"}>
                                                            %
                                                            </CInputGroupText>
                                                        </CInputGroupPrepend>
                                                        </CInputGroup>
                                                    )}
                                                    rules={{required:false}}
                                                />
                                                {errors?.['Musyarakah.EstimasiReturnBersihInvestorBulan'] && <span className="text-danger">{errors?.['Musyarakah.EstimasiReturnBersihInvestorBulan'].message}</span>}
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="FormMurabahah" style={{display:dataProyekUsulan?.proyek?.idJenisProyek == 2 ? "block" : "none"}}>
                                       <fieldset readOnly disabled>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <CLabel>Obyek</CLabel>
                                                        <input name="Murabahah.Obyek" className="form-control" placeholder="Ketikkan Obyek..." {...register("Murabahah.Obyek")}/>
                                                        {errors?.['Murabahah.Obyek'] && <span className="text-danger">{errors?.['Murabahah.Obyek'].message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <CLabel>Kuantiti</CLabel>
                                                        <Controller control={control} name={`Murabahah.Kuantiti`} 
                                                            render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                                <input ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Murabahah.Kuantiti" className="form-control" placeholder="Kuantiti..."/>
                                                                <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}> kg/pcs/dll</CInputGroupText>
                                                                </CInputGroupPrepend>
                                                            </CInputGroup>
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.Kuantiti'] && <span className="text-danger">{errors?.['Murabahah.Kuantiti'].message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <CLabel>Proyeksi Biaya Perolehan</CLabel>
                                                        <Controller control={control} name={`Murabahah.BiayaPerolehan`} 
                                                            render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                                <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}> Rp</CInputGroupText>
                                                                </CInputGroupPrepend>
                                                                <input ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Murabahah.BiayaPerolehan" className="form-control" placeholder="Proyeksi Biaya Perolehan..."/>
                                                            </CInputGroup>
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.BiayaPerolehan'] && <span className="text-danger">{errors?.['Murabahah.BiayaPerolehan'].message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <CLabel>Harga</CLabel>
                                                        <Controller control={control} name={`Murabahah.Harga`} 
                                                            render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                                <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>
                                                                    Rp
                                                                </CInputGroupText>
                                                                </CInputGroupPrepend>
                                                                <input ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Murabahah.Harga" className="form-control" placeholder="Harga..."/>
                                                            </CInputGroup>
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.Harga'] && <span className="text-danger">{errors?.['Murabahah.Harga'].message}</span>}
                                                    </div>
                                                </div>
                                                <CCol md="6"> 
                                                    <div className='form-group'>
                                                        <CLabel>Nilai Proyek</CLabel>
                                                        <Controller control={control} name={`Murabahah.NilaiProyek`} 
                                                        render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                            <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>Rp</CInputGroupText>
                                                            </CInputGroupPrepend>
                                                            <input readOnly ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Murabahah.NilaiProyek" className="form-control" placeholder="Rupiah Nilai Proyek..."/>
                                                            </CInputGroup>
                                                        )}
                                                        rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.NilaiProyek'] && <span className="text-danger">{errors?.['Murabahah.NilaiProyek'].message}</span>}
                                                    </div>
                                                </CCol>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <CLabel>DP / Hamisyul Jiddiyah</CLabel>
                                                        </div>
                                                        <div className="col-md-5">
                                                        <Controller control={control} name={`Murabahah.PctDp`} 
                                                            render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                                <input ref={field.ref} onChange={(e) => field.onChange(e.target.value)} value={field.value} name="Murabahah.PctDp" className="form-control"/>
                                                                <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>
                                                                    %
                                                                </CInputGroupText>
                                                                </CInputGroupPrepend>
                                                            </CInputGroup>
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.PctDp'] && <span className="text-danger">{errors?.['Murabahah.PctDp'].message}</span>}
                                                        </div>
                                                        <div className="col-md-7">
                                                        <Controller control={control} name={`Murabahah.RpDp`} 
                                                            render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                                <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>
                                                                    Rp
                                                                </CInputGroupText>
                                                                </CInputGroupPrepend>
                                                                <input readOnly ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))} value={FUNCAngkaToRupiah(field.value)} name="Murabahah.RpDp" className="form-control" placeholder="Proyeksi Margin..."/>
                                                            </CInputGroup>
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.RpDp'] && <span className="text-danger">{errors?.['Murabahah.RpDp'].message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <CLabel>Tanggal Jatuh Tempo DP</CLabel>
                                                        <Controller
                                                            control={control}
                                                            defaultValue={""}
                                                            name="Murabahah.TanggalJatuhTempoDp"
                                                            render={({field }) => (
                                                                <ReactDatePicker
                                                                    dateFormat="dd-MM-yyyy"
                                                                    selected={getValues("Murabahah.TanggalJatuhTempoDp")}
                                                                    onChange={(date) => field.onChange(date)}
                                                                    placeholderText="- Pilih Tanggal -"
                                                                />
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.TanggalJatuhTempoDp'] && <span className="text-danger">{errors?.['Murabahah.TanggalJatuhTempoDp'].message}</span>}
                                                    </div>
                                                </div>
                                                <CCol md="6">
                                                    <div className='form-group'>
                                                        <CLabel>Kebutuhan Modal</CLabel>
                                                        <Controller control={control} name={`Murabahah.KebutuhanModal`} 
                                                            render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                                <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}> Rp</CInputGroupText>
                                                                </CInputGroupPrepend>
                                                                <input ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Murabahah.KebutuhanModal" className="form-control" placeholder="Kebutuhan Modal..." readOnly/>
                                                            </CInputGroup>
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.KebutuhanModal'] && <span className="text-danger">{errors?.['Murabahah.KebutuhanModal'].message}</span>}
                                                    </div>
                                                </CCol>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <CLabel>Jumlah Angsuran</CLabel>
                                                        <Controller control={control} name={`Murabahah.JumlahAngsuran`} 
                                                            render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                                <input ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Murabahah.JumlahAngsuran" className="form-control" placeholder="Jumlah Angsuran..."/>
                                                                <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}> Bulan</CInputGroupText>
                                                                </CInputGroupPrepend>
                                                            </CInputGroup>
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.JumlahAngsuran'] && <span className="text-danger">{errors?.['Murabahah.JumlahAngsuran'].message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <CLabel>Tanggal Awal Angsuran</CLabel>
                                                        <Controller
                                                            control={control}
                                                            defaultValue={""}
                                                            name="Murabahah.TanggalAwalAngsuran"
                                                            render={({field }) => (
                                                                <ReactDatePicker
                                                                    dateFormat="dd-MM-yyyy"
                                                                    selected={getValues("Murabahah.TanggalAwalAngsuran")}
                                                                    onChange={(date) => field.onChange(date)}
                                                                    placeholderText="- Pilih Tanggal -"
                                                                />
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.TanggalAwalAngsuran'] && <span className="text-danger">{errors?.['Murabahah.TanggalAwalAngsuran'].message}</span>}
                                                    </div>
                                                </div>
                                                <CCol md="6">
                                                <div className='form-group'>
                                                    <CLabel>Grace Period</CLabel>
                                                    <Controller control={control} name={`Murabahah.GracePeriod`} 
                                                        render={({field }) => (
                                                        <CInputGroup className="mb-3">
                                                            <input ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Murabahah.GracePeriod" className="form-control" placeholder="Grace Period..."/>
                                                            <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}> Bulan</CInputGroupText>
                                                            </CInputGroupPrepend>
                                                        </CInputGroup>
                                                        )}
                                                        rules={{required:false}}
                                                    />
                                                    {errors?.['Murabahah.GracePeriod'] && <span className="text-danger">{errors?.['Murabahah.GracePeriod'].message}</span>}
                                                </div>
                                                </CCol>
                                                <CCol md="6">
                                                    <div className='form-group'>
                                                        <CLabel>Total Jangka Waktu</CLabel>
                                                        <CInputGroup className="mb-3">
                                                            <input readOnly name="Murabahah.TotalJangkaWaktu" className="form-control" {...register("Murabahah.TotalJangkaWaktu")}/>
                                                            <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>Bulan</CInputGroupText>
                                                            </CInputGroupPrepend>
                                                        </CInputGroup>
                                                        {errors?.['Murabahah.TotalJangkaWaktu'] && <span className="text-danger">{errors?.['Murabahah.TotalJangkaWaktu'].message}</span>}
                                                    </div>
                                                </CCol>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <CLabel>Margin Murabahah</CLabel>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <Controller control={control} name={`Murabahah.PctMargin`} 
                                                                render={({field }) => (
                                                                <CInputGroup className="mb-3">
                                                                    <input ref={field.ref} onChange={(e) => field.onChange(e.target.value)} value={field.value} name="Murabahah.PctMargin" className="form-control"/>
                                                                    <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}>
                                                                        %
                                                                    </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                </CInputGroup>
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors?.['Murabahah.PctMargin'] && <span className="text-danger">{errors?.['Murabahah.PctMargin'].message}</span>}
                                                        </div>
                                                        <div className="col-md-7">
                                                            <Controller control={control} name={`Murabahah.RpMargin`} 
                                                                render={({field }) => (
                                                                <CInputGroup className="mb-3">
                                                                    <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}>
                                                                        Rp
                                                                    </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <input readOnly ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))} value={FUNCAngkaToRupiah(field.value)} name="Murabahah.RpMargin" className="form-control" placeholder="Proyeksi Margin..."/>
                                                                </CInputGroup>
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors?.['Murabahah.RpMargin'] && <span className="text-danger">{errors?.['Murabahah.RpMargin'].message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <CLabel>Proyeksi Angsuran/Bulan</CLabel>
                                                        <Controller control={control} name={`Murabahah.RpProyeksiAngsuran`} 
                                                            render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                                <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>
                                                                    Rp
                                                                </CInputGroupText>
                                                                </CInputGroupPrepend>
                                                                <input readOnly ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Murabahah.RpProyeksiAngsuran" className="form-control" placeholder="Proyeksi Angsuran..."/>
                                                            </CInputGroup>
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.RpProyeksiAngsuran'] && <span className="text-danger">{errors?.['Murabahah.RpProyeksiAngsuran'].message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <CLabel>Proyeksi Harga Jual</CLabel>
                                                        <Controller control={control} name={`Murabahah.HargaJual`} 
                                                        render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                            <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>
                                                                Rp
                                                                </CInputGroupText>
                                                            </CInputGroupPrepend>
                                                            <input readOnly ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Murabahah.HargaJual" className="form-control" placeholder="Proyeksi Harga Jual..."/>
                                                            </CInputGroup>
                                                        )}
                                                        rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.HargaJual'] && <span className="text-danger">{errors?.['Murabahah.HargaJual'].message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <CLabel>Estimasi Margin Bersih Investor</CLabel>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <Controller control={control} name={`Murabahah.PctEstimasiInvestor`} 
                                                                render={({field }) => (
                                                                <CInputGroup className="mb-3">
                                                                    <input readOnly ref={field.ref} onChange={(e) => field.onChange(e.target.value)} value={field.value} name="Murabahah.PctEstimasiInvestor" className="form-control"/>
                                                                    <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}>
                                                                        %
                                                                    </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                </CInputGroup>
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors?.['Murabahah.PctEstimasiInvestor'] && <span className="text-danger">{errors?.['Murabahah.PctEstimasiInvestor'].message}</span>}
                                                        </div>
                                                        <div className="col-md-7">
                                                            <Controller control={control} name={`Murabahah.RpEstimasiInvestor`} 
                                                                render={({field }) => (
                                                                <CInputGroup className="mb-3">
                                                                    <CInputGroupPrepend>
                                                                    <CInputGroupText className={"bg-white"}>
                                                                        Rp
                                                                    </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <input readOnly ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))} value={FUNCAngkaToRupiah(field.value)} name="Murabahah.RpEstimasiInvestor" className="form-control"/>
                                                                </CInputGroup>
                                                                )}
                                                                rules={{required:false}}
                                                            />
                                                            {errors?.['Murabahah.RpEstimasiInvestor'] && <span className="text-danger">{errors?.['Murabahah.RpEstimasiInvestor'].message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <CLabel>Estimasi Return / Bulanan</CLabel>
                                                        <Controller control={control} name={`Murabahah.PctEstimasiInvestorBulan`} 
                                                            render={({field }) => (
                                                            <CInputGroup className="mb-3">
                                                                <input readOnly ref={field.ref} onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}  value={FUNCAngkaToRupiah(field.value)}  name="Murabahah.PctEstimasiInvestorBulan" className="form-control"/>
                                                                <CInputGroupPrepend>
                                                                <CInputGroupText className={"bg-white"}>
                                                                    %
                                                                </CInputGroupText>
                                                                </CInputGroupPrepend>
                                                            </CInputGroup>
                                                            )}
                                                            rules={{required:false}}
                                                        />
                                                        {errors?.['Murabahah.PctEstimasiInvestorBulan'] && <span className="text-danger">{errors?.['Murabahah.PctEstimasiInvestorBulan'].message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                       </fieldset>
                                    </div>

                                </div>
                            }

                            { dataProyekUsulan && dataProyekUsulan.proyek && dataProyekUsulan.proyek?.syaratFiles && dataProyekUsulan.proyek.syaratFiles?.length > 0 && <div className="col-md-12">
                                <div className='alert alert-primary mt-3'>
                                    <h5>File Persyaratan</h5>
                                    <div className=''>File Persyaratan Pengajuan Pembiayaan.</div>
                                </div>
                                <div style={{whiteSpace:"nowrap",overflowx:"auto",overflowy:"auto",maxHeight:500}} className="table-responsive mb-3">
                                    <table className="table table-striped table-bordered table-hover nowrap mb-3" style={{width:"100%"}}>
                                        <thead>
                                            <tr>
                                                <th>Jenis</th>
                                                <th>Keterangan/File</th>
                                                <th>Status</th>
                                                <th>Catatan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {   
                                                dataProyekUsulan && dataProyekUsulan.proyek && dataProyekUsulan.proyek?.syaratFiles.map((item,i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.jenisSyarat.name}</td>
                                                            <td>{item.namaFile != null ? <a href={item.namaFile} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</td>
                                                            <td>{item.isValid ? <div className="badge badge-success p-2">Valid</div> : <div className="badge badge-warning text-white p-2">Belum di Validasi</div>}</td>
                                                            <td>{item.catatan != "null" && item.catatan}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            {   
                                                dataProyekUsulan && dataProyekUsulan.proyek && dataProyekUsulan.proyek?.syaratNonFiles.map((item,i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.jenisSyarat.name}</td>
                                                            <td>{item.isChecked != null ? <div className="badge badge-success p-2">Ya</div> : <div className="badge badge-danger p-2">Tidak</div>}</td>
                                                            <td>{item.isValid ? <div className="badge badge-success p-2">Valid</div> : <div className="badge badge-warning text-white p-2">Belum di Validasi</div>}</td>
                                                            <td>{item.catatan != "null" && item.catatan}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            }

                            {/* {
                                dataProyekUsulan && dataProyekUsulan.pembiayaans && dataProyekUsulan.pembiayaans.length > 0 && <div className="col-md-12">
                                    <div className='alert alert-primary mt-3'>
                                        <h5>Pembiayaan</h5>
                                        <div className=''>Pembiayaan Usulan Proyek.</div>
                                    </div>
                                    <div style={{whiteSpace:"nowrap",overflowx:"auto",overflowy:"auto",maxHeight:500}} className="table-responsive mb-3">
                                        <table className="table table-striped table-bordered table-hover nowrap mb-3" style={{width:"100%"}}>
                                            <thead>
                                            <tr>
                                                <th>Jenis Pembiayaan</th>
                                                <th>Rupiah</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {   
                                                dataProyekUsulan.pembiayaans.map((item,i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.jenisPembiayaan.name}</td>
                                                            <td><input {...register(`DataProyekPembiayaans[${i}].rp`,{required:false})} defaultValue={item.rp} name={`DataProyekPembiayaans[${i}].rp`} className="form-control"/></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            } */}

                            {/* {
                                dataProyekUsulan && dataProyekUsulan.biayaSendiri && 
                                <div className="col-md-12">
                                    <div className='alert alert-primary mt-3'>
                                        <h5>{dataProyekUsulan.biayaSendiri.jenisBiaya.name}</h5>
                                        <div className=''>{dataProyekUsulan.biayaSendiri.jenisBiaya.name} Usulan Proyek.</div>
                                    </div>
                                    <div style={{whiteSpace:"nowrap",overflowx:"auto",overflowy:"auto",maxHeight:500}} className="table-responsive mb-3">
                                        <table className="table table-striped table-bordered table-hover nowrap mb-3" style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th>Ref No</th>
                                                    <th>Jenis Biaya</th>
                                                    <th>Rupiah</th>
                                                    <th>Tanggal Jatuh Tempo</th>
                                                    <th>Status Biaya</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{dataProyekUsulan.biayaSendiri.refNo}</td>
                                                    <td>{dataProyekUsulan.biayaSendiri.jenisBiaya.name}</td>
                                                    <td>{dataProyekUsulan.biayaSendiri.rp}</td>
                                                    <td>{dataProyekUsulan.biayaSendiri.tanggalJatuhTempo}</td>
                                                    <td>{dataProyekUsulan.biayaSendiri.statusBiaya.status} {dataProyekUsulan.biayaSendiri.statusBiaya.statusProses} {dataProyekUsulan.biayaSendiri.statusBiaya.catatan}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            } */}

                            {
                                dataProyekUsulan && dataProyekUsulan.proyek && dataProyekUsulan.proyek.biayas && dataProyekUsulan.proyek.biayas.length > 0 && 
                                <div className="col-md-12">
                                    <div className='alert alert-primary mt-3'>
                                        <h5>Tagihan</h5>
                                        <div className=''>Tagihan Pengajuan Pembiayaan.</div>
                                    </div>
                                    <div style={{whiteSpace:"nowrap",overflowx:"auto",overflowy:"auto",maxHeight:500}} className="table-responsive mb-3">
                                        <table className="table table-striped table-bordered table-hover nowrap mb-3" style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th>Ref No</th>
                                                    <th>Jenis Biaya</th>
                                                    <th>Rupiah</th>
                                                    <th>Tanggal Jatuh Tempo</th>
                                                    <th>Status Biaya</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataProyekUsulan.proyek.biayas.map((item,index) => {
                                                        return (<tr key={index}>
                                                            <td>{item.refNo}</td>
                                                            <td>{item.jenisBiaya.name}</td>
                                                            <td>{FUNCNumberFormat(item.rp)}</td>
                                                            <td>{FUNCIndoDate(item.tanggalJatuhTempo)}</td>
                                                            <td>{item.statusBiaya.status} {item.statusBiaya.statusProses} {item.statusBiaya.catatan}</td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='alert alert-primary mt-3'>
                            <h5>File Dokumen</h5>
                            <div className=''>File Dokumen Proyek Usulan.</div>
                        </div>
                        <div className="row">
                            {dataProyekUsulan?.fileUsulan != null && dataUser.idRole != 2  && <div className="col-md-6">
                                <div className="form-group">
                                    <CLabel className={"font-weight-bold"}>File Usulan</CLabel>
                                    <iframe src={`https://docs.google.com/viewer?url=${dataProyekUsulan?.fileUsulan}&embedded=true`} style={{border:0}} height="600px" width="100%"></iframe>
                                    <a href={dataProyekUsulan?.fileUsulan} className="btn btn-primary btn-block text-white p-2" target={"_blank"}>Download File</a>
                                </div>
                            </div>}
                            {dataProyekUsulan?.proyek?.fileNotaUsulan != null && dataUser.idRole != 2 && <div className="col-md-6">
                                <div className="form-group">
                                    <CLabel className={"font-weight-bold"}>File Nota Usulan</CLabel>
                                    <iframe src={`https://docs.google.com/viewer?url=${dataProyekUsulan?.proyek?.fileNotaUsulan}&embedded=true`} style={{border:0}} height="600px" width="100%"></iframe>
                                    <a href={dataProyekUsulan?.proyek?.fileNotaUsulan} className="btn btn-primary btn-block text-white p-2" target={"_blank"}>Download File</a>
                                </div>
                            </div>}
                            {dataProyekUsulan?.proyek?.fileAkad != null  && <div className="col-md-6">
                                <div className="form-group">
                                    <CLabel className={"font-weight-bold"}>File Akad</CLabel>
                                    <iframe src={`https://docs.google.com/viewer?url=${dataProyekUsulan?.proyek?.fileAkad}&embedded=true`} style={{border:0}} height="600px" width="100%"></iframe>
                                    <a href={dataProyekUsulan?.proyek?.fileAkad} className="btn btn-primary btn-block text-white p-2" target={"_blank"}>Download File</a>
                                </div>
                            </div>}
                        </div>
                    </fieldset>
                </form>
            </CContainer>
        </CModalBody>
        <CModalFooter>
            <CButton color="danger" onClick={showModalViewProyekUsulan}>TUTUP</CButton>{' '}
        </CModalFooter>
    </CModal>
    {/* END SECTION MODAL USULAN PROYEK  */}
    </div>
  );
};

export default ViewProyekUsulanDetil;