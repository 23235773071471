import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
  CSelect,
  CRow,
  CNavbar,
  CModal,
  CForm,
  CSwitch,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CTextarea,
  CContainer,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CAlert,
  CImg,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText
} from '@coreui/react'
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { DataTable, ReactSelect, ReactDatePicker } from "src/components";
import { FUNCDateDmytoYmd, FUNCIndoDate, FUNCMonthToString, FUNCNumberFormat, FUNCSetFullName, FUNCYearToString } from "src/config";
import { setDataCombo, setNasabahBiaya } from "src/config/redux/action";
import './ViewDetil.css';

const ViewNasabahBiayaDetil = ({data}) => {
    useEffect(() => {
        if(data?.id != 0){
            reset();
            showModalViewNasabahBiaya();
            setHeadingForm("Lihat Detil Nasabah Biaya")
        }
    }, [data])
  const { register:register, handleSubmit:handleSubmit, formState: { errors:errors }, control:control, setValue:setValue, getValues:getValues, reset:reset } = useForm();
  
  const dataUser = JSON.parse(localStorage.getItem("SessionLogin"))

  const { dataProyekInvestor, modalViewInvestor } = useSelector(
    (state) => state.proyekReducer
  );
  
  const { dataNasabahBiaya, modalViewNasabahBiaya } = useSelector(
    (state) => state.proyekBiayaReducer
  );

  const { 
    dataComboProyekTagihanJenis,
    dataComboJenisIdentitas,
    dataComboWaktu,
   } = useSelector((state) => state.comboReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setDataCombo("DATA_COMBO_PROYEK_TAGIHAN_JENIS"));
    dispatch(setDataCombo("DATA_COMBO_JENIS_IDENTITAS"));
  }, [dispatch]);

  useEffect(() => {
    if(dataNasabahBiaya){
      setValue("id",dataNasabahBiaya.id);
      setValue("jenisBiaya", {value:dataNasabahBiaya.idJenisBiaya,label:dataNasabahBiaya.jenisBiaya.name});
      dataNasabahBiaya.tanggalJatuhTempo && setValue("tanggalJatuhTempo", new Date(FUNCDateDmytoYmd(dataNasabahBiaya.tanggalJatuhTempo)));
      setValue("rp", FUNCNumberFormat(dataNasabahBiaya.rp));
      setValue("refNo",dataNasabahBiaya.refNo);
      dataNasabahBiaya?.tanggalBayar && setValue("tanggalBayar", new Date(FUNCDateDmytoYmd(dataNasabahBiaya?.tanggalBayar)));
      setValue("rpBayar", FUNCNumberFormat(dataNasabahBiaya.rpBayar));
      setValue("idProyek",dataNasabahBiaya.idProyek);
      setValue("namaProyek",dataNasabahBiaya.namaProyek);
      setValue("namaNasabah",dataNasabahBiaya.namaNasabah);
      setValue("atasNamaProyek",dataNasabahBiaya.atasNamaProyek);

    }
  }, [dataNasabahBiaya])


  const [headingForm, setHeadingForm] = useState(false);

  const showModalViewNasabahBiaya = () => dispatch({ type: 'MODAL_VIEW_NASABAH_BIAYA', payload: !modalViewNasabahBiaya });

  return (
    <div>
      {/* SECTION MODAL INVESTOR  */}
      <CModal
        show={modalViewNasabahBiaya}
        onClose={showModalViewNasabahBiaya}
        size={"lg"}
    >
        <CModalHeader closeButton>
            {headingForm}
        </CModalHeader>
        <CModalBody>
            <CContainer>
                <form className="form-horizontal">
                    <fieldset disabled aria-readonly>
                        <input {...register("id")} name="id" type={"hidden"} className="form-control"  />
                        <div className="form-row">
                            <div className="col-md-12">
                                {
                                    dataNasabahBiaya && <div className="form-group">
                                        <div className="form-row">
                                            <div className="col-md-4">
                                                <CLabel className={"font-weight-bold"}>Jenis Biaya</CLabel>
                                                <div>{dataNasabahBiaya.jenisBiaya?.name}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <CLabel className={"font-weight-bold"}>Status Biaya</CLabel>
                                                <div>{dataNasabahBiaya.statusBiaya?.status} {dataNasabahBiaya.statusBiaya?.statusProses}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <CLabel className={"font-weight-bold"}>File Bukti Bayar</CLabel>
                                                <div>{dataNasabahBiaya.fileBuktiBayar != null ? <a href={dataNasabahBiaya.fileBuktiBayar} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className='form-group'>
                                    <CRow>
                                        <CCol>
                                            <CLabel>Nama Nasabah</CLabel>
                                            <div>
                                                <input name="namaNasabah" className="form-control" {...register("namaNasabah")}  />
                                                {errors.namaNasabah && <span className="text-danger">{errors.namaNasabah?.message}</span>}
                                            </div>
                                        </CCol>
                                    </CRow>
                                </div>

                                <CRow>
                                    <CCol>
                                        <div className='form-group'>
                                            <CLabel>Rupiah Yang Harus Dibayarkan</CLabel>
                                            <CInputGroup className="mb-3">
                                                    <CInputGroupPrepend>
                                                        <CInputGroupText className={"bg-white"}>
                                                        Rp
                                                        </CInputGroupText>
                                                    </CInputGroupPrepend>
                                                    <input name="rp" className="form-control" {...register("rp")}  /> 
                                                </CInputGroup>
                                            {errors.rp && <span className="text-danger">{errors.rp?.message}</span>}
                                        </div>
                                    </CCol>
                                    <CCol>
                                        <div className='form-group'>
                                            <CLabel>Tanggal Jatuh Tempo </CLabel>
                                            <Controller
                                                control={control}
                                                defaultValue={""}
                                                name="tanggalJatuhTempo"    
                                                render={({field, value, name, ref }) => (
                                                    <ReactDatePicker
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={getValues("tanggalJatuhTempo")}
                                                        //onChange={(date) => field.onChange(date)}
                                                    />
                                                )}
                                                rules={{required:true}}
                                            />
                                            {errors.tanggalJatuhTempo && <span className="text-danger">{errors.tanggalJatuhTempo?.message}</span>}
                                        </div>
                                    </CCol>

                                </CRow>
                                <CRow>
                                    <CCol>
                                        <div className='form-group'>
                                            <CLabel>Rupiah Bayar</CLabel>
                                            <CInputGroup className="mb-3">
                                                    <CInputGroupPrepend>
                                                        <CInputGroupText className={"bg-white"}>
                                                        Rp
                                                        </CInputGroupText>
                                                    </CInputGroupPrepend>
                                                    <input name="rpBayar" className="form-control" placeholderText="Belum Melakukan Pembayaran" {...register("rpBayar")}  />                                             
                                                </CInputGroup>
                                            {errors.rpBayar && <span className="text-danger">{errors.rpBayar?.message}</span>}
                                        </div>
                                    </CCol>
                                    <CCol>
                                        <div className='form-group'>
                                            <CLabel>Tanggal Bayar </CLabel>
                                            <Controller
                                                control={control}
                                                defaultValue={""}
                                                name="tanggalBayar"
                                                render={({field, value, name, ref }) => (
                                                    <ReactDatePicker
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={getValues("tanggalBayar")}
                                                        onChange={(date) => field.onChange(date)}
                                                        placeholderText="Belum Melakukan Pembayaran"
                                                    />
                                                )}
                                                rules={{required:true}}
                                            />
                                            {errors?.tanggalBayar && <span className="text-danger">{errors?.tanggalBayar?.message}</span>}
                                        </div>
                                    </CCol>
                                </CRow>
                                <div className='form-group'>
                                    <CRow>
                                        <CCol>
                                            <CLabel>Nomor Referensi</CLabel>
                                            <div className='form-group'>
                                                <input name="refNo" className="form-control" {...register("refNo")}  />
                                                {errors.refNo && <span className="text-danger">{errors.refNo?.message}</span>}
                                            </div>
                                        </CCol>
                                    </CRow>
                                </div>
                                <div className='form-group'>
                                    <CRow>
                                        <CCol>
                                            <div className='form-group'>
                                                <CLabel>Nama Proyek</CLabel>
                                                <input name="namaProyek" className="form-control" {...register("namaProyek", { required: 'Kolom wajib diisi' })}  />
                                                {errors.namaProyek && <span className="text-danger">{errors.namaProyek?.message}</span>}
                                            </div>
                                        </CCol>
                                        <CCol>
                                            <div className='form-group'>
                                                <CLabel>Atas Nama Proyek</CLabel>
                                                <input name="atasNamaProyek" className="form-control" {...register("atasNamaProyek", { required: 'Kolom wajib diisi' })}  />
                                                {errors.atasNamaProyek && <span className="text-danger">{errors.atasNamaProyek?.message}</span>}
                                            </div>
                                        </CCol>
                                    </CRow>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </CContainer>
        </CModalBody>
        <CModalFooter>
            <CButton color="danger" onClick={showModalViewNasabahBiaya}>TUTUP</CButton>{' '}
        </CModalFooter>
    </CModal>
    {/* END SECTION MODAL INVESTOR  */}
    </div>
  );
};

export default ViewNasabahBiayaDetil;