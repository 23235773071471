import { FUNCDateToString, http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';

export const setProyekPengajuanBiayas = (idProyek,nextStatus=null, query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekPengajuanBiayas/GetPengajuanBiayas?idProyek=${idProyek}&nextStatus=${nextStatus}&applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_PENGAJUAN_BIAYAS', payload: data.data})
                if(data.data == ""){ dispatch({type: 'DATA_PROYEK_PENGAJUAN_BIAYAS', payload: []}) }
            } else {
                dispatch({type: 'DATA_PROYEK_PENGAJUAN_BIAYAS', payload: []})
                if(data.returnMessage != 'data tidak ada') {
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                } 
            }
        })
    }
}

export const setProyekPengajuanBiaya = (idProyekPengajuanBiaya) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekPengajuanBiayas/GetPengajuanBiaya?idProyekPengajuanBiaya=${idProyekPengajuanBiaya}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_PENGAJUAN_BIAYA', payload: data.data})
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekPengajuanBiaya_AddPengajuanBiaya = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyek', iData.idProyek);
        fd.append('rpPengajuan', iData.rpPengajuan);
        fd.append('judul', iData.judul);
        fd.append('keterangan', iData.keterangan);
        fd.append('tanggalDibutuhkan', FUNCDateToString(iData.tanggalDibutuhkan));
        fd.append('namaVendor', iData.namaVendor);
        fd.append('alamatVendor', iData.alamatVendor);
        fd.append('emailVendor', iData.emailVendor);
        fd.append('noHandphoneVendor', iData.noHandphoneVendor);
        fd.append('noTelponVendor', iData.noTelponVendor);
        iData.filePengajuan[0] != undefined && fd.append('filePengajuan', iData.filePengajuan[0]);
        
        http.post(`${URLSVC}/ProyekPengajuanBiayas/AddPengajuanBiaya`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_PROYEK_PENGAJUAN_BIAYA', payload: false });
                Swal.fire('Berhasil', `Pengajuan Pencairan Proyek Berhasil Disimpan`, 'success');
                dispatch(setProyekPengajuanBiayas(iData.idProyek, null, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekPengajuanBiaya_EditPengajuanBiaya = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekPengajuanBiaya', iData.id);
        fd.append('rpPengajuan', iData.rpPengajuan);
        fd.append('judul', iData.judul);
        fd.append('keterangan', iData.keterangan);
        fd.append('tanggalDibutuhkan', FUNCDateToString(iData.tanggalDibutuhkan));
        fd.append('namaVendor', iData.namaVendor);
        fd.append('alamatVendor', iData.alamatVendor);
        fd.append('emailVendor', iData.emailVendor);
        fd.append('noHandphoneVendor', iData.noHandphoneVendor);
        fd.append('noTelponVendor', iData.noTelponVendor);
        iData.filePengajuan[0] != undefined && fd.append('filePengajuan', iData.filePengajuan[0]);
        
        http.post(`${URLSVC}/ProyekPengajuanBiayas/EditPengajuanBiaya`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_PROYEK_PENGAJUAN_BIAYA', payload: false });
                Swal.fire('Berhasil', `Pengajuan Pencairan Proyek Berhasil Disimpan`, 'success');
                dispatch(setProyekPengajuanBiayas(iData.idProyek, null,query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekPengajuanBiaya_DeletePengajuanBiaya = (param) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan menghapus pengajuan biaya ${param.judul} untuk proyek ${param.namaProyek}!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                loadingBar(true);
                var fd = new FormData();
                fd.append('idProyekPengajuanBiaya', param.id);
                http.post(`${URLSVC}/ProyekPengajuanBiayas/DeletePengajuanBiaya`,fd).then((res) => {
                    loadingBar(false);
                    let data = res.data;
                    if (data.isSuccess) {
                        dispatch(setProyekPengajuanBiayas(param.idProyek));
                        Swal.fire('Berhasil', `Pengajuan biaya ${param.judul} untuk proyek ${param.namaProyek} berhasil dihapus`, 'success');
                    } else {
                        Swal.fire('Gagal', `${data.returnMessage}`, 'error');
                    }
                })
            }
        })
    }
}

export const apiProyekPengajuanBiaya_VerifikasiPengajuanBiaya = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekPengajuanBiaya', iData.id);
        fd.append('rpPengajuan', iData.rpPengajuan);
        fd.append('judul', iData.judul);
        fd.append('keterangan', iData.keterangan);
        fd.append('tanggalDibutuhkan', FUNCDateToString(iData.tanggalDibutuhkan));
        fd.append('namaVendor', iData.namaVendor);
        fd.append('alamatVendor', iData.alamatVendor);
        fd.append('emailVendor', iData.emailVendor);
        fd.append('noHandphoneVendor', iData.noHandphoneVendor);
        fd.append('noTelponVendor', iData.noTelponVendor);
        iData.filePemesanan[0] != undefined && fd.append('filePemesanan', iData.filePemesanan[0]);
        iData.filePenawaran[0] != undefined && fd.append('filePenawaran', iData.filePenawaran[0]);

        fd.append('isApprove', iData.isApprove);
        fd.append('catatan', iData.catatan);
        
        http.post(`${URLSVC}/ProyekPengajuanBiayas/VerifikasiPengajuanBiaya`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_PROYEK_PENGAJUAN_BIAYA', payload: false });
                Swal.fire('Berhasil', `Pengajuan Pencairan Proyek Berhasil Di Verifikasi`, 'success');
                dispatch(setProyekPengajuanBiayas(iData.idProyek, null, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekPengajuanBiaya_PersetujuanNasabahPengajuanBiaya = (iData,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekPengajuanBiaya', iData.id);
        fd.append('isApprove', iData.isApprove);
        fd.append('catatan', iData.catatan);
        
        http.post(`${URLSVC}/ProyekPengajuanBiayas/PersetujuanNasabahPengajuanBiaya`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_PROYEK_PENGAJUAN_BIAYA', payload: false });
                Swal.fire('Berhasil', `Perubahan Pencairan Proyek Berhasil Di Proses`, 'success');
                dispatch(setProyekPengajuanBiayas(iData.idProyek));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekPengajuanBiaya_RealisasiPengajuanBiaya = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekPengajuanBiaya', iData.id);
        fd.append('tanggalTransfer', FUNCDateToString(iData.tanggalTransfer));
        fd.append('rp', iData.rp);
        iData.buktiTransfer[0] != undefined && fd.append('buktiTransfer', iData.buktiTransfer[0]);
        
        http.post(`${URLSVC}/ProyekPengajuanBiayas/RealisasiPengajuanBiaya`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_PROYEK_PENGAJUAN_BIAYA', payload: false });
                Swal.fire('Berhasil', `Pengajuan Pencairan Proyek Berhasil Di Realisasikan`, 'success');
                dispatch(setProyekPengajuanBiayas(iData.idProyek,null,query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekPengajuanBiaya_VerifikasiVendorPengajuanBiaya = (iData,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekPengajuanBiaya', iData.id);
        fd.append('isApprove', iData.isApprove);
        fd.append('catatan', iData.catatan);
        
        http.post(`${URLSVC}/ProyekPengajuanBiayas/VerifikasiVendorPengajuanBiaya`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_PROYEK_PENGAJUAN_BIAYA', payload: false });
                Swal.fire('Berhasil', `Verifikasi Vendor Berhasil Diproses`, 'success');
                // dispatch(setProyekPengajuanBiayas(iData.idProyek));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekPengajuanBiaya_BastPengajuanBiaya = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekPengajuanBiaya', iData.id);
        iData.fileBast[0] != undefined && fd.append('fileBast', iData.fileBast[0]);
        var iFile = 0;
        (iData.DataFileDokumentasis).map((item,index) => {
            if(item.FormFile[0] != undefined){
                fd.append(`fileDokumentasis[${iFile}].Id`, item.Id);
                fd.append(`fileDokumentasis[${iFile}].FormFile`, item.FormFile[0]);
                iFile++;
            }
        })
        
        http.post(`${URLSVC}/ProyekPengajuanBiayas/BastPengajuanBiaya`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_PROYEK_PENGAJUAN_BIAYA', payload: false });
                Swal.fire('Berhasil', `Berita Acara Serah Terima Berhasil Disimpan`, 'success');
                dispatch(setProyekPengajuanBiayas(iData.idProyek, null, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiVerifikasiVendorPengajuanBiayaByVendor = (iData,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('emailVendor', iData.email);
        fd.append('idProyekPengajuanBiaya', iData.id);
        fd.append('isApprove', iData.isApprove);
        fd.append('catatan', iData.catatan);
        
        http.post(`${URLSVC}/ProyekPengajuanBiayas/VerifikasiVendorPengajuanBiayaByVendor`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_PROYEK_PENGAJUAN_BIAYA', payload: false });
                Swal.fire('Berhasil', `Verifikasi Vendor Berhasil Diproses`, 'success');
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}