const initialState = {
    dataMenus: [],
    modalMenu: false,
    formMenu: {
        IdApplTaskParent: '',
        ApplTaskName: '',
        IdAppl: '',
        ControllerName: '',
        ActionName: '',
        Description: '',
        IconName: '',
    }
}

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_MENUS':
            return { ...state, dataMenus: action.payload }
        case 'FORM_MENU':
            return {
                ...state,
                formMenu: {
                    ...state.formMenu,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_MENU':
            return { ...state, formMenu: initialState.formMenu }
        case 'MODAL_MENU':
            return { ...state, modalMenu: action.payload }
        default:
            return state
    }
}

export default menuReducer;