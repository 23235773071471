import { combineReducers } from "redux";

import userReducer from "./userReducer";
import pegawaiReducer from "./pegawaiReducer";
import sidebarReducer from "./sidebarReducer";
import comboReducer from "./comboReducer";
import globalReducer from "./globalReducer";
import homeReducer from "./homeReducer";
import loginReducer from "./loginReducer";
import roleReducer from "./pengaturan/roleReducer";
import roleApplicationReducer from "./pengaturan/roleApplicationReducer";

import ubahProfileReducer from "./pengaturan/ubahProfileReducer";
import ubahPasswordReducer from "./pengaturan/ubahPasswordReducer";

import konfigurasiAplikasiReducer from "./pengaturan/konfigurasiAplikasiReducer";
import menuReducer from "./pengaturan/menuReducer";
import bannerReducer from "./pengaturan/bannerReducer";
import tvReducer from "./tvReducer";

import reportReducer from "./master/reportReducer";
import registrasiReducer from "./registrasi/registrasiReducer";
import simpananReducer from "./simpanan/simpananReducer";
import proyekUsulanReducer from "./proyek/proyekUsulanReducer";
import proyekReducer from "./proyek/proyekReducer";
import proyekPengajuanBiayaReducer from "./proyek/proyekPengajuanBiayaReducer";
import proyekBiayaReducer from "./proyek/proyekBiayaReducer";

const reducer = combineReducers({
  sidebarReducer,
  userReducer,
  pegawaiReducer,
  comboReducer,
  globalReducer,
  homeReducer,
  loginReducer,
  roleReducer,
  roleApplicationReducer,

  ubahProfileReducer,
  ubahPasswordReducer,
  konfigurasiAplikasiReducer,
  menuReducer,
  bannerReducer,
  tvReducer,

  reportReducer,
  registrasiReducer,
  simpananReducer,
  proyekUsulanReducer,
  proyekReducer,
  proyekPengajuanBiayaReducer,
  proyekBiayaReducer
});

export default reducer;
