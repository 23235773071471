import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { FUNCArraySelectKode, FUNCArraySelectId, FUNCArraySelectRole, http } from 'src/config';

/// ASY-SYIRKAH INDONESIA ////
export const setDataCombo = (type,Parameter) => {
    return (dispatch) => {
        switch(type){
            case "DATA_COMBO_ANGGOTA":
                http.get(`${URLSVC}/Registrasis/GetAnggotas`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        var array = [];
                        (data.data).map((v, i) => {
                            array.push({
                                value: v.id,
                                label: v.fullName
                            });
                        });
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_PERIODE_SIMPANAN_WAJIB":
                http.get(`${URLSVC}/Registrasis/GetPeriodeSimpananWajibs`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_JENIS_IDENTITAS":
                http.get(`${URLSVC}/Registrasis/GetJenisIdentitass`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_JENIS_KELAMIN":
                http.get(`${URLSVC}/Registrasis/GetJenisKelamins`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_PEKERJAAN":
                http.get(`${URLSVC}/Registrasis/GetPekerjaans`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_PENDIDIKAN":
                http.get(`${URLSVC}/Registrasis/GetPendidikans`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_WARGA_NEGARA":
                http.get(`${URLSVC}/Registrasis/GetWargaNegaras`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_AGAMA":
                http.get(`${URLSVC}/Registrasis/GetAgamas`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_WAKTU":
                http.get(`${URLSVC}/ProyekUsulans/GetRefWaktus`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_JENIS_PEMBIAYAAN":
                // http.get(`${URLSVC}/ProyekUsulans/GetRefJenisPembiayaans`).then((res) => {
                //     let data = res.data
                //     if (data.isSuccess) {
                //         let array = FUNCArraySelectId(data.data)
                //         dispatch({ type, payload: array })
                //     } else {
                //         Swal.fire('Fail', `${data.returnMessage}`, 'error')
                //     }
                // })
                break;
            case "DATA_COMBO_PROYEK_BIAYA_JENIS":
                http.get(`${URLSVC}/ProyekUsulans/GetRefProyekBiayaJenis`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_PROYEK_TAGIHAN_JENIS":
                // http.get(`${URLSVC}/ProyekUsulans/GetRefProyekTagihanJenis`).then((res) => {
                //     let data = res.data
                //     if (data.isSuccess) {
                //         let array = FUNCArraySelectId(data.data)
                //         dispatch({ type, payload: array })
                //     } else {
                //         Swal.fire('Fail', `${data.returnMessage}`, 'error')
                //     }
                // })
                break;
            case "DATA_COMBO_PROYEK_JENIS":
                http.get(`${URLSVC}/ProyekUsulans/GetRefProyekJenis`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_TIPE_FILE":
                var Data = [
                    {
                        value:1,
                        label:"Image"
                    },
                    {
                        value:2,
                        label:"Video"
                    },
                    {
                        value:3,
                        label:"PDF"
                    },
                ];
                dispatch({ type, payload: Data })
                break;
            case "DATA_COMBO_PROVINSI":
                http.get(`${URLSVC}/Registrasis/GetProvinsis`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_KABKOT":
                http.get(`${URLSVC}/Registrasis/GetKabkots?idProvinsi=${Parameter.idProvinsi}`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_KECAMATAN":
                http.get(`${URLSVC}/Admins/GetKecamatans?kabkotId=${Parameter.KabkotId}`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_DESA":
                http.get(`${URLSVC}/Admins/GetDesas?kecamatanId=${Parameter.KecamatanId}`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_RW":
                http.get(`${URLSVC}/Admins/GetRws?desaId=${Parameter.DesaId}`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_RT":
                http.get(`${URLSVC}/Admins/GetRts?rwId=${Parameter.RwId}`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        let array = FUNCArraySelectId(data.data)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_BULAN":
                var Data = [
                    {
                        value:1,
                        label:"Januari"
                    },
                    {
                        value:2,
                        label:"Februari"
                    },
                    {
                        value:3,
                        label:"Maret"
                    },
                    {
                        value:4,
                        label:"April"
                    },
                    {
                        value:5,
                        label:"Mei"
                    },
                    {
                        value:6,
                        label:"Juni"
                    },
                    {
                        value:7,
                        label:"Juli"
                    },
                    {
                        value:8,
                        label:"Agustus"
                    },
                    {
                        value:9,
                        label:"September"
                    },
                    {
                        value:10,
                        label:"Oktober"
                    },
                    {
                        value:11,
                        label:"November"
                    },
                    {
                        value:12,
                        label:"Desember"
                    }
                ];
                dispatch({ type, payload: Data })
                break;
            case "DATA_COMBO_APPL":
                http.get(`${URLSVC}/Webs/GetAppls`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        var array = [];
                        data.data.map((item, index) => {
                            array.push({Id:item.IdAppl,Name:item.ApplName})
                        });
                        array = FUNCArraySelectId(array)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_APPL_TASK":
                http.get(`${URLSVC}/Admins/GetApplTasks?IdAppl=${Parameter.IdAppl}`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        var array = [];
                        data.data.map((item, index) => {
                            array.push({Id:item.IdApplTask,Name:item.ApplTaskName})
                        });
                        array = FUNCArraySelectId(array)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_APPL_TASK_PARENT":
                http.get(`${URLSVC}/Webs/GetApplTasks?IdAppl=${Parameter.IdAppl}`).then((res) => {
                    let data = res.data
                    if (data.isSuccess) {
                        var array = [];
                        array.push({Id:null,Name:"- Pilih Salah Satu -"})
                        data.data.map((item, index) => {
                            if (item.IdApplTaskParent == null) {
                                array.push({Id:item.IdApplTask,Name:item.ApplTaskName})
                            }
                        });
                        array = FUNCArraySelectId(array)
                        dispatch({ type, payload: array })
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
                break;
            case "DATA_COMBO_TAHUN":
                var d = new Date();
                var TahunSekarang = d.getFullYear();
                var TahunMulai = TahunSekarang - 5;
                var Tahuns = [];
                for (TahunSekarang; TahunSekarang > TahunMulai; TahunSekarang--) {
                    Tahuns.push({Id:TahunSekarang,Name:TahunSekarang});
                }
                let array = FUNCArraySelectId(Tahuns)
                dispatch({ type, payload: array })
                break;
            default:
                break;
        }
    }
}
/// END ASY-SYIRKAH INDONESIA ///

export const setDataComboProv = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetProvinsi`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectKode(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboKab = (type, KdProv) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetKabupaten?KdProv=${KdProv}`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectKode(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboKec = (type, KdKab) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetKecamatan?KdKab=${KdKab}`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectKode(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboDesa = (type, KdKec) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetDesa?KdKec=${KdKec}`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectKode(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboJenisBantuan = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetJenisBantuans`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboPenghasilanTetap = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetStatusPenghasilanTetap`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboStatusKk = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetStatusKk`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboJenisKelamin = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetJenisKelamin`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboAgama = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetAgama`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboJenisPendidikan = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetJenisPendidikan`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboWarga = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetWarga`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataJenisGoldar = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetJenisGoldar`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboJenisPekerjaan = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetJenisPekerjaan`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataStatusKawin = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetStatusKawin`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboStatusKkNik = (type) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetStatusKkNik`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectId(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataComboRole = (type = 'DATA_COMBO_ROLE') => {
    return (dispatch) => {
        http.get(`${URLSVC}/Admins/GetRoles`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                let array = FUNCArraySelectRole(data.data)
                dispatch({ type, payload: array })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}