const initialState = {
    dataRoleApplications: [],
    dataRoleApplication: null,
    modalRoleApplication: false,
    formAddRoleApplication: {
        Id: '',
        RoleName: '',
        AssignTasks: ''
    }
}

const roleApplicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_ROLE_APPLICATIONS':
            return { ...state, dataRoleApplications: action.payload }
        case 'DATA_ROLE_APPLICATION':
            return { ...state, dataRoleApplication: action.payload }
        case 'ADD_ROLE_APPLICATION':
            return {
                ...state,
                formAddRoleApplication: {
                    ...state.formAddRoleApplication,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_ADD_ROLE_APPLICATION':
            return { ...state, formAddRoleApplication: initialState.formAddRoleApplication }
        case 'MODAL_ROLE_APPLICATION':
            return { ...state, modalRoleApplication: action.payload }
        default:
            return state
    }
}

export default roleApplicationReducer;