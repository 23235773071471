import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
  CSelect,
  CRow,
  CNavbar,
  CModal,
  CForm,
  CSwitch,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CTextarea,
  CContainer,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CAlert,
  CImg
} from '@coreui/react'
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { DataTable, ReactSelect, ReactDatePicker } from "src/components";
import { FUNCIndoDate, FUNCMonthToString, FUNCNumberFormat, FUNCSetFullName, FUNCYearToString } from "src/config";
import { setDataCombo, setProyekPengajuanBiaya } from "src/config/redux/action";
import './ViewDetil.css';

const ViewProyekPengajuanBiayaDetil = ({data}) => {
    useEffect(() => {
        if(data?.id != 0){
            reset();
            showModalViewProyekPengajuanBiaya();
            setHeadingForm("Lihat Detil Proyek Pengajuan Biaya")
        }
    }, [data])

  const { register:register, handleSubmit:handleSubmit, formState: { errors:errors }, control:control, setValue:setValue, getValues:getValues, reset:reset } = useForm();
  
  const dataUser = JSON.parse(localStorage.getItem("SessionLogin"))

  const { dataProyekPengajuanBiaya, modalViewProyekPengajuanBiaya } = useSelector(
    (state) => state.proyekPengajuanBiayaReducer
  );
  const { 
    dataComboProyekTagihanJenis,
    dataComboWaktu,
   } = useSelector((state) => state.comboReducer);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setDataCombo("DATA_COMBO_PROYEK_TAGIHAN_JENIS"));
  }, [dispatch]);

  useEffect(() => {
    if(dataProyekPengajuanBiaya){
      setValue("id",dataProyekPengajuanBiaya.id);
      setValue("status",dataProyekPengajuanBiaya.status);
      dataProyekPengajuanBiaya.tanggalPengajuan && setValue("tanggalPengajuan", new Date(dataProyekPengajuanBiaya.tanggalPengajuan));
      dataProyekPengajuanBiaya.rpPengajuan && setValue("rpPengajuan", FUNCNumberFormat(dataProyekPengajuanBiaya.rpPengajuan));
      setValue("judul",dataProyekPengajuanBiaya.judul);
      setValue("keterangan",dataProyekPengajuanBiaya.keterangan);
      dataProyekPengajuanBiaya.tanggalDibutuhkan && setValue("tanggalDibutuhkan", new Date(dataProyekPengajuanBiaya.tanggalDibutuhkan));
      dataProyekPengajuanBiaya.tanggalRealisasi && setValue("tanggalRealisasi", new Date(dataProyekPengajuanBiaya.tanggalRealisasi));
      dataProyekPengajuanBiaya.rpRealisasi && setValue("rpRealisasi", FUNCNumberFormat(dataProyekPengajuanBiaya.rpRealisasi));
      setValue("namaVendor",dataProyekPengajuanBiaya.namaVendor);
      setValue("alamatVendor",dataProyekPengajuanBiaya.alamatVendor);
      setValue("emailVendor",dataProyekPengajuanBiaya.emailVendor);
      setValue("noHandphoneVendor",dataProyekPengajuanBiaya.noHandphoneVendor);
      setValue("noTelponVendor",dataProyekPengajuanBiaya.noTelponVendor);

      dataProyekPengajuanBiaya.tanggalApprove ? setValue("tanggalApprove", new Date(dataProyekPengajuanBiaya.tanggalApprove)) : setValue("tanggalApproveNull", "- Sedang Proses -");

      setValue("namaProyek",dataProyekPengajuanBiaya.namaProyek);
      setValue("namaNasabah",dataProyekPengajuanBiaya.namaNasabah);
      setValue("atasNamaProyek",dataProyekPengajuanBiaya.atasNamaProyek);

    }
  }, [dataProyekPengajuanBiaya])


  const [headingForm, setHeadingForm] = useState(false);

  const showModalViewProyekPengajuanBiaya = () => dispatch({ type: 'MODAL_VIEW_PROYEK_PENGAJUAN_BIAYA', payload: !modalViewProyekPengajuanBiaya });

  return (
    <div>
      {/* SECTION MODAL USULAN PROYEK  */}
      <CModal
        show={modalViewProyekPengajuanBiaya}
        onClose={showModalViewProyekPengajuanBiaya}
        size={"lg"}
    >
        <CModalHeader closeButton>
            {headingForm}
        </CModalHeader>
        <CModalBody>
            <CContainer>
                <form className="form-horizontal">
                    <fieldset disabled aria-readonly>
                        <input {...register("id")} name="id" type={"hidden"} className="form-control"  />
                        {dataProyekPengajuanBiaya && <div className="form-group">
                            <div className="form-row">
                                <div className="col-md-4">
                                    <CLabel className={"font-weight-bold"}>Status Approve Vendor</CLabel>
                                    <div>{dataProyekPengajuanBiaya.tanggalApprove == null ? <div>Sedang Proses</div> : dataProyekPengajuanBiaya.isApprove == true ? <div>Disetujui</div> : <div>Ditolak</div>}</div>
                                </div>
                                <div className="col-md-4">
                                    <CLabel className={"font-weight-bold"}>Status Pengajuan</CLabel>
                                    <div>{dataProyekPengajuanBiaya.statusPengajuan?.status}</div>
                                </div>
                                
                            </div>
                        </div>}
                        
                        <div className="form-row">
                            <div className="col-md-6">
                                {
                                    dataProyekPengajuanBiaya && dataProyekPengajuanBiaya.isApprove == false ?
                                    <div className='form-group'>
                                        <CLabel>Tanggal Approve</CLabel>
                                        <input readOnly name="tanggalApproveFalse" className="form-control" value="- Sedang Proses -" />
                                    </div>
                                    :
                                    <div className='form-group'>
                                        <CLabel>Tanggal Approve</CLabel>
                                        <Controller
                                            control={control}
                                            defaultValue={""}
                                            name="tanggalApprove"
                                            render={({field }) => (
                                                <ReactDatePicker
                                                    dateFormat="dd-MM-yyyy"
                                                    selected={getValues("tanggalApprove")}
                                                />
                                            )}
                                        />
                                    </div> 

                                }
                                <div className='form-group'>
                                    <CLabel>Rupiah Pengajuan</CLabel>
                                    <input name="rpPengajuan" className="form-control" {...register("rpPengajuan", { required: 'Kolom wajib diisi' })}  />
                                    {errors.rpPengajuan && <span className="text-danger">{errors.rpPengajuan?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Rupiah Realisasi</CLabel>
                                    <input name="rpRealisasi" className="form-control" placeholder="Ketikkan Email Anggota..." {...register("rpRealisasi", { required: 'Kolom wajib diisi' })}  />
                                    {errors.rpRealisasi && <span className="text-danger">{errors.rpRealisasi?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Judul</CLabel>
                                    <input name="judul" className="form-control" {...register("judul", { required: 'Kolom wajib diisi' })}  />
                                    {errors.judul && <span className="text-danger">{errors.judul?.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <CLabel>Tanggal Dibutuhkan</CLabel>
                                    <Controller
                                        control={control}
                                        defaultValue={""}
                                        name="tanggalDibutuhkan"
                                        render={({field }) => (
                                            <ReactDatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={getValues("tanggalDibutuhkan")}
                                                onChange={(date) => field.onChange(date)}
                                                placeholderText="- Pilih Tanggal -"
                                            />
                                        )}
                                        rules={{required:false}}
                                    />
                                    {errors.tanggalDibutuhkan && <span className="text-danger">{errors.tanggalDibutuhkan?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Tanggal Pengajuan</CLabel>
                                    <Controller
                                        control={control}
                                        defaultValue={""}
                                        name="tanggalPengajuan"
                                        render={({field }) => (
                                            <ReactDatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={getValues("tanggalPengajuan")}
                                                onChange={(date) => field.onChange(date)}
                                                placeholderText="- Pilih Tanggal -"
                                            />
                                        )}
                                        rules={{required:false}}
                                    />
                                    {errors.tanggalPengajuan && <span className="text-danger">{errors.tanggalPengajuan?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Tanggal Realisasi</CLabel>
                                    <Controller
                                        control={control}
                                        defaultValue={""}
                                        name="tanggalRealisasi"
                                        render={({field }) => (
                                            <ReactDatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={getValues("tanggalRealisasi")}
                                                onChange={(date) => field.onChange(date)}
                                                placeholderText="- Pilih Tanggal -"
                                            />
                                        )}
                                        rules={{required:false}}
                                    />
                                    {errors.tanggalRealisasi && <span className="text-danger">{errors.tanggalRealisasi?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Keterangan</CLabel>
                                    <textarea name="keterangan" className="form-control" {...register("keterangan", { required: 'Kolom wajib diisi' })}></textarea>
                                    {errors.keterangan && <span className="text-danger">{errors.keterangan?.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <div className='alert alert-primary mt-3'>
                                    <h5>Detil Vendor</h5>
                                </div>
                                <CRow>
                                    <CCol md="6">
                                    <div className='form-group'>
                                        <CLabel>Nama Vendor</CLabel>
                                        <input readOnly name="namaVendor" className="form-control" placeholder="Ketikkan Nama Pemohon..." {...register("namaVendor")}/>
                                        {errors.namaVendor && <span className="text-danger">{errors.namaVendor?.message}</span>}
                                    </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className='form-group'>
                                            <CLabel>Alamat Vendor</CLabel>
                                            <input readOnly name="alamatVendor" className="form-control" placeholder="Ketikkan Status Anggota..." {...register("alamatVendor")}/>
                                            {errors.alamatVendor && <span className="text-danger">{errors.alamatVendor?.message}</span>}
                                        </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className='form-group'>
                                            <CLabel>Email Vendor</CLabel>
                                            <input readOnly name="emailVendor" className="form-control" {...register("emailVendor")}/>
                                            {errors.emailVendor && <span className="text-danger">{errors.emailVendor?.message}</span>}
                                        </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className='form-group'>
                                            <CLabel>Nomor Handphone Vendor</CLabel>
                                            <input readOnly name="noHandphoneVendor" className="form-control" {...register("noHandphoneVendor")}/>
                                            {errors.noHandphoneVendor && <span className="text-danger">{errors.noHandphoneVendor?.message}</span>}
                                        </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className='form-group'>
                                            <CLabel>Nomor Telepon Vendor</CLabel>
                                            <input readOnly name="noTelponVendor" className="form-control" {...register("noTelponVendor")}/>
                                            {errors.noTelponVendor && <span className="text-danger">{errors.noTelponVendor?.message}</span>}
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                            <div className="col-md-12">
                                <div className='alert alert-primary mt-3'>
                                    <h5>Detil Proyek</h5>
                                </div>
                                <CRow>
                                    <CCol md="6">
                                    <div className='form-group'>
                                        <CLabel>Nama Proyek</CLabel>
                                        <input readOnly name="namaProyek" className="form-control" placeholder="Ketikkan Nama Pemohon..." {...register("namaProyek")}/>
                                        {errors.namaProyek && <span className="text-danger">{errors.namaProyek?.message}</span>}
                                    </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className='form-group'>
                                            <CLabel>Nama Nasabah</CLabel>
                                            <input readOnly name="namaNasabah" className="form-control" placeholder="Ketikkan Status Anggota..." {...register("namaNasabah")}/>
                                            {errors.namaNasabah && <span className="text-danger">{errors.namaNasabah?.message}</span>}
                                        </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className='form-group'>
                                            <CLabel>Atas Nama Proyek</CLabel>
                                            <input readOnly name="atasNamaProyek" className="form-control" {...register("atasNamaProyek")}/>
                                            {errors.atasNamaProyek && <span className="text-danger">{errors.atasNamaProyek?.message}</span>}
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                        <div className='alert alert-primary mt-3'>
                            <h5>Lampiran</h5>
                        </div>
                        <div className="form-row">
                            <div className="col-md-3">
                                <CLabel className={"font-weight-bold"}>File Pengajuan</CLabel>
                                <div>{dataProyekPengajuanBiaya?.filePengajuan != null ? <a href={dataProyekPengajuanBiaya.filePengajuan} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                            </div>
                            <div className="col-md-3">
                                <CLabel className={"font-weight-bold"}>File Penawaran</CLabel>
                                <div>{dataProyekPengajuanBiaya?.filePenawaran != null ? <a href={dataProyekPengajuanBiaya.filePenawaran} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                            </div>
                            <div className="col-md-3">
                                <CLabel className={"font-weight-bold"}>File Pemesanan</CLabel>
                                <div>{dataProyekPengajuanBiaya?.filePemesanan != null ? <a href={dataProyekPengajuanBiaya.filePemesanan} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                            </div>
                            <div className="col-md-3">
                                <CLabel className={"font-weight-bold"}>File Bukti Transfer</CLabel>
                                <div>{dataProyekPengajuanBiaya?.fileBuktiTransfer != null ? <a href={dataProyekPengajuanBiaya.fileBuktiTransfer} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                            </div>
                        </div>
                        <div className='alert alert-primary mt-3'>
                            <h5>Dokumentasi</h5>
                        </div>
                        <div className="form-row">
                            {
                                dataProyekPengajuanBiaya?.dokumentasis != null && dataProyekPengajuanBiaya?.dokumentasis.map((item,index) => {
                                    return (<div className="col-md-3">
                                        <div>{item != null ? <a href={item} className="badge badge-info p-2" target={"_blank"}>Download File {index+1}</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                                    </div>)
                            })
                            }
                            
                        </div>
                        
                    </fieldset>
                </form>
            </CContainer>
        </CModalBody>
        <CModalFooter>
            <CButton color="danger" onClick={showModalViewProyekPengajuanBiaya}>TUTUP</CButton>{' '}
        </CModalFooter>
    </CModal>
    {/* END SECTION MODAL USULAN PROYEK  */}
    </div>
  );
};

export default ViewProyekPengajuanBiayaDetil;