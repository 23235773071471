const initialState = {
    dataReports: [],
    dataReport: null,
    modalReport: false,
}

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_REPORTS':
            return { ...state, dataReports: action.payload }
        case 'DATA_REPORT':
            return { ...state, dataReport: action.payload }
        case 'ADD_REPORT':
            return {
                ...state,
                formReport: {
                    ...state.formReport,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_ADD_REPORT':
            return { ...state, formReport: initialState.formReport }
        case 'MODAL_REPORT':
            return { ...state, modalReport: action.payload }
        default:
            return state
    }
}

export default reportReducer;