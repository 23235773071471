import { FUNCDateToString, http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from 'sweetalert2'
import { loadingBar } from "../globalAction";
import { reset } from "redux-form";

export const setInvestorInvestasis = (idProyekInvestor, query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekBiayas/GetInvestorInvestasis?idProyekInvestor=${idProyekInvestor}&applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_INVESTOR_INVESTASIS', payload: data.data})
            } else {
                dispatch({type: 'DATA_INVESTOR_INVESTASIS', payload: []})
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setInvestorInvestasi = (idProyekInvestorBiaya) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekBiayas/GetInvestorInvestasi?idProyekInvestorBiaya=${idProyekInvestorBiaya}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_INVESTOR_INVESTASI', payload: data.data})
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setInvestorModalKembalis = (idProyekInvestor, query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekBiayas/GetInvestorModalKembalis?idProyekInvestor=${idProyekInvestor}&applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_INVESTOR_MODAL_KEMBALIS', payload: data.data})
            } else {
                dispatch({type: 'DATA_INVESTOR_MODAL_KEMBALIS', payload: []})
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setInvestorModalKembali = (idProyekInvestorBiaya) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekBiayas/GetInvestorModalKembali?idProyekInvestorBiaya=${idProyekInvestorBiaya}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_INVESTOR_MODAL_KEMBALI', payload: data.data})
            } else {
                dispatch({type: 'DATA_INVESTOR_MODAL_KEMBALI', payload: data.data})
            }
        })
    }
}

export const apiUploadInvestasi = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekInvestor', iData.idProyekInvestor);
        fd.append('rp', iData.rp);
        fd.append('tanggalBayar', FUNCDateToString(iData.tanggalBayar));
        iData.buktiBayar[0] != undefined && fd.append('buktiBayar', iData.buktiBayar[0]);
        iData.akadWakalahTtd[0] != undefined && fd.append('akadWakalahTtd', iData.akadWakalahTtd[0]);

        http.post(`${URLSVC}/Proyeks/UploadInvestasi`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_UPLOAD_BIAYA_AKAD', payload: false});
                Swal.fire('Berhasil', 'Berhasil Upload Bukti Investor', 'success');
                dispatch(setInvestorInvestasis(iData.idProyekInvestor, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiVerifikasiInvestasi = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekInvestorBiaya', iData.idProyekInvestorBiaya);
        fd.append('isApprove', iData.isApprove);
        fd.append('catatan', iData.catatan);
        
        http.post(`${URLSVC}/Proyeks/VerifikasiInvestasi`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_VERIFIKASI_INVESTASI', payload: false});
                Swal.fire('Berhasil', 'Berhasil Verifikasi Investasi', 'success');
                dispatch(setInvestorInvestasis(iData.idProyekInvestor, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setNasabahBiayas = (idProyek=null, query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekBiayas/GetNasabahBiayas?idProyek=${idProyek}&applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_NASABAH_BIAYAS', payload: data.data})
            } else {
                dispatch({type: 'DATA_NASABAH_BIAYAS', payload: []})
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setNasabahBiaya = (idProyekBiaya) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekBiayas/GetNasabahBiaya?idProyekBiaya=${idProyekBiaya}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_NASABAH_BIAYA', payload: data.data})
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUploadBiayaNasabah = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekBiaya', iData.idProyekBiaya);
        fd.append('rp', iData.rp);
        fd.append('tanggalBayar', FUNCDateToString(iData.tanggalBayar));
        iData.buktiBayar[0] != undefined && fd.append('buktiBayar', iData.buktiBayar[0]);

        http.post(`${URLSVC}/ProyekBiayas/UploadBiayaNasabah`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_UPLOAD_BIAYA_NASABAH', payload: false});
                Swal.fire('Berhasil', 'Berhasil Upload Bukti Setor Nasabah', 'success');
                dispatch(setNasabahBiayas(iData.idProyekInvestor, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiVerifikasiBiayaNasabah = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekBiaya', iData.idProyekBiaya);
        fd.append('isApprove', iData.isApprove);
        fd.append('catatan', iData.catatan);

        http.post(`${URLSVC}/ProyekBiayas/VerifikasiBiayaNasabah`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_VERIFIKASI_BIAYA_NASABAH', payload: false});
                Swal.fire('Berhasil', 'Berhasil Verifikasi Dana Nasabah', 'success');
                dispatch(setNasabahBiayas(iData.idProyekInvestor, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUploadModalKembali = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekInvestorBiaya', iData.id);
        fd.append('rp', iData.rp);
        fd.append('tanggalBayar', FUNCDateToString(iData.tanggalBayar));
        iData.buktiBayar[0] != undefined && fd.append('buktiBayar', iData.buktiBayar[0]);

        http.post(`${URLSVC}/ProyekBiayas/UploadModalKembali`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_UPLOAD_MODAL_KEMBALI', payload: false});
                Swal.fire('Berhasil', 'Pengembalian Modal Berhasil di Upload', 'success');
                dispatch(setInvestorModalKembalis(iData.idProyekInvestor, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiVerifikasiModalKembali = (iData,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekInvestorBiaya', iData.id);
        fd.append('isApprove', iData.isApprove);
        fd.append('catatan', iData.catatan);

        http.post(`${URLSVC}/ProyekBiayas/VerifikasiModalKembali`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_VERIFIKASI_MODAL_KEMBALI', payload: false});
                Swal.fire('Berhasil', 'Pengembalian Modal Berhasil di Verifikasi', 'success');
                dispatch(setInvestorModalKembalis(iData.idProyekInvestor));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setProyekBiayaProyeks = (query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekBiayas/GetProyeks?applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_BIAYA_PROYEKS', payload: data.data})
                reset();
            } else {
                dispatch({type: 'DATA_PROYEK_BIAYA_PROYEKS', payload: []})
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setProyekBiayaProyek = (id) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekBiayas/GetProyek?id=${id}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                reset();
                dispatch({type: 'DATA_PROYEK_BIAYA_PROYEK', payload: data.data})
            } else {
                Swal.fire('Gagal1', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setProyekBiayaInvestors = (idProyek) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekBiayas/GetInvestors?idProyek=${idProyek}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_BIAYA_INVESTORS', payload: data.data})
            } else {
                dispatch({type: 'DATA_PROYEK_BIAYA_INVESTORS', payload: []})
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setProyekBiayaInvestor = (idProyekInvestor) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekBiayas/GetInvestor?idProyekInvestor=${idProyekInvestor}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_BIAYA_INVESTOR', payload: data.data})
                dispatch({type: 'DATA_PROYEK_INVESTOR', payload: data.data});
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setResetBiayaProyek = (iData, query,resetEdit) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyek', iData.idProyek);
        fd.append('tanggalAnggsuran', FUNCDateToString(iData.tanggalAngsuran));
        fd.append('jumlahAngsuran', iData.jumlahAngsuran);
        fd.append('rpAngsuran', iData.rpAngsuran);
        fd.append('idWaktuTransfer', iData.idWaktuTransfer?.value || '');

        http.post(`${URLSVC}/ProyekBiayas/ResetBiayaProyek`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire('Berhasil', 'Tagihan berhasil diubah', 'success');
                dispatch(setProyekBiayaProyeks(query));
                dispatch(setProyekBiayaProyek(iData.idProyek));
                dispatch(setNasabahBiayas(iData.idProyek, query));
                resetEdit();
                // dispatch({type: 'DATA_PROYEK_BIAYA_PROYEKS', payload: data.data})
                // dispatch({type: 'DATA_PROYEK_BIAYA_PROYEK', payload: data.data})
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}