const initialState = {
    dataInvestorInvestasis: [],
    dataInvestorInvestasi: null,
    modalUploadInvestasi: false,
    modalVerifikasiInvestasi: false,
    dataNasabahBiayas: [],
    dataNasabahBiaya: null,
    modalUploadBiayaNasabah: false,
    modalVerifikasiBiayaNasabah: false,
    dataInvestorModalKembalis: [],
    dataInvestorModalKembali: null,
    modalUploadModalKembali: false,
    modalVerifikasiModalKembali: false,
    modalViewInvestorInvestasi: false,
    modalViewNasabahBiaya: false,
    dataProyekBiayaProyeks: [],
    dataProyekBiayaProyek: [],
    modalViewProyekBiayaProyek: false,
    dataProyekBiayaInvestors: [],
    modalEditTagihan: false,
};

const proyekBiayaReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DATA_INVESTOR_INVESTASIS":
            return { ...state, dataInvestorInvestasis: action.payload};
        case "DATA_INVESTOR_INVESTASI":
            return { ...state, dataInvestorInvestasi: action.payload};
        case "MODAL_UPLOAD_INVESTASI":
            return { ...state, modalUploadInvestasi: action.payload};
        case "MODAL_VERIFIKASI_INVESTASI":
            return { ...state, modalVerifikasiInvestasi: action.payload};
        case "DATA_NASABAH_BIAYAS":
            return { ...state, dataNasabahBiayas: action.payload};
        case "DATA_NASABAH_BIAYA":
            return { ...state, dataNasabahBiaya: action.payload};
        case "MODAL_UPLOAD_BIAYA_NASABAH":
            return { ...state, modalUploadBiayaNasabah: action.payload};
        case "MODAL_VERIFIKASI_BIAYA_NASABAH":
            return { ...state, modalVerifikasiBiayaNasabah: action.payload};
        case "MODAL_UPLOAD_MODAL_KEMBALI":
            return { ...state, modalUploadModalKembali: action.payload};
        case "MODAL_VERIFIKASI_MODAL_KEMBALI":
            return { ...state, modalVerifikasiModalKembali: action.payload};    

        case "DATA_INVESTOR_MODAL_KEMBALIS":
            return { ...state, dataInvestorModalKembalis: action.payload};
        case "DATA_INVESTOR_MODAL_KEMBALI":
            return { ...state, dataInvestorModalKembali: action.payload};

        
        case "MODAL_VIEW_INVESTOR_INVESTASI":
            return { ...state, modalViewInvestorInvestasi: action.payload};
        case "MODAL_VIEW_NASABAH_BIAYA":
            return { ...state, modalViewNasabahBiaya: action.payload};

        case "DATA_PROYEK_BIAYA_PROYEKS":
            return { ...state, dataProyekBiayaProyeks: action.payload};
        case "DATA_PROYEK_BIAYA_PROYEK":
            return { ...state, dataProyekBiayaProyek: action.payload};
        case "MODAL_VIEW_PROYEK_BIAYA_PROYEK":
            return { ...state, modalViewProyekBiayaProyek: action.payload};
        case "DATA_PROYEK_BIAYA_INVESTORS":
            return { ...state, dataProyekBiayaInvestors: action.payload};
        case "MODAL_EDIT_TAGIHAN":
            return { ...state, modalEditTagihan: action.payload};

        default:
            return state;
    }
};


export default proyekBiayaReducer;