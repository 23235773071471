const initialState = {
    dataTvs:[]
}

const tvReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_TVS':
            return { ...state, dataTvs: action.payload }
        default:
            return state
    }
}

export default tvReducer;