import React, { useRef } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function CkEditor({...props}) {
    const refEditor = useRef(null);
    const editorConfiguration = {
        extraPlugins: [uploadPlugin],
        shouldNotGroupWhenFull: props.shouldNotGroupWhenFull == undefined ? true:props.shouldNotGroupWhenFull,
        toolbar: {
          items: [
            'importWord',
            'heading',
            '|',
			'sourceEditing',
            '|',
			'importWord',
			'exportWord',
			'exportPdf',
            '|',
            'fontSize',
            'fontFamily',
            '|',
            'bold',
            'italic',
            '|',
            'alignment',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'indent',
            'outdent',
            '|',
            'link',
            'blockQuote',
            'imageUpload',
            'insertTable',
            'mediaEmbed',
            '|',
            'undo',
            'redo',
            'placeholder',
          ],
        },
        image: {
          toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        placeholderConfig: {
          types: ['Name', 'DOB'],
        },
      };
    function uploadAdapter(loader) {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
                const body = new FormData();
                loader.file.then((file) => {
                body.append("file", file);
                // let headers = new Headers();
                // headers.append("Origin", "http://localhost:3000");

                fetch(`https://33333.cke-cs.com/easyimage/upload/`, {
                  method: "post",
                  body: body,
                  headers: new Headers(
                    {
                        "Authorization":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzNyIsImF1dGgiOnsiY29sbGFib3JhdGlvbiI6eyIqIjp7InJvbGUiOiJ3cml0ZXIifX0sImNrYm94Ijp7InJvbGUiOiJhZG1pbiJ9fSwidXNlciI6eyJpZCI6IjM3IiwibmFtZSI6IkFsdGhlYSBTb2xsb3dheWUiLCJlbWFpbCI6InZha2p1d0BvZGV6Lm5wIn0sImlzRGV2VG9rZW4iOnRydWUsInRpbWVzdGFtcCI6MTY4NzIzMjk0ODE3Miwic2lnbmF0dXJlIjoiMTA3NGQ2MzJkNDBmNjk0NWViZTA3YjcyZDkwODNjNmFmNDFkZjdhNGMyN2RmNTM4MDUxNGIxYzFmMDIxMTllZCIsImV4cCI6MTY4NzIzNjU0OCwianRpIjoiampMQkc1SXNzYTJBWXo2WlV2QlBSRjUxdERORG04Z3UiLCJhdWQiOiJldHM0MUwza1luQmdHNWFsN3pjeCIsImlhdCI6MTY4NzIzMjk0OH0.sUKxHp1GdgRa6A2zyte-QgSfFEzXs9pASLuPfv8oGc0"
                    })
                })
                  .then((res) => res.json())
                  .then((res) => {
                    resolve({
                      default: res.default
                    });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            });
          }
        };
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }
    return (
        <CKEditor
            ref={refEditor}
            editor={ClassicEditor}
            config={editorConfiguration}
            onChange={ ( event, editor ) => {
                const data = editor.getData();
            }}
            // onBlur={ ( event, editor ) => {
            //     console.log( 'Blur.', editor );
            // }}
            // onFocus={ ( event, editor ) => {
            //     console.log( 'Focus.', editor );
            // }}
            {...props}
        />
    )
}

export default CkEditor;