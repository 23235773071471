import { http, FUNCDateToString } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from './globalAction';
import { setDataComboRole } from './comboAction';


export const setDataUsers = (id) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Users/GetUsers?applTaskId=${id}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_USERS', payload: data.data})
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataUser = (idUser) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Users/GetUser?param=${idUser}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'DATA_NEW_ANGGOTA', payload: data.data })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setFormUser = (formType, formValue) => {
    return {type: 'ADD_USER', formType, formValue}
}

export const apiAddUser = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('Id', iData.Id);
        fd.append('Username', iData.Username);
        fd.append('Password', iData.Password);
        fd.append('Email', iData.Email);
        fd.append('FirstName', iData.FirstName);
        fd.append('MiddleName', iData.MiddleName);
        fd.append('LastName', iData.LastName);
        fd.append('Address', iData.Address);
        fd.append('PhoneNumber', iData.PhoneNumber);
        fd.append('MobileNumber', iData.MobileNumber);
        fd.append('RoleId', iData.Role.value);

        http.post(`${URLSVC}/Users/AddUser`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire('Success', `You have successfully added data`, 'success');
                dispatch({ type: 'MODAL_USER', payload: false })
                dispatch(setDataUsers())
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUpdateUser = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdUser', iData.IdUser);
        fd.append('Username', iData.Username);
        fd.append('Password', iData.Password);
        fd.append('Email', iData.Email);
        fd.append('FirstName', iData.FirstName);
        fd.append('MiddleName', iData.MiddleName);
        fd.append('LastName', iData.LastName);
        fd.append('Address', iData.Address);
        fd.append('PhoneNumber', iData.PhoneNumber);
        fd.append('MobileNumber', iData.MobileNumber);
        fd.append('RoleId', iData.Role.value);
        
        http.post(`${URLSVC}/Users/UpdateUser`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire('Success', `You have successfully updated data username (${iData.FirstName})`, 'success')
                dispatch({ type: 'MODAL_USER', payload: false })
                dispatch(setDataUsers());
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}
const GetInfoUser = () => {
    http.get(`${URLSVC}/Users/GetInfoUser`)
      .then((res) => {
        let data = res.data;
        if (data.isSuccess) {
          localStorage.setItem("SessionLogin", JSON.stringify(data.data));
        } else {
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        // if (err.response.status == 401) {
        //   localStorage.removeItem('TOKEN');
        // } else {
        //   Swal.fire('Terjadi Kesalahan', `${JSON.stringify(err.message)}`, 'error')
        // }
      });
  };

export const apiEditUser = (iData, query, isUtility=true) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('id', iData.id);
        //fd.append('Username', iData.Username);
        fd.append('email', iData.email);
        fd.append('firstName', iData.firstName);
        fd.append('middleName', iData.middleName || '');
        fd.append('lastName', iData.lastName || '');
        fd.append('address', iData.address);
        fd.append('phoneNumber', iData.phoneNumber);
        fd.append('mobileNumber', iData.mobileNumber);
        fd.append('idJenisIdentitas', iData.idJenisIdentitas.value);
        fd.append('nomorIdentitas', iData.nomorIdentitas);
        //profil
        fd.append('idJenisKelamin', iData.idJenisKelamin.value);
        fd.append('tanggalLahir', FUNCDateToString(iData.tanggalLahir));
        fd.append('tempatLahir', iData.tempatLahir);
        fd.append('alamat', iData.alamat);
        fd.append('idKabkot', iData.idKabkot.value);
        fd.append('kodePos', iData.kodePos);
        fd.append('namaIbuKandung', iData.namaIbuKandung);
        fd.append('idPendidikan', iData.idPendidikan.value);
        fd.append('idAgama', iData.idAgama.value);
        fd.append('idPekerjaan', iData.idPekerjaan.value);
        fd.append('namaPekerjaan', iData.namaPekerjaan);
        fd.append('namaPerusahaan', iData.namaPerusahaan);
        fd.append('namaAhliWaris', iData.namaAhliWaris);
        fd.append('hubunganAhliWaris', iData.hubunganAhliWaris);
        fd.append('alamatAhliWaris', iData.alamatAhliWaris);
        fd.append('noHandphoneAhliWaris', iData.noHandphoneAhliWaris);
        fd.append('idWargaNegara', iData.idWargaNegara.value);
        fd.append('namaKoperasiLain', iData.namaKoperasiLain || '');
        fd.append('namaBank', iData.namaBank);
        fd.append('noRekening', iData.noRekening);
        fd.append('namaPemilikRekening', iData.namaPemilikRekening);
        if(iData.pasFoto)
            fd.append('pasFoto', iData.pasFoto[0]);

        http.post(`${URLSVC}/Users/Edit`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire(
                  "Berhasil",
                  `Anda berhasil melakukan update akun atas nama (${iData.fullName})`,
                  "success"
                ).then(() => {
                  dispatch({ type: "MODAL_VIEW_USER", payload: false });
                  dispatch(setDataUsers(query));
                  GetInfoUser();
                  if(!isUtility)
                  window.location = "/ubah-profile";
                });
                
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}



export const apiAktivasiUser = (id, username, query) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: `Anda akan aktivasi username (${username})!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                http.get(`${URLSVC}/Users/SetActive?id=${id}`).then((res) => {
                    let data = res.data;
                    if (data.isSuccess) {
                        Swal.fire('Success', `Anda berhasil aktivasi username (${username})`, 'success')
                        dispatch(setDataUsers(query))
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
            }
        })
    }
}

export const apiNonAktivasiUser = (id, username, query) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: `Anda akan non-aktivasi username (${username})!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                http.get(`${URLSVC}/Users/SetNonActive?id=${id}`).then((res) => {
                    let data = res.data;
                    if (data.isSuccess) {
                        Swal.fire('Success', `Anda berhasil non-aktivasi username (${username})`, 'success')
                        dispatch(setDataUsers(query))
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
            }
        })
    }
}