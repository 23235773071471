import { http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from './globalAction';

export const setTvs = (Year) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Admins/GetDashboard?Year=2020`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                var Data = data.data;

                var chartAgencyName = "";
                var chartLabels = [];
                var chartData = [];

                var dataChart = [];
                (Data.PriorityDataCharts).map((value,index) => {
                    chartAgencyName = value.AgencyName;
                    chartLabels = value.Labels;
                    chartData = value.Values; 
                    dataChart.push({
                        AgencyName:chartAgencyName,
                        Label: chartLabels,
                        Data: chartData
                    })
                })
                Data.DataCharts = dataChart;
                dispatch({type: 'DATA_TVS', payload: Data})
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}