import { FUNCCleanNumberFormat, FUNCDateToString, http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from 'sweetalert2'
import { loadingBar } from "../globalAction";
import { setNasabahBiayas } from "./proyekBiayaAction";

export const setProposalProyeks = (nextStatus="", query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Proyeks/GetProposalProyeks?applTaskId=${query}&nextStatusProyek=${nextStatus}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROPOSAL_PROYEKS', payload: data.data})
            } else {
                dispatch({type: 'DATA_PROPOSAL_PROYEKS', payload: []})
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setProposalProyek = (id) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Proyeks/GetProposalProyek?id=${id}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_USULAN', payload: data.data})
                dispatch({type: 'DATA_PROPOSAL_PROYEK', payload: data.data})
                dispatch({type: 'DATA_PROYEK', payload: data.data})
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setProyekInvestorsByUser = (query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Proyeks/GetProyekInvestorsByUser?applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_INVESTORS', payload: data.data})
            } else {
                dispatch({type: 'DATA_PROYEK_INVESTORS', payload: []})
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setProposalProyeksAnggota = (nextStatus, query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Proyeks/GetProposalProyeksAnggota?nextStatusProyek=${nextStatus}&applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROPOSAL_PROYEKS', payload: data.data})
            } else {
                dispatch({type: 'DATA_PROPOSAL_PROYEKS', payload: []})
            }
        })
    }
}

export const apiSetMinat = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyek', iData.idProyek);
        fd.append('rpInvestasi', iData.rpInvestasi);
        fd.append('tanggalSetor', FUNCDateToString(iData.tanggalSetor));

        http.post(`${URLSVC}/Proyeks/SetMinat`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_SET_MINAT', payload: false});
                Swal.fire('Berhasil', 'Berhasil Mengajukan Minat', 'success');
                dispatch(setProposalProyeks(null, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setProyekInvestors = (idProyek, query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Proyeks/GetProyekInvestors?idProyek=${idProyek}&applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_INVESTORS', payload: data.data});
            } else {
                dispatch({type: 'DATA_PROYEK_INVESTORS', payload: []});
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        });
    }
}

export const setProyekInvestor = (id) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Proyeks/GetProyekInvestor?idProyekInvestor=${id}`).then((res) => {
            let data =res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_INVESTOR', payload: data.data});
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiEditMinat = (iData, query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekInvestor', iData.idProyekInvestor);
        fd.append('rpInvestasi', FUNCCleanNumberFormat(iData.rpInvestasi));
        fd.append('tanggalSetor', FUNCDateToString(iData.tanggalSetor));

        http.post(`${URLSVC}/Proyeks/EditMinat`,fd).then((res) => {
            loadingBar(false);
            let data =res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_SET_MINAT', payload: false });
                Swal.fire('Berhasil', 'Berhasil Menyimpan Perubahan Minat', 'success');
                dispatch(setProposalProyeks(null, query));
                reset()
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiVerifikasiMinatAkad = (iData, query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idProyekInvestor', iData.idProyekInvestor);
        fd.append('isApprove', true);
        fd.append('catatan', iData.catatan);
        iData.fileAkadWakalah !== undefined && fd.append('fileAkadWakalah', iData.fileAkadWakalah[0]);

        http.post(`${URLSVC}/Proyeks/VerifikasiMinatAkad`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_VERIFIKASI_MINAT_AKAD', payload: false});
                Swal.fire('Berhasil', 'Berhasil Verifikasi Minat Akad', 'success');
                dispatch(setProposalProyeks(null, query.get("id")));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}


export const apiSetAkadNasabah = (iData, query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('idProyek', iData.idProyek);
        iData.fileAkad !== undefined && fd.append('fileAkad', iData.fileAkad[0]);

        fd.append('Nama', iData.atasNama);
        fd.append('JangkaWaktu', iData.jangkaWaktu);
        // fd.append('MinTanggalProposal', FUNCDateToString(iData.minTanggalProposal));
        // fd.append('MaxTanggalProposal', FUNCDateToString(iData.maxTanggalProposal));
       
        if(iData.jenisProyek == "Musyarakah"){
            // Musyarakah
            fd.append('Musyarakah.NilaiProyek', iData.Musyarakah.NilaiProyek);
            if (iData.Musyarakah?.TanggalJatuhTempoBiayaSendiri){
                fd.append('Musyarakah.TanggalJatuhTempoBiayaSendiri', FUNCDateToString(iData.Musyarakah?.TanggalJatuhTempoBiayaSendiri));
            }
            fd.append('Musyarakah.PctBiayaSendiri', iData.Musyarakah?.PctBiayaSendiri || '');
            fd.append('Musyarakah.PctProyeksiMargin', iData.Musyarakah?.PctProyeksiMargin);
            fd.append('Musyarakah.PctHasilPengelola', iData.Musyarakah?.PctHasilPengelola);
            fd.append('Musyarakah.PctHasilInvestor', iData.Musyarakah?.PctHasilInvestor);
            fd.append('Musyarakah.IdWaktuTransfer', iData.Musyarakah.IdWaktuTransfer?.value);
            fd.append('Musyarakah.JumlahTransfer', iData.Musyarakah.JumlahTransfer);
            fd.append('Musyarakah.TanggalMulaiTransfer', FUNCDateToString(iData.Musyarakah.TanggalMulaiTransfer));
            fd.append('Musyarakah.PctUjrah', iData.Musyarakah.PctUjrah);
        } else if(iData.jenisProyek == "Murabahah"){
            // Murabahah
            fd.append('Murabahah.Obyek', iData.Murabahah.Obyek);
            fd.append('Murabahah.BiayaPerolehan', iData.Murabahah.BiayaPerolehan);
            fd.append('Murabahah.Kuantiti', iData.Murabahah.Kuantiti);
            fd.append('Murabahah.Harga', iData.Murabahah.Harga);
            fd.append('Murabahah.PctDp', iData.Murabahah.PctDp);
            fd.append('Murabahah.TanggalJatuhTempoDp', FUNCDateToString(iData.Murabahah.TanggalJatuhTempoDp));
            fd.append('Murabahah.PctMargin', iData.Murabahah.PctMargin);
            fd.append('Murabahah.JumlahAngsuran', iData.Murabahah.JumlahAngsuran);
            fd.append('Murabahah.TanggalAwalAngsuran', FUNCDateToString(iData.Murabahah.TanggalAwalAngsuran));
            fd.append('Murabahah.GracePeriod', iData.Murabahah.GracePeriod);
        }

        http.post(`${URLSVC}/Proyeks/SetAkadNasabah`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_AKAD_NASABAH', payload: false});
                Swal.fire('Berhasil', `Akad Nasabah Telah Terunggah`, 'success')
                dispatch(setProposalProyeks(3, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUploadAkadNasabah = (iData, query, reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('idProyek', iData.idProyek);
        iData.fileAkad !== undefined && fd.append('fileAkadTtd', iData.fileAkad[0]);

        http.post(`${URLSVC}/Proyeks/UploadAkadNasabah`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_AKAD_NASABAH_ANGGOTA', payload: false});
                Swal.fire('Berhasil', `Akad Nasabah Tanda Tangan Telah Terunggah`, 'success')
                dispatch(setProposalProyeksAnggota(4, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiOfferingLetter = (iData, query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('idProyek', iData.idProyek);
        iData.fileOl !== undefined && fd.append('fileOl', iData.fileOl[0]);

        http.post(`${URLSVC}/Proyeks/SetOfferingLetter`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_OFFERING_LETTER', payload: false});
                Swal.fire('Berhasil', `Offering Letter Telah Terunggah`, 'success')
                dispatch(setProposalProyeks(2, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUploadOfferingLetter = (iData, query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('idProyek', iData.idProyek);
        iData.fileOlTtd !== undefined && fd.append('fileOlTtd', iData.fileOlTtd[0]);

        http.post(`${URLSVC}/Proyeks/UploadOfferingLetter`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_UPLOAD_OFFERING_LETTER', payload: false});
                Swal.fire('Berhasil', `Offering Letter Berhasil di Upload`, 'success')
                dispatch(setProposalProyeksAnggota(3, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUploadSfDp = (iData, query, reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('idProyek', iData.idProyek);
        fd.append('tanggalBayar', FUNCDateToString(iData.tanggalBayar));
        fd.append('rp', FUNCCleanNumberFormat(iData.rp));
        iData.buktiBayar !== undefined && fd.append('buktiBayar', iData.buktiBayar[0]);

        http.post(`${URLSVC}/Proyeks/UploadSfDp`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_UPLOAD_SF_DP', payload: false});
                Swal.fire('Berhasil', `Self Financing/DP Berhasil di Upload`, 'success')
                dispatch(setProposalProyeksAnggota(3, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiVerifikasiSfDp = (iData,query, reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('idProyekBiaya', iData.idProyekBiaya);
        fd.append('isApprove', iData.isApprove);
        fd.append('catatan', iData.catatan);

        http.post(`${URLSVC}/Proyeks/VerifikasiSfDp`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_VERIFIKASI_SF_DP', payload: false});
                Swal.fire('Berhasil', `Self Financing/DP Berhasil di Verifikasi`, 'success')
                dispatch(setNasabahBiayas(null, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

// export const apiSetAktif = (id, reset) => {
//     return (dispatch) => {
//         loadingBar(true);
//         var fd = new FormData();

//         fd.append('idProyek', id);
//         //iData.fileAkad != undefined && fd.append('fileAkad', iData.fileAkad[0]);

//         http.post(`${URLSVC}/Proyeks/SetAktif`, fd).then((res) => {
//             loadingBar(false);
//             let data = res.data;
//             if (data.isSuccess) {
//                 dispatch({ type: 'MODAL_SET_AKTIF', payload: false});
//                 Swal.fire('Berhasil', `Set Aktif Berhasil`, 'success');
//                 dispatch(setProposalProyeks());
//                 reset();
//             } else {
//                 Swal.fire('Gagal', `${data.returnMessage}`, 'error')
//             }
//         })
//     }
// }

export const apiSetAktif = (id, query,reset) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan aktivasi proyek ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
               loadingBar(true);
        var fd = new FormData();

        fd.append('idProyek', id);
        //iData.fileAkad != undefined && fd.append('fileAkad', iData.fileAkad[0]);

        http.post(`${URLSVC}/Proyeks/SetAktif`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_SET_AKTIF', payload: false});
                Swal.fire('Berhasil', `Set Aktif Berhasil`, 'success');
                dispatch(setProposalProyeks(4, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
      }
    });
  };
};

export const setDataProyeks = (query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Proyeks/GetProyeks?applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEKS', payload: data.data})
            } else {
                dispatch({type: 'DATA_PROYEKS', payload: []})
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setDataProposalLittleMore = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Proyeks/GetProposalLittleMore`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROPOSAL_LITTLE_MORES', payload: data.data})
            } else {
                dispatch({type: 'DATA_PROPOSAL_LITTLE_MORES', payload: []})
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setDataProyek = (id) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Proyeks/GetProyek?id=${id}`).then((res) => {
            let data =res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK', payload: data.data})
            } else {
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setDataProyeksByUser = (query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Proyeks/GetProyeksByUser?applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEKS', payload: data.data})
                dispatch({type: 'DATA_PROYEKS_BY_USER', payload: data.data})
            } else {
                dispatch({type: 'DATA_PROYEKS', payload: []})
                dispatch({type: 'DATA_PROYEKS_BY_USER', payload: []})
                if(data.returnMessage != "data tidak ada"){
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}
