import Swal from 'sweetalert2';

export const FUNCValidateUploadFileSize = (fi, maxSize = 2048, strMaxSize = '2MB') => {
    if (fi.files.length > 0) {
        for (var i = 0; i <= fi.files.length - 1; i++) {
            const fsize = fi.files.item(i).size;
            const file = Math.round((fsize / 1024));
            if (file >= maxSize) {
                Swal.fire('Fail', `Ukuran file terlalu besar, batas ukuran ${strMaxSize}`, 'error');
                fi.value = "";
                return null;
            }
        }
    }
}

export const FUNCValidateUploadFileExtension = (
    oInput,
    _validFileExtensions = [".jpg", ".jpeg", ".png"]
  ) => {
    var sFileName = oInput.value;
    if (sFileName.length > 0) {
      var blnValid = false;
      var msgExtension = "";
      for (var j = 0; j < _validFileExtensions.length; j++) {
        msgExtension += _validFileExtensions[j] + " ";
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }
  
      if (!blnValid) {
        Swal.fire(
          "Gagal",
          `Ekstensi file tidak didukung! <br /> format harus ${msgExtension}`,
          "error"
        );
        oInput.value = "";
        return false;
      }
    }
  };

export const FUNCArraySelectId = (data) => {
    var array = [];
    data.map((v, i) => {
        array.push({
            value: v.id,
            label: v.name
        });
    });
    return array;
}

export const FUNCArraySelectKode = (data) => {
    var array = [];
    data.map((v, i) => {
        array.push({
            value: v.code,
            label: v.name,
        });
    });
    return array;
}

export const FUNCArraySelectRole = (data) => {
    var array = [];
    data.map((v, i) => {
        array.push({
            value: v.Id,
            label: v.Nama,
        });
    });
    return array;
}

export const FUNCDateToString = (date) => {
    var Date = date.getDate();
    var Month = date.getMonth() + 1; 
    var Year = date.getFullYear();
    if (Date < 10) Date = "0" + Date;
    if (Month < 10) Month = "0" + Month;
    return Date + "-" + Month + "-" + Year;
}

export const FUNCMonthToString = (date) => {
    var Month = date.getMonth() + 1;
    var Year = date.getFullYear();
    if (Month < 10) Month = "0" + Month;
    return Month;
  };

export const FUNCYearToString = (date) => {
    var Year = date.getFullYear();
    return Year;
}

export const FUNCIndoDate = (date) => {
  if(date == null){
    return "";
  } else {
    var SplitTanggal = date.split("-");
    var Hari = SplitTanggal[0];
    var Bulan = SplitTanggal[1];
    var Tahun = SplitTanggal[2];

    var ArrayBulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
    if (Bulan < 10) {
        Bulan = Bulan.replace('0', '');
    }

    return Hari + " " + ArrayBulan[Bulan - 1] + " " + Tahun;
  }
}

export const FUNCSetFullName = (FirstName, MiddleName, LastName) => {
    var satu = FirstName || ' ';
    var dua = MiddleName || ' ';
    var tiga = LastName || ' ';
    return satu + dua + tiga;
}

// export const FUNCNumberFormat = (str) => {
//     while (str.search(",") >= 0) {
//       str = (str + "").replace(",", "");
//     }
//     return str;
//   };

export const FUNCAngkaToRupiah = (num = 0) => {
  var numx = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return numx;
};

export const FUNCNumberFormat = (number) => {
  if(number == null){
    return 0;
  } else {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}

export const FUNCCleanAngkaRupiah = (data) => {
  const withoutDot = data.replace(/\./g, '');
  const withoutComma = withoutDot.replace(/,/g, '.');
  const number = parseFloat(withoutComma);
  return number;
}

export const FUNCCleanNumberFormat = (data) => {
  var pattern = /[^0-9]/g; 
  var cleanedInput = data.replace(pattern, '');
  return cleanedInput;
}
  
  export const FUNCDateDmytoYmd = (date) => {
    var b = date.split(/\D/);
    return b.reverse().join("-");
  };

export const FUNCIsImage = (url) => {
  if(/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)){
    return true;
  } else {
    if(url.includes("blob")){
      var isImage = false;
      const img = new Image();
      img.src = url;
      // img.onerror = () => false;
      // img.onload = () => true;
      // console.log("img",isImage);
      // return isImage;
      return isImage;
    } else {
      return false;
    }
  }
}