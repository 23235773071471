const initialState = {
    dataKonfigurasiAplikasis: [],
    modalKonfigurasiAplikasi: false,
    formKonfigurasiAplikasi: {
        Code: '',
        Name: '',
        ValueType: '',
        ValueDate: '',
        ValueNumber: '',
        ValueString: '',
        FileUpload: '',

        DataValueTypes: '',
    },

    isTypeKonfigurasiAplikasi: '',
    dataValueType: []
}

const konfigurasiAplikasiReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_KONFIGURASI_APLIKASIS':
            return { ...state, dataKonfigurasiAplikasis: action.payload }
        case 'FORM_KONFIGURASI_APLIKASI':
            return {
                ...state,
                formKonfigurasiAplikasi: {
                    ...state.formKonfigurasiAplikasi,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_KONFIGURASI_APLIKASI':
            return { ...state, formKonfigurasiAplikasi: initialState.formKonfigurasiAplikasi }
        case 'MODAL_KONFIGURASI_APLIKASI':
            return { ...state, modalKonfigurasiAplikasi: action.payload }
        case 'IS_TYPE_KONFIGURASI_APLIKASI':
            return { ...state, isTypeKonfigurasiAplikasi: action.payload }
        case 'DATA_VALUE_TYPE':
            return { ...state, dataValueType: action.payload }
        default:
            return state
    }
}

export default konfigurasiAplikasiReducer;