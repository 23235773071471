import { http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from './globalAction';

export const setFormLogin = (formType, formValue) => {
    return {type: 'FORM_LOGIN', formType, formValue}
}

export const apiLogin = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        var tokenFcm = sessionStorage.getItem("TokenFcm");

        fd.append('usernameOrEmail', iData.UsernameOrEmail);
        fd.append('password', iData.Password);
        if(tokenFcm != null || tokenFcm != "" || tokenFcm != undefined){
          fd.append('tokenFcm', tokenFcm);
          fd.append('tipe', 1);
        }

        http.post(`${URLSVC}/Users/Login`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                localStorage.setItem('TOKEN', data.data)
                var params = new URLSearchParams(window.location.search);
                if(params.get("redirect_url") != null){
                  window.location = params.get("redirect_url");
                } else {
                  window.location = '/';
                }
                
            } else {
                Swal.fire('Login Gagal', `${data.returnMessage}`, 'error');
            }
        })
    }
}

export const apiResetPassword = (iData) => {
    return (dispatch) => {
      loadingBar(true);
      var fd = new FormData();
      fd.append("email", iData.UsernameOrEmailPass);
  
      http.post(`${URLSVC}/Users/ResetPassword`, fd).then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.isSuccess) {
          Swal.fire(
            "Berhasil",
            `Reset password berhasil dikirim, silahkan cek email anda!`,
            "success"
          );
          dispatch({ type: "MODAL_LUPA_PASSWORD", payload: false });
        } else {
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
        }
      });
    };
  };

  export const apiResetPasswordConfirm = (iData) => {
    return (dispatch) => {
      loadingBar(true);
      var fd = new FormData();
      fd.append("key", iData.key);
      fd.append("passwordNew1", iData.passwordNew1);
      fd.append("passwordNew2", iData.passwordNew2);
  
      http.post(`${URLSVC}/Users/ConfirmResetPassword`, fd).then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.isSuccess) {
          Swal.fire('Berhasil', `Password Anda berhasil di-ubah, silahkan login menggunakan akun Anda!`, 'success').then(() => window.location = '/')
        } else {
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
        }
      });
    };
  };