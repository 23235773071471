const initialState = {
    dataPegawais: [],
    dataPegawai: null,
    modalPegawai: false,
    formAddPegawai: {
      Id: "",
      userName: "",
      email: "",
      roleId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      address: "",
      phoneNumber: "",
      jenisJabatanId: "",
      jenisPegawaiId: "",
      nip: "",
      kdJenisKelamin: "",
      tempatLahir: "",
      tanggalLahir: "",
      jenisAgamaId: "",
      FileImage: "",
    },
  };
  
  const pegawaiReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_PEGAWAIS":
        return { ...state, dataPegawais: action.payload };
      case "DATA_PEGAWAI":
        return { ...state, dataPegawai: action.payload };
      case "ADD_PEGAWAI":
        return {
          ...state,
          formAddPegawai: {
            ...state.formAddPegawai,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_ADD_PEGAWAI":
        return { ...state, formAddPegawai: initialState.formAddPegawai };
      case "MODAL_PEGAWAI":
        return { ...state, modalPegawai: action.payload };
      default:
        return state;
    }
  };
  
  export default pegawaiReducer;
  