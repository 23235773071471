const initialState = {
    formLogin: {
        UsernameOrEmail: '',
        Password: '',
    },
    modalLupaPassword: false,
}

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FORM_LOGIN':
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_LOGIN':
            return { ...state, formLogin: initialState.formLogin }
        case 'MODAL_LUPA_PASSWORD':
            return { ...state, modalLupaPassword: action.payload }
        default:
            return state
    }
}

export default loginReducer;