import { http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';
import { setDataComboRole } from '../comboAction';


export const setDataRoles = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Users/GetRoles`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_ROLES', payload: data.data})
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataRole = (idRole) => {
    return (dispatch) => {
        loadingBar(true);
        dispatch({type: 'RESET_FORM_ADD_ROLE'})
        http.get(`${URLSVC}/Users/GetRole?roleId=${idRole}`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch(setDataComboRole());
                dispatch({ type: 'DATA_ROLE', payload: data.data })

                dispatch(setFormRole('Id', data.data.Id))
                dispatch(setFormRole('Name', data.data.Name))

                dispatch({type: 'MODAL_ROLE', payload: true})
                
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setFormRole = (formType, formValue) => {
    return {type: 'ADD_ROLE', formType, formValue}
}

export const apiAddRole = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('Name', iData.Name);

        http.post(`${URLSVC}/Users/AddRole`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire('Success', `You have successfully added data`, 'success');
                dispatch({ type: 'MODAL_ROLE', payload: false })
                dispatch(setDataRoles())
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUpdateRole = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('Id', iData.Id);
        fd.append('Name', iData.Name);
        
        http.post(`${URLSVC}/Users/UpdateRole`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire('Success', `You have successfully updated data role (${iData.Name})`, 'success')
                dispatch({ type: 'MODAL_ROLE', payload: false })
                dispatch(setDataRoles());
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiDeleteRole = (roleId, name) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You will delete role (${name})!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I am sure!'
        }).then((result) => {
            if (result.isConfirmed) {
                http.get(`${URLSVC}/Users/DeleteRole?roleId=${roleId}`).then((res) => {
                    let data = res.data;
                    if (data.isSuccess) {
                        Swal.fire('Success', `You have successfully deleted data role (${name})`, 'success')
                        dispatch(setDataRoles())
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
            }
        })
    }
}