import { FUNCCleanNumberFormat, FUNCDateToString, FUNCSetFullName, http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';

export const apiProyekUsulan_InputUsulanProyek = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        var iFile = 0;
        var iNonFile = 0;

        if(iData.isAnggota.value == 0){
            fd.append('TempUser.FullName',FUNCSetFullName(iData.firstName,iData.middleName,iData.lastName));
            // fd.append('TempUser.Username',iData.username);
            fd.append('TempUser.Email',iData.email);
        } else if(iData.isAnggota.value == 1){
            fd.append('IdUser',iData.IdUser.value);
        }
        fd.append('AtasNama', iData.namaPemohon);
        fd.append('Jabatan', iData.jabatan);
        fd.append('Rp', iData.rp);
        fd.append('Tujuan', iData.tujuan);
        fd.append('IdJenisProyek', iData.idJenisProyek.value);

        (iData.DataFilePersyaratans).map((item,index) => {
            if(item.IsFile == true){
                if(item.FormFile[0] != undefined){
                    fd.append(`SyaratFiles[${iFile}].Id`, item.Id);
                    fd.append(`SyaratFiles[${iFile}].Tipe`, item.Tipe.value);
                    fd.append(`SyaratFiles[${iFile}].Judul`, item.Judul);
                    fd.append(`SyaratFiles[${iFile}].Jenis`, item.Jenis);
                    fd.append(`SyaratFiles[${iFile}].FormFile`, item.FormFile[0]);
                    iFile++;
                } 
            } else {
                fd.append(`SyaratNonFiles[${iNonFile}].Jenis`, item.Jenis);
                fd.append(`SyaratNonFiles[${iNonFile}].IsChecked`, item.IsChecked);
                iNonFile++; 
            }
        })

        fd.append('HasilWawancara.Nama', iData.nama);
        fd.append('HasilWawancara.MinTanggalProposal', FUNCDateToString(iData.minTanggalProposal));
        fd.append('HasilWawancara.MaxTanggalProposal', FUNCDateToString(iData.maxTanggalProposal));
       
        if(iData.idJenisProyek.value == 1){
            // Musyarakah
            fd.append('HasilWawancara.JangkaWaktu', iData.Musyarakah.JangkaWaktu);
            fd.append('HasilWawancara.Musyarakah.NilaiProyek', FUNCCleanNumberFormat(iData.Musyarakah.NilaiProyek));
            if (iData.Musyarakah?.TanggalJatuhTempoBiayaSendiri){
                fd.append('HasilWawancara.Musyarakah.TanggalJatuhTempoBiayaSendiri', FUNCDateToString(iData.Musyarakah?.TanggalJatuhTempoBiayaSendiri));
            }
            fd.append('HasilWawancara.Musyarakah.PctBiayaSendiri', iData.Musyarakah?.PctBiayaSendiri);
            fd.append('HasilWawancara.Musyarakah.PctProyeksiMargin', iData.Musyarakah?.PctProyeksiMargin);
            fd.append('HasilWawancara.Musyarakah.PctHasilPengelola', iData.Musyarakah?.PctHasilPengelola);
            fd.append('HasilWawancara.Musyarakah.PctHasilInvestor', iData.Musyarakah?.PctHasilInvestor);
            fd.append('HasilWawancara.Musyarakah.IdWaktuTransfer', iData.Musyarakah.IdWaktuTransfer?.value);
            fd.append('HasilWawancara.Musyarakah.JumlahTransfer', iData.Musyarakah.JumlahTransfer);
            fd.append('HasilWawancara.Musyarakah.TanggalMulaiTransfer', FUNCDateToString(iData.Musyarakah.TanggalMulaiTransfer));
            fd.append('HasilWawancara.Musyarakah.PctUjrah', iData.Musyarakah.PctUjrah);
            fd.append('HasilWawancara.Musyarakah.KebutuhanModal', iData.Musyarakah.KebutuhanModal);
            fd.append('HasilWawancara.Musyarakah.RpBiayaSendiri', iData.Musyarakah.RpBiayaSendiri);
            fd.append('HasilWawancara.Musyarakah.RpProyeksiMargin', iData.Musyarakah.RpProyeksiMargin);
            fd.append('HasilWawancara.Musyarakah.RpHasilPengelola', iData.Musyarakah.RpHasilPengelola);
            fd.append('HasilWawancara.Musyarakah.RpHasilInvestor', iData.Musyarakah.RpHasilInvestor);
            fd.append('HasilWawancara.Musyarakah.RpUjrah', iData.Musyarakah.RpUjrah);
            fd.append('HasilWawancara.Musyarakah.EstimasiHasilBersihInvestor', iData.Musyarakah.EstimasiHasilBersihInvestor);
            fd.append('HasilWawancara.Musyarakah.EstimasiReturnBersihInvestor', iData.Musyarakah.EstimasiReturnBersihInvestor);
            fd.append('HasilWawancara.Musyarakah.EstimasiReturnBersihInvestorBulan', iData.Musyarakah.EstimasiReturnBersihInvestorBulan);

        } else if(iData.idJenisProyek.value == 2){
            // Murabahah
            fd.append('HasilWawancara.JangkaWaktu', iData.Murabahah.TotalJangkaWaktu);
            fd.append('HasilWawancara.Murabahah.Obyek', iData.Murabahah.Obyek);
            fd.append('HasilWawancara.Murabahah.BiayaPerolehan', iData.Murabahah.BiayaPerolehan);
            fd.append('HasilWawancara.Murabahah.Kuantiti', iData.Murabahah.Kuantiti);
            fd.append('HasilWawancara.Murabahah.Harga', iData.Murabahah.Harga);
            fd.append('HasilWawancara.Murabahah.PctDp', iData.Murabahah.PctDp);
            fd.append('HasilWawancara.Murabahah.TanggalJatuhTempoDp', FUNCDateToString(iData.Murabahah.TanggalJatuhTempoDp));
            fd.append('HasilWawancara.Murabahah.PctMargin', iData.Murabahah.PctMargin);
            fd.append('HasilWawancara.Murabahah.JumlahAngsuran', iData.Murabahah.JumlahAngsuran);
            fd.append('HasilWawancara.Murabahah.TanggalAwalAngsuran', FUNCDateToString(iData.Murabahah.TanggalAwalAngsuran));
            fd.append('HasilWawancara.Murabahah.GracePeriod', iData.Murabahah.GracePeriod);
            fd.append('HasilWawancara.Murabahah.NilaiProyek', iData.Murabahah.NilaiProyek);
            fd.append('HasilWawancara.Murabahah.HargaJual', iData.Murabahah.HargaJual);
            fd.append('HasilWawancara.Murabahah.RpDp', iData.Murabahah.RpDp);
            fd.append('HasilWawancara.Murabahah.RpMargin', iData.Murabahah.RpMargin);
            fd.append('HasilWawancara.Murabahah.RpProyeksiAngsuran', iData.Murabahah.RpProyeksiAngsuran);
            fd.append('HasilWawancara.Murabahah.EstimasiInvestor', iData.Murabahah.EstimasiInvestor);
            fd.append('HasilWawancara.Murabahah.PctEstimasiInvestor', iData.Murabahah.PctEstimasiInvestor);
            fd.append('HasilWawancara.Murabahah.PctEstimasiInvestorBulan', iData.Murabahah.PctEstimasiInvestorBulan);
        }
        
        iData.FileUsulan[0] != undefined && fd.append('FileUsulan', iData.FileUsulan[0]);
        iData.FileProfil[0] != undefined && fd.append(`FileProfil`, iData.FileProfil[0]);
        iData.FileNotaUsulan[0] != undefined && fd.append(`HasilWawancara.FileNotaUsulan`, iData.FileNotaUsulan[0]);
        iData.FileProfil[0] != undefined && fd.append(`HasilWawancara.FileProfil`, iData.FileProfil[0]);
        iData.FileExsum !== undefined && fd.append('FileExsum', iData.FileExsum[0]);

        http.post(`${URLSVC}/ProyekUsulans/InputUsulanProyek`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                reset();
                dispatch({ type: 'MODAL_PROYEK_USULAN', payload: false });
                dispatch(setProyekUsulans(null, query));
                Swal.fire('Berhasil', `Pengajuan Pembiayaan Berhasil Disimpan`, 'success');
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setProyekUsulans = (next, query) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekUsulans/GetProyekUsulans?nextStatusUsulan=${next}&applTaskId=${query}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_USULANS', payload: data.data})
            } else {
                dispatch({type: 'DATA_PROYEK_USULANS', payload: []})
                if(data.returnMessage !== 'data tidak ada') {
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setProyekUsulan = (id) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekUsulans/GetProyekUsulan?id=${id}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PROYEK_USULAN', payload: data.data})
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setRefProyekSyarats = (idJenisProyek) => {
    return (dispatch) => {
        http.get(`${URLSVC}/ProyekUsulans/GetRefProyekSyarats?idJenisProyek=${idJenisProyek}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_REF_PROYEK_SYARATS', payload: data.data})
            } else {
                dispatch({type: 'DATA_REF_PROYEK_SYARATS', payload: []})
                if(data.returnMessage != 'data tidak ada') {
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const apiAddProyekUsulan = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('atasNama', iData.atasNama);
        fd.append('jabatan', iData.jabatan);
        fd.append('rp', iData.rp);
        fd.append('tujuan', iData.tujuan);
        iData.FormFile[0] != undefined && fd.append('fileUsulan', iData.FormFile[0]);
        iData.FormProfil[0] != undefined && fd.append('fileProfil', iData.FormProfil[0]);
        
        http.post(`${URLSVC}/ProyekUsulans/AddProyekUsulan`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_PROYEK_USULAN', payload: false });
                Swal.fire('Berhasil', `Berhasil Menyimpan Usulan Proyek`, 'success');
                dispatch(setProyekUsulans(null, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiEditProyekUsulan = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('id', iData.id);
        fd.append('atasNama', iData.atasNama);
        fd.append('jabatan', iData.jabatan);
        fd.append('rp', FUNCCleanNumberFormat(iData.rp));
        fd.append('tujuan', iData.tujuan);
        iData.FormFile[0] != undefined && fd.append('fileUsulan', iData.FormFile[0]);
        iData.FormProfil[0] != undefined && fd.append('fileProfil', iData.FormProfil[0]);
        
        http.post(`${URLSVC}/ProyekUsulans/EditProyekUsulan`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_PROYEK_USULAN', payload: false });
                Swal.fire('Berhasil', `Berhasil Menyimpan Usulan Proyek`, 'success');
                dispatch(setProyekUsulans(null, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiVerifikasiUsulan = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('id', iData.id);
        fd.append('isLengkap', iData.isLengkap);
        fd.append('catatan', iData.catatan);
        fd.append('idJenisProyek', iData.idJenisProyek.value);
        
        http.post(`${URLSVC}/ProyekUsulans/VerifikasiUsulan`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_VERIFIKASI_PROYEK_USULAN', payload: false });
                Swal.fire('Berhasil', `Berhasil Verifikasi Usulan Proyek`, 'success');
                dispatch(setProyekUsulans(1, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUploadPersyaratan = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('id', iData.id);

        var iFile = 0;
        var iNonFile = 0;
        (iData.DataFilePersyaratans).map((item,index) => {
            if(item.IsFile == true){
                if(item.FormFile[0] != undefined){
                    fd.append(`syaratFiles[${iFile}].Id`, item.Id);
                    fd.append(`syaratFiles[${iFile}].Tipe`, item.Tipe.value);
                    fd.append(`syaratFiles[${iFile}].Judul`, item.Judul);
                    fd.append(`syaratFiles[${iFile}].Jenis`, item.Jenis);
                    fd.append(`syaratFiles[${iFile}].FormFile`, item.FormFile[0]);
                    iFile++;
                } 
            } else {
                fd.append(`syaratNonFiles[${iNonFile}].Jenis`, item.Jenis);
                fd.append(`syaratNonFiles[${iNonFile}].IsChecked`, item.IsChecked);
                iNonFile++; 
            }
        })
        
        http.post(`${URLSVC}/ProyekUsulans/UploadPersyaratan`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_UPLOAD_PERSYARATAN_USULAN', payload: false });
                Swal.fire('Berhasil', `Upload Persyaratan Berhasil`, 'success');
                reset();
                dispatch(setProyekUsulans(2, query));
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiVerifikasiPersyaratan = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('id', iData.id);

        var iFile = 0;
        var iNonFile = 0;
        (iData.DataFilePersyaratans).map((item,index) => {
            if(item.isFile == true){
                fd.append(`syaratFiles[${iFile}].IdJenisSyarat`, item.id);
                fd.append(`syaratFiles[${iFile}].Catatan`, item.catatan);
                fd.append(`syaratFiles[${iFile}].IsValid`, item.isValid);
                iFile++;
            } else {
                fd.append(`syaratNonFiles[${iNonFile}].IdJenisSyarat`, item.id);
                fd.append(`syaratNonFiles[${iNonFile}].Catatan`, item.catatan);
                fd.append(`syaratNonFiles[${iNonFile}].IsValid`, item.isValid);
                iNonFile++; 
            }
        })
        
        http.post(`${URLSVC}/ProyekUsulans/VerifikasiPersyaratan`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_VERIFIKASI_PROYEK_USULAN', payload: false });
                Swal.fire('Berhasil', `Persyaratan Berhasil di Verifikasi`, 'success');
                dispatch(setProyekUsulans(3, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekUsulan_HitungMusyarakah = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('NilaiProyek', iData.Musyarakah.NilaiProyek);
        fd.append('JangkaWaktu', iData.Musyarakah.JangkaWaktu);
        iData.Musyarakah.TanggalJatuhTempoBiayaSendiri && fd.append('TanggalJatuhTempoBiayaSendiri', FUNCDateToString(iData.Musyarakah.TanggalJatuhTempoBiayaSendiri));
        fd.append('PctBiayaSendiri', iData.Musyarakah.PctBiayaSendiri);
        fd.append('PctProyeksiMargin', iData.Musyarakah.PctProyeksiMargin);
        fd.append('PctHasilPengelola', iData.Musyarakah.PctHasilPengelola);
        fd.append('PctHasilInvestor', iData.Musyarakah.PctHasilInvestor);
        fd.append('IdWaktuTransfer', iData.Musyarakah.IdWaktuTransfer?.value);
        fd.append('JumlahTransfer', iData.Musyarakah.JumlahTransfer);
        iData.Musyarakah.TanggalMulaiTransfer && fd.append('TanggalMulaiTransfer', FUNCDateToString(iData.Musyarakah.TanggalMulaiTransfer));
        fd.append('PctUjrah', iData.Musyarakah.PctUjrah);
        
        http.post(`${URLSVC}/ProyekUsulans/HitungMusyarakah`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_HITUNG_MUSYARAKAH', payload: data.data})
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekUsulan_HitungMurabahah = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        
        // fd.append('JangkaWaktu', iData.Murabahah.JangkaWaktu);
        fd.append('Obyek', iData.Murabahah.Obyek);
        fd.append('BiayaPerolehan', iData.Murabahah.BiayaPerolehan);
        fd.append('Kuantiti', iData.Murabahah.Kuantiti);
        fd.append('Harga', iData.Murabahah.Harga);
        fd.append('PctDp', iData.Murabahah.PctDp);
        fd.append('TanggalJatuhTempoDp', FUNCDateToString(iData.Murabahah.TanggalJatuhTempoDp));
        fd.append('PctMargin', iData.Murabahah.PctMargin);
        fd.append('JumlahAngsuran', iData.Murabahah.JumlahAngsuran);
        fd.append('TanggalAwalAngsuran', FUNCDateToString(iData.Murabahah.TanggalAwalAngsuran));
        fd.append('GracePeriod', iData.Murabahah.GracePeriod);
        
        http.post(`${URLSVC}/ProyekUsulans/HitungMurabahah`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_HITUNG_MURABAHAH', payload: data.data})
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekUsulan_InputHasilWawancara = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('id', iData.id);
        fd.append('isSepakat', iData.isSepakat);
        fd.append('catatan', iData.catatan);

        fd.append('Nama', iData.nama);
        fd.append('MinTanggalProposal', FUNCDateToString(iData.minTanggalProposal));
        fd.append('MaxTanggalProposal', FUNCDateToString(iData.maxTanggalProposal));
       
        if(iData.idJenisProyek == 1){
            // Musyarakah
            fd.append('JangkaWaktu', iData.Musyarakah.JangkaWaktu);
            fd.append('Musyarakah.NilaiProyek', FUNCCleanNumberFormat(iData.Musyarakah.NilaiProyek));
            if (iData.Musyarakah?.TanggalJatuhTempoBiayaSendiri){
                fd.append('Musyarakah.TanggalJatuhTempoBiayaSendiri', FUNCDateToString(iData.Musyarakah?.TanggalJatuhTempoBiayaSendiri));
            }
            fd.append('Musyarakah.PctBiayaSendiri', iData.Musyarakah?.PctBiayaSendiri);
            fd.append('Musyarakah.PctProyeksiMargin', iData.Musyarakah?.PctProyeksiMargin);
            fd.append('Musyarakah.PctHasilPengelola', iData.Musyarakah?.PctHasilPengelola);
            fd.append('Musyarakah.PctHasilInvestor', iData.Musyarakah?.PctHasilInvestor);
            fd.append('Musyarakah.IdWaktuTransfer', iData.Musyarakah.IdWaktuTransfer?.value);
            fd.append('Musyarakah.JumlahTransfer', iData.Musyarakah.JumlahTransfer);
            fd.append('Musyarakah.TanggalMulaiTransfer', FUNCDateToString(iData.Musyarakah.TanggalMulaiTransfer));
            fd.append('Musyarakah.PctUjrah', iData.Musyarakah.PctUjrah);
            //tanpa kalkulator
            fd.append('Musyarakah.KebutuhanModal', iData.Musyarakah.KebutuhanModal);
            fd.append('Musyarakah.RpBiayaSendiri', iData.Musyarakah.RpBiayaSendiri);
            fd.append('Musyarakah.RpProyeksiMargin', iData.Musyarakah.RpProyeksiMargin);
            fd.append('Musyarakah.RpHasilPengelola', iData.Musyarakah.RpHasilPengelola);
            fd.append('Musyarakah.RpHasilInvestor', iData.Musyarakah.RpHasilInvestor);
            fd.append('Musyarakah.RpUjrah', iData.Musyarakah.RpUjrah);
            fd.append('Musyarakah.EstimasiHasilBersihInvestor', iData.Musyarakah.EstimasiHasilBersihInvestor);
            fd.append('Musyarakah.EstimasiReturnBersihInvestor', iData.Musyarakah.EstimasiReturnBersihInvestor);
            fd.append('Musyarakah.EstimasiReturnBersihInvestorBulan', iData.Musyarakah.EstimasiReturnBersihInvestorBulan);

        } else if(iData.idJenisProyek == 2){
            // Murabahah
            fd.append('JangkaWaktu', iData.Murabahah.TotalJangkaWaktu);
            fd.append('Murabahah.Obyek', iData.Murabahah.Obyek);
            fd.append('Murabahah.BiayaPerolehan', iData.Murabahah.BiayaPerolehan);
            fd.append('Murabahah.Kuantiti', iData.Murabahah.Kuantiti);
            fd.append('Murabahah.Harga', iData.Murabahah.Harga);
            fd.append('Murabahah.PctDp', iData.Murabahah.PctDp);
            fd.append('Murabahah.TanggalJatuhTempoDp', FUNCDateToString(iData.Murabahah.TanggalJatuhTempoDp));
            fd.append('Murabahah.PctMargin', iData.Murabahah.PctMargin);
            fd.append('Murabahah.JumlahAngsuran', iData.Murabahah.JumlahAngsuran);
            fd.append('Murabahah.TanggalAwalAngsuran', FUNCDateToString(iData.Murabahah.TanggalAwalAngsuran));
            fd.append('Murabahah.GracePeriod', iData.Murabahah.GracePeriod);
            //tanpa kalkulator
            fd.append('Murabahah.NilaiProyek', iData.Murabahah.NilaiProyek);
            fd.append('Murabahah.HargaJual', iData.Murabahah.HargaJual);
            fd.append('Murabahah.RpDp', iData.Murabahah.RpDp);
            fd.append('Murabahah.RpMargin', iData.Murabahah.RpMargin);
            fd.append('Murabahah.RpProyeksiAngsuran', iData.Murabahah.RpProyeksiAngsuran);
            fd.append('Murabahah.EstimasiInvestor', iData.Murabahah.EstimasiInvestor);
            fd.append('Murabahah.PctEstimasiInvestor', iData.Murabahah.PctEstimasiInvestor);
            fd.append('Murabahah.PctEstimasiInvestorBulan', iData.Murabahah.PctEstimasiInvestorBulan);
        }
        
        iData.FileNotaUsulan[0] != undefined && fd.append(`FileNotaUsulan`, iData.FileNotaUsulan[0]);
        iData.FileProfil[0] != undefined && fd.append(`FileProfil`, iData.FileProfil[0]);
        
        http.post(`${URLSVC}/ProyekUsulans/InputHasilWawancara`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch(setProyekUsulans(4, query));
                dispatch({ type: 'MODAL_INPUT_HASIL_WAWANCARA', payload: false });
                Swal.fire('Berhasil', `Input Hasil Wawancara Berhasil Disimpan`, 'success');
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekUsulan_ReviewKomite = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('id', iData.id);
        fd.append('isApprove', iData.isApprove);
        fd.append('catatan', iData.catatan);
        
        http.post(`${URLSVC}/ProyekUsulans/ReviewKomite`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_REVIEW_KOMITE', payload: false });
                Swal.fire('Berhasil', `Review Komite Berhasil Disimpan`, 'success');
                dispatch(setProyekUsulans(5, query));
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiProyekUsulan_SetFundrising = (iData,query) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan mengatur penggalangan dana!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                loadingBar(true);
                var fd = new FormData();
                fd.append('id', iData.id);
                iData.FileExsum !== undefined && fd.append('FileExsum', iData.FileExsum[0]);

                http.post(`${URLSVC}/ProyekUsulans/SetFundrising`,fd).then((res) => {
                    loadingBar(false);
                    let data = res.data;
                    if (data.isSuccess) {
                        dispatch(setProyekUsulans(6, query));
                        Swal.fire('Berhasil', `Penggalangan dana berhasil diatur`, 'success');
                        dispatch({ type: 'MODAL_FILE_EXSUM', payload: false });
                    } else {
                        Swal.fire('Gagal', `${data.returnMessage}`, 'error');
                    }
                })
            }
        })
    }
}