const initialState = {
    dataProyekUsulans: [],
    dataProyekUsulan: null,
    dataRefProyekSyarats: null,
    dataHitungMusyarakah: null,
    dataHitungMurabahah: null,
    modalViewProyekUsulan: false,
    modalProyekUsulan: false,
    modalVerifikasiProyekUsulan: false,
    modalUploadPersyaratanUsulan: false,
    modalInputHasilWawancara: false,
    modalReviewKomite: false,
    modalFileExsum: false,
  };
  
  const proyekUsulanReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_PROYEK_USULANS":
        return { ...state, dataProyekUsulans: action.payload };
      case "DATA_PROYEK_USULAN":
        return { ...state, dataProyekUsulan: action.payload };
      case "DATA_REF_PROYEK_SYARATS":
        return { ...state, dataRefProyekSyarats: action.payload };
      case "DATA_HITUNG_MUSYARAKAH":
        return { ...state, dataHitungMusyarakah: action.payload };
      case "DATA_HITUNG_MURABAHAH":
        return { ...state, dataHitungMurabahah: action.payload };
      case "RESET_FORM_ADD_PROYEK_USULAN":
        return { ...state, formAddProyekUsulan: initialState.formAddProyekUsulan };
      case "MODAL_VIEW_PROYEK_USULAN":
        return { ...state, modalViewProyekUsulan: action.payload };
      case "MODAL_PROYEK_USULAN":
        return { ...state, modalProyekUsulan: action.payload };
      case "MODAL_VERIFIKASI_PROYEK_USULAN":
        return { ...state, modalVerifikasiProyekUsulan: action.payload };
      case "MODAL_UPLOAD_PERSYARATAN_USULAN":
        return { ...state, modalUploadPersyaratanUsulan: action.payload };
      case "MODAL_INPUT_HASIL_WAWANCARA":
        return { ...state, modalInputHasilWawancara: action.payload };
      case "MODAL_REVIEW_KOMITE":
        return { ...state, modalReviewKomite: action.payload };
      case "MODAL_FILE_EXSUM":
        return { ...state, modalFileExsum: action.payload };
      default:
        return state;
    }
  };
  
  export default proyekUsulanReducer;