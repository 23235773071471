const initialState = {
    dataSyaratRegistrasis: [],
    dataJenisSimpanans: [],
    dataNewAnggotas: [],
    dataNewAnggota: null,
    dataBiayaKeanggotaan: null,
    modalViewRegistrasi: false,
    modalRegistrasi: false,
    modalVerifikasiBerkas: false,
    modalVerifikasiForm: false,
    formAddRegistrasi: {
      Id: "",
      Registrasiname: "",
      Password: "",
      Email: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Address: "",
      PhoneNumber: "",
      MobileNumber: "",
      Role: "",
    },
  };
  
  const registrasiReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_PERSYARATANS":
        return { ...state, dataSyaratRegistrasis: action.payload };
      case "DATA_JENIS_SIMPANANS":
        return { ...state, dataJenisSimpanans: action.payload };
      case "DATA_NEW_ANGGOTAS":
        return { ...state, dataNewAnggotas: action.payload };
      case "DATA_NEW_ANGGOTA":
        return { ...state, dataNewAnggota: action.payload };
      case "DATA_BIAYA_KEANGGOTAAN":
        return { ...state, dataBiayaKeanggotaan: action.payload };
      case "ADD_REGISTRASI":
        return {
          ...state,
          formAddRegistrasi: {
            ...state.formAddRegistrasi,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_ADD_REGISTRASI":
        return { ...state, formAddRegistrasi: initialState.formAddRegistrasi };
      case "MODAL_VIEW_REGISTRASI":
        return { ...state, modalViewRegistrasi: action.payload };
      case "MODAL_REGISTRASI":
        return { ...state, modalRegistrasi: action.payload };
      case "MODAL_VERIFIKASI_BERKAS":
        return { ...state, modalVerifikasiBerkas: action.payload };
      case "MODAL_UPLOAD_FORM_REGISTRASI":
        return { ...state, modalVerifikasiForm: action.payload};
      default:
        return state;
    }
  };
  
  export default registrasiReducer;
  