import { FUNCDateToString, http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';

export const setDataJenisSimpanans = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Registrasis/GetJenisSimpanans`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_JENIS_SIMPANANS', payload: data.data})
            } else {
                dispatch({type: 'DATA_JENIS_SIMPANANS', payload: []})
                if(data.returnMessage != 'data tidak ada') {
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setDataNewAnggotas = (nextStatus, id) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Registrasis/GetNewAnggotas?nextStatusMember=${nextStatus}&applTaskId=${id}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_NEW_ANGGOTAS', payload: data.data})
            } else {
                dispatch({type: 'DATA_NEW_ANGGOTAS', payload: []})
                if(data.returnMessage != 'data tidak ada') {
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setDataNewAnggota = (id) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Registrasis/GetNewAnggota?id=${id}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_NEW_ANGGOTA', payload: data.data})
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setDataNewAnggotaViaEmail = (id) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Registrasis/GetNewAnggotaViaEmail?id=${id}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_NEW_ANGGOTA', payload: data.data})
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setBiayaKeanggotaans = (id) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Registrasis/GetBiayaKeanggotaans?id=${id}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_BIAYA_KEANGGOTAAN', payload: data.data})
            } else {
                dispatch({type: 'DATA_BIAYA_KEANGGOTAAN', payload: []})
                if(data.returnMessage != 'data tidak ada') {
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setSyaratRegistrasis = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Registrasis/GetSyarats`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_PERSYARATANS', payload: data.data})
            } else {
                dispatch({type: 'DATA_PERSYARATANS', payload: []})
                if(data.returnMessage != 'data tidak ada') {
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const apiAddRegistrasi = (iData,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('email', iData.email);
        fd.append('firstName', iData.firstName);
        fd.append('middleName', iData.middleName);
        fd.append('lastName', iData.lastName);
        fd.append('address', iData.address);
        fd.append('phoneNumber', iData.phoneNumber);
        fd.append('mobileNumber', iData.mobileNumber);
        fd.append('idJenisIdentitas', iData.idJenisIdentitas.value);
        fd.append('nomorIdentitas', iData.nomorIdentitas);

        (iData.DataFilePersyaratans).filter((item) => item.FormFile[0] != undefined || item.FormFile != "").map((item,index) => {
            fd.append(`Files[${index}].Tipe`, 1);
            fd.append(`Files[${index}].Judul`, item.Judul);
            fd.append(`Files[${index}].Jenis`, item.Jenis);
            fd.append(`Files[${index}].FormFile`, item.FormFile[0]);
        })
        
        http.post(`${URLSVC}/Registrasis/Add`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire('Berhasil', `Pendaftaran Pengguna Berhasil, ${data.data}`, 'success');
                reset();
                dispatch(setSyaratRegistrasis());
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUpdateRegistrasi = (iData, query, reset,viaEmail=false) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('id', iData.id);
        fd.append('email', iData.email);
        fd.append('firstName', iData.firstName);
        iData.middleName && fd.append('middleName', iData.middleName);
        iData.lastName && fd.append('lastName', iData.lastName);
        fd.append('address', iData.address);
        fd.append('phoneNumber', iData.phoneNumber);
        fd.append('mobileNumber', iData.mobileNumber);
        fd.append('idJenisIdentitas', iData.idJenisIdentitas.value);
        fd.append('nomorIdentitas', iData.nomorIdentitas);
        
        (iData.DataFilePersyaratans).filter((item) => item.FormFile[0] != undefined || item.FormFile != "").map((item,index) => {
            fd.append(`Files[${index}].Id`, item.IdUpdate);
            fd.append(`Files[${index}].Tipe`, 1);
            fd.append(`Files[${index}].Judul`, item.Judul);
            fd.append(`Files[${index}].Jenis`, item.Jenis);
            fd.append(`Files[${index}].FormFile`, item.FormFile[0]);
        })
        
        var Url = "";
        if(viaEmail){
            Url = `${URLSVC}/Registrasis/EditViaEmail`;
        } else {
            Url = `${URLSVC}/Registrasis/Edit`;
        }
        http.post(Url,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_REGISTRASI', payload: false });
                if(viaEmail === false){
                    dispatch(setDataNewAnggotas(null,query));                  
                } else {
                    dispatch(setDataNewAnggotaViaEmail(iData.id));
                    dispatch(setSyaratRegistrasis());
                }
                reset();
                Swal.fire('Berhasil', `Edit Pendaftaran Berhasil`, 'success');
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiVerifikasiBerkas = (iData, query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('id', iData.id);
        fd.append('isLengkap', iData.isLengkap);
        fd.append('catatan', iData.catatan);
        
        http.post(`${URLSVC}/Registrasis/VerifikasiBerkas`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_VERIFIKASI_BERKAS', payload: false });
                Swal.fire('Berhasil', `Verifikasi Berkas Berhasil, ${data.returnMessage}`, 'success');
                reset();
                dispatch(setDataNewAnggotas(1,query.get("id")));
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiInputHasilWawancara = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('id', iData.id);
        fd.append('rpPokok', iData.rpPokok);
        fd.append('periodeBayarPokok', iData.periodeBayarPokok);
        fd.append('rpWajib', iData.rpWajib);
        fd.append('jatuhTempoWajib', iData.jatuhTempoWajib);
        iData.periodeBayarWajib && fd.append('periodeBayarWajib', iData.periodeBayarWajib.value);
        fd.append('catatan', iData.catatan);

        iData.IdJenisKelamin && fd.append('IdJenisKelamin', iData.IdJenisKelamin?.value);
        fd.append('TempatLahir', iData.TempatLahir);
        fd.append('TanggalLahir', FUNCDateToString(iData.TanggalLahir));
        fd.append('Alamat', iData.Alamat);
        iData.IdKabkot && fd.append('IdKabkot', iData.IdKabkot.value);
        fd.append('KodePos', iData.KodePos);
        fd.append('NamaIbuKandung', iData.NamaIbuKandung);
        iData.IdPendidikan && fd.append('IdPendidikan', iData.IdPendidikan.value);
        iData.IdAgama && fd.append('IdAgama', iData.IdAgama.value);
        iData.IdPekerjaan && fd.append('IdPekerjaan', iData.IdPekerjaan.value);
        fd.append('NamaPekerjaan', iData.NamaPekerjaan);
        fd.append('NamaPerusahaan', iData.NamaPerusahaan);
        fd.append('NamaAhliWaris', iData.NamaAhliWaris);
        fd.append('HubunganAhliWaris', iData.HubunganAhliWaris);
        fd.append('AlamatAhliWaris', iData.AlamatAhliWaris);
        fd.append('NoHandphoneAhliWaris', iData.NoHandphoneAhliWaris);
        iData.IdWargaNegara && fd.append('IdWargaNegara', iData.IdWargaNegara.value);

        fd.append('IsKoperasiLain', iData.IsKoperasiLain);
        fd.append('NamaKoperasiLain', iData.NamaKoperasiLain);
        fd.append('NamaBank', iData.NamaBank);
        fd.append('NoRekening', iData.NoRekening);
        fd.append('NamaPemilikRekening', iData.NamaPemilikRekening);
        
        http.post(`${URLSVC}/Registrasis/InputHasilWawancara`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_REGISTRASI', payload: false });
                Swal.fire('Berhasil', `Hasil Wawancara Berhasil Disimpan ${data.returnMessage}`, 'success');
                reset();
                dispatch(setDataNewAnggotas(2, query.get("id")));
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUploadBiayaKeanggotaan = (iData,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('id', iData.id);
        fd.append('email', iData.email);
        fd.append('refNo', iData.refNo);
        fd.append('tanggalBayar', FUNCDateToString(iData.tanggalBayar));
        fd.append('rp', iData.rp);
        iData.buktiBayar[0] != undefined && fd.append('buktiBayar', iData.buktiBayar[0]);
        
        http.post(`${URLSVC}/Registrasis/UploadBiayaKeanggotaan`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_REGISTRASI', payload: false });
                Swal.fire('Berhasil', `Bukti Bayar Biaya Keanggotaan Berhasil di Upload ${data.returnMessage}`, 'success');
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiUploadFormRegistrasi = (iData,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('email', iData.email);
        iData.formRegistrasiTtd[0] != undefined && fd.append('formRegistrasiTtd', iData.formRegistrasiTtd[0]);
        
        http.post(`${URLSVC}/Registrasis/UploadFormRegistrasi`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_UPLOAD_FORM_REGISTRASI', payload: false });
                Swal.fire('Berhasil', `Form Registrasi Berhasil di Upload ${data.returnMessage}`, 'success');
                reset();
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}



export const apiVerifikasiBiayaKeanggotaan = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('id', iData.id);
        fd.append('idSimpananDet', iData.idSimpananDet);
        fd.append('isLengkap', iData.isLengkap);
        fd.append('catatan', iData.catatan);
        
        http.post(`${URLSVC}/Registrasis/VerifikasiBiayaKeanggotaan`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_REGISTRASI', payload: false });
                Swal.fire('Berhasil', `Biaya Keanggotaan Berhasil di Verifikasi ${data.returnMessage}`, 'success');
                reset();
                dispatch(setDataNewAnggotas(4, query.get("id")));
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiSetKeanggotaan = (iData, query, reset) => {
    return (dispatch) => {
        if(iData.isLengkap === "false")
        {
            Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan menolak calon anggota atas nama ${iData.firstName} dengan nomor identitas ${iData.nomorIdentitas} menjadi anggota!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
            }).then((result) => {
                if (result.isConfirmed) {
                    loadingBar(true);
                    var fd = new FormData();
                    fd.append('id', iData.id);
                    fd.append('isLengkap', iData.isLengkap);
                    fd.append('catatan', iData.catatan);
                    
                    http.post(`${URLSVC}/Registrasis/SetKeanggotaan`,fd).then((res) => {
                        loadingBar(false);
                        let data = res.data;
                        if (data.isSuccess) {
                            dispatch(setDataNewAnggotas(6, query.get("id")));
                            dispatch({ type: 'MODAL_UPLOAD_FORM_REGISTRASI', payload: false })
                            Swal.fire('Berhasil', `Calon anggota atas nama ${iData.firstName} dengan nomor identitas ${iData.nomorIdentitas} berhasil ditolak`, 'success');
                        } else {
                            Swal.fire('Gagal', `${data.returnMessage}`, 'error');
                        }
                    })
                }
            })
        } else {
            Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan menjadikan calon anggota atas nama ${iData.firstName} dengan nomor identitas ${iData.nomorIdentitas} menjadi anggota!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        loadingBar(true);
                        var fd = new FormData();
                        fd.append('id', iData.id);
                        fd.append('isLengkap', iData.isLengkap);
                        fd.append('catatan', iData.catatan);
                        
                        http.post(`${URLSVC}/Registrasis/SetKeanggotaan`,fd).then((res) => {
                            loadingBar(false);
                            let data = res.data;
                            if (data.isSuccess) {
                                dispatch(setDataNewAnggotas(6, query.get("id")));
                                dispatch({ type: 'MODAL_UPLOAD_FORM_REGISTRASI', payload: false })
                                Swal.fire('Berhasil', `Calon anggota atas nama ${iData.firstName} dengan nomor identitas ${iData.nomorIdentitas} berhasil dijadikan anggota`, 'success');
                            } else {
                                Swal.fire('Gagal', `${data.returnMessage}`, 'error');
                            }
                        })
                    }
                })
        }

        
    }
}

export const apiDeleteRegistrasi = (param, query) => {
        return (dispatch) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan menghapus data registrasi dengan nama: ${param.fullName}!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                loadingBar(true);
                http.post(`${URLSVC}/Registrasis/DeleteRgistrasi?id=${param.id}`).then((res) => {
                    loadingBar(false);
                    let data = res.data;
                    if (data.isSuccess) {
                        dispatch(setDataNewAnggotas(null,query.get("id")));
                        Swal.fire('Berhasil', `Penghapusan data Registrasi dengan nama: ${param.fullName} berhasil dihapus`, 'success');
                    } else {
                        Swal.fire('Gagal', `${data.returnMessage}`, 'error');
                    }
                })
            }
        })
    }
}
