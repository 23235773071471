import { FUNCAngkaToRupiah, FUNCCleanAngkaRupiah, FUNCCleanNumberFormat, FUNCDateToString, FUNCNumberFormat, http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';

export const setSimpanans = (id) => {
    return (dispatch) => {
        loadingBar(true);
        http.get(`${URLSVC}/Simpanans/GetSimpanans?applTaskId=${id}`).then((res) => {
            let data = res.data;
            loadingBar(false);
            if (data.isSuccess) {
                dispatch({type: 'DATA_SIMPANANS', payload: data.data})
            } else {
                dispatch({type: 'DATA_SIMPANANS', payload: []})
                if(data.returnMessage != 'data tidak ada') {
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const setSimpanansByUser = (idUser) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Simpanans/GetSimpanansByUser?idUser=${idUser}`).then((res) => {
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_SIMPANAN', payload: data.data})
            } else {
                dispatch({type: 'DATA_SIMPANAN', payload: []})
                if(data.returnMessage != 'data tidak ada') {
                    Swal.fire('Gagal', `${data.returnMessage}`, 'error')
                }
            }
        })
    }
}

export const apiGenerateSimpananWajib = (tahun, query) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: `Anda akan melakukan Generate Simpanan Wajib tahun ${tahun}!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Saya Yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                loadingBar(true);

                var fd = new FormData();
                // fd.append('bulan', bulan);
                fd.append('tahun', tahun);

                http.post(`${URLSVC}/Simpanans/GenerateSimpananWajib?tahun=${tahun}`,fd).then((res) => {
                    loadingBar(false);
                    let data = res.data;
                    if (data.isSuccess) {
                        Swal.fire('Success', `Anda berhasil Generate Simpanan Wajib tahun ${tahun}`, 'success')
                        dispatch(setSimpanans(query.get("id")))
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
            }
        })
    }
}

export const apiUploadSimpanan = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        var index = 0;
        iData.data.map((i) => {
            fd.append(`data[${index}].idSimpananDet`, i.idSimpananDet);
            fd.append(`data[${index}].rp`, FUNCCleanAngkaRupiah(i.rp));
            fd.append(`data[${index}].tanggalBayar`, FUNCDateToString(i.tanggalBayar));
            index++;
        })
        fd.append('rpBayar', iData.rpBayar);
        iData.buktiBayar !== undefined && fd.append('buktiBayar', iData.buktiBayar);

        http.post(`${URLSVC}/Simpanans/UploadSimpanans`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_UPLOAD_SIMPANAN', payload: false });
                Swal.fire('Berhasil', `Bukti Bayar Simpanan Berhasil di Upload ${data.returnMessage}`, 'success');
                reset();
                dispatch(setSimpanans(query.get("id")));
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiVerifikasiSimpanan = (iData,query,reset) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idSimpananDet', iData.idSimpananDet);
        fd.append('isLengkap', iData.isLengkap);
        fd.append('catatan', iData.catatan);
        
        http.post(`${URLSVC}/Simpanans/VerifikasiUploadSimpanan`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'MODAL_VERIFIKASI_SIMPANAN', payload: false });
                Swal.fire('Berhasil', `Bukti Bayar Simpanan Berhasil di Verifikasi ${data.returnMessage}`, 'success');
                reset();
                dispatch(setSimpanans(query.get("id")));
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiDeleteSimpananDet = (iData, query) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: `Anda akan menghapus simpanan dengan no referensi ${iData.refNo}! dan status pembayaran [${iData.status.status}] dengan status proses [${iData.status.statusProses}]`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Saya Yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                var fd = new FormData();
                fd.append('idSimpananDet', iData.id);
                http.post(`${URLSVC}/Simpanans/DeleteSimpananDet`,fd).then((res) => {
                    let data = res.data;
                    if (data.isSuccess) {
                        Swal.fire('Success', `Anda berhasil menghapus simpanan dengan no referensi ${iData.refNo}`, 'success')
                        dispatch({ type: 'MODAL_VERIFIKASI_SIMPANAN', payload: false });
                        dispatch(setSimpanans(query.get("id")))
                    } else {
                        Swal.fire('Fail', `${data.returnMessage}`, 'error')
                    }
                })
            }
        })
    }
}

export const apiEditSimpanan = (iData, query) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();
        fd.append('idUser', iData.idUser);
        iData.periodeWajib && fd.append('periodeWajib', iData.periodeWajib.value);
        if(iData.tglJatuhTempoWajib){
            var tglWajib = parseInt(iData.tglJatuhTempoWajib);
            if(!isNaN(tglWajib) && tglWajib >= 1 && tglWajib <= 28){
                fd.append('tglJatuhTempoWajib', tglWajib);
            } else {
                fd.append('tglJatuhTempoWajib', 1);
            }
        }
        iData.fileNota[0] !== undefined && fd.append('fileNota', iData.fileNota[0]);
        iData.catatan && fd.append('catatan', iData.catatan);


        http.post(`${URLSVC}/Simpanans/EditSimpanan`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire(
                  "Berhasil",
                  `Anda berhasil melakukan update simpanan`,
                  "success"
                ).then(() => {
                  dispatch({ type: "MODAL_SIMPANAN", payload: false });
                  dispatch(setSimpanans(query.get("id")));
                });
                
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const apiEditSaldo = (iData, query) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        console.log("apiSaldo", iData)
        fd.append('idUser', iData.idUser);
        iData.catatan && fd.append('catatan', iData.catatan);
        fd.append('rpTotalPokok', FUNCCleanAngkaRupiah(iData.rpTotalPokok));
        fd.append('rpTotalWajib', FUNCCleanAngkaRupiah(iData.rpTotalWajib));
        iData.fileNota[0] !== undefined && fd.append('fileNota', iData.fileNota[0]);

        http.post(`${URLSVC}/Simpanans/EditSaldo`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire(
                  "Berhasil",
                  `Anda berhasil melakukan update saldo`,
                  "success"
                ).then(() => {
                  dispatch({ type: "MODAL_SIMPANAN_SALDO", payload: false });
                  dispatch(setSimpanans(query.get("id")));
                });
                
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}
