const initialState = {
    dataProposalProyeks: [],
    dataProposalProyek: [],
    modalSetMinat: false,
    dataProyekInvestors: [],
    dataProyekInvestor: [],
    modalEditMinat: false,
    modalVerifikasiMinatAkad: false,
    modalAkadNasabah: false,
    modalAkadNasabahAnggota: false,
    modalSetAktif: false,
    modalOfferingLetter: false,
    modalUploadSfDp: false,
    modalUploadOfferingLetter: false,
    modalVerifikasiSfDp: false,
    modalUploadBuktiSetorAkad: false,
    modalAkadNasabahAnggota: false,
    modalViewProyek: false,
    modalViewInvestor: false,
    dataProyeks: [],
    dataProposalProyekLittleMores: [],
    dataProyek: null,
    modalUploadBiayaAkad: false,
    dataProyeksByUser: [],
};

const proyekReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DATA_PROPOSAL_PROYEKS":
            return { ...state, dataProposalProyeks: action.payload};
        case "DATA_PROPOSAL_PROYEK":
            return { ...state, dataProposalProyek: action.payload};
        case "MODAL_SET_MINAT":
            return { ...state, modalSetMinat: action.payload};
        case "DATA_PROYEK_INVESTORS":
            return { ...state, dataProyekInvestors: action.payload};
        case "DATA_PROYEK_INVESTOR":
            return { ...state, dataProyekInvestor: action.payload};
        case "MODAL_EDIT_MINAT":
            return { ...state, modalEditMinat: action.payload};
        case "MODAL_VERIFIKASI_MINAT_AKAD":
            return { ...state, modalVerifikasiMinatAkad: action.payload};
        case "MODAL_AKAD_NASABAH":
            return { ...state, modalAkadNasabah: action.payload};
        case "MODAL_AKAD_NASABAH_ANGGOTA":
            return { ...state, modalAkadNasabahAnggota: action.payload};
        case "MODAL_SET_AKTIF": 
            return { ...state, modalSetAktif: action.payload};
        case "MODAL_OFFERING_LETTER":
            return { ...state, modalOfferingLetter: action.payload};
        case "MODAL_UPLOAD_SF_DP":
            return { ...state, modalUploadSfDp: action.payload};
        case "MODAL_UPLOAD_OFFERING_LETTER":
            return { ...state, modalUploadOfferingLetter: action.payload};
        case "MODAL_VERIFIKASI_SF_DP":
            return { ...state, modalVerifikasiSfDp: action.payload};
        case "MODAL_UPLOAD_BUKTI_SETOR_AKAD":
            return { ...state, modalUploadBuktiSetorAkad: action.payload};
        case "MODAL_AKAD_NASABAH_ANGGOTA":
            return { ...state, modalAkadNasabahAnggota: action.payload};
        case "MODAL_VIEW_PROYEK": 
            return { ...state, modalViewProyek: action.payload};
        case "DATA_PROYEKS":
            return { ...state, dataProyeks: action.payload};
        case "DATA_PROPOSAL_LITTLE_MORES":
            return { ...state, dataProposalProyekLittleMores: action.payload};
        case "DATA_PROYEK":
            return { ...state, dataProyek: action.payload};
        case "MODAL_VIEW_INVESTOR": 
            return { ...state, modalViewInvestor: action.payload};
        case "MODAL_UPLOAD_BIAYA_AKAD":
            return { ...state, modalUploadBiayaAkad: action.payload};
        case "DATA_PROYEKS_BY_USER":
            return { ...state, dataProyeksByUser: action.payload};
        default:
            return state;
    }
};


export default proyekReducer;