import React, { Component, useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Axios from "axios";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import "./scss/style.scss";
import { useIdleTimer } from "react-idle-timer";
import { ProgressBar } from "src/components";

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ResetPasswordConfirm = React.lazy(() => import("./views/pages/login/ResetPasswordConfirm"));
const Registrasi = React.lazy(() => import("./views/pages/registrasi/Registrasi"));
const UploadBiayaKeanggotaan = React.lazy(() => import("./views/pages/registrasi/UploadBiayaKeanggotaan"));
const UploadFormRegistrasi = React.lazy(() => import("./views/pages/registrasi/UploadFormRegistrasi"));
const VerifikasiVendor = React.lazy(() => import("./views/pages/pengajuanBiaya/verifikasiVendor"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Tv = React.lazy(() => import("./views/pages/tv/Tv"));

const App = () => {
  var TOKEN = localStorage.getItem("TOKEN");
  useEffect(() => {
    GetInfoUser();
    // if(TOKEN == null)
    //   window.location.replace('/#/login')
  }, []);

  const GetInfoUser = () => {
    Axios.get(`${URLSVC}/Users/GetInfoUser`, {
      headers: { Authorization: "Bearer " + TOKEN },
    })
      .then((res) => {
        let data = res.data;
        if (data.isSuccess) {
          localStorage.setItem("SessionLogin", JSON.stringify(data.data));
        } else {
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        // if (err.response.status == 401) {
        //   localStorage.removeItem('TOKEN');
        // } else {
        //   Swal.fire('Terjadi Kesalahan', `${JSON.stringify(err.message)}`, 'error')
        // }
      });
  };

  /// handle activity on idle ////
  const logout = () => {
    localStorage.clear();
    window.location = "/";
  };

  const handleOnIdle = (event) => {
    if (TOKEN != null) {
      logout();
    }

    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    //console.log('user did something', event)
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  /// handle idle activity ////

  return (
    <BrowserRouter>
      <React.Suspense fallback={ProgressBar}>
        <Switch>
          <Route
            exact
            path="/reset-password-confirm"
            name="ResetPasswordConfirm"
            render={(props) => <ResetPasswordConfirm {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            exact
            path="/registrasi"
            name="Registrasi"
            render={(props) => <Registrasi {...props} />}
          />
          <Route
            exact
            path="/registrasi/upload-biaya-keanggotaan"
            name="UploadBiayaKeanggotaan"
            render={(props) => <UploadBiayaKeanggotaan {...props} />}
          />
          <Route
            exact
            path="/registrasi/upload-form-registrasi"
            name="UploadFormRegistrasi"
            render={(props) => <UploadFormRegistrasi {...props} />}
          />
          <Route
            exact
            path="/pengajuan-biaya/verifikasi-vendor"
            name="VerifikasiVendor"
            render={(props) => <VerifikasiVendor {...props} />}
          />
          <Route
            exact
            path="/login"
            name="login"
            render={(props) => <Login {...props} />}
          />
          <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          {/* {TOKEN != null ? (
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          ) : (
            <Route
              path="/"
              name="Halaman Login"
              render={(props) => <Login {...props} />}
            />
          )} */}
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
