const initialState = {
    dataComboMasterTable: [],
    dataComboAppl: [],
    dataComboApplTask: [],
    dataComboApplTaskParent: [],

    dataComboRole: [],
    dataComboTahun: [],
    dataComboBulan: [],

    dataComboTipeFile: [],
    dataComboProyekJenis: [],
    dataComboProyekTagihanJenis: [],
    dataComboProyekBiayaJenis: [],
    dataComboWaktu: [],
    dataComboJenisPembiayaan: [],

    dataComboJenisIdentitas: [],
    dataComboProvinsi: [],
    dataComboKabkot: [],
    dataComboJenisKelamin: [],
    dataComboPekerjaan: [],
    dataComboPendidikan: [],
    dataComboJenisKelamin: [],
    dataComboWargaNegara: [],
    dataComboAgama: [],

    dataComboPeriodeSimpananWajib: [],
    dataComboAnggota: []
};

const comboReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_COMBO_ROLE':
            return {...state, dataComboRole: action.payload }
        case 'DATA_COMBO_MASTER_TABLE':
            return {...state, dataComboMasterTable: action.payload }
        case 'DATA_COMBO_APPL':
            return {...state, dataComboAppl: action.payload }
        case 'DATA_COMBO_APPL_TASK':
            return {...state, dataComboApplTask: action.payload }
        case 'DATA_COMBO_APPL_TASK_PARENT':
            return {...state, dataComboApplTaskParent: action.payload }

        case 'DATA_COMBO_BULAN':
            return {...state, dataComboBulan: action.payload }
        case 'DATA_COMBO_TAHUN':
            return {...state, dataComboTahun: action.payload }

        case 'DATA_COMBO_TIPE_FILE':
            return {...state, dataComboTipeFile: action.payload }
        case 'DATA_COMBO_PROYEK_JENIS':
            return {...state, dataComboProyekJenis: action.payload }
        case 'DATA_COMBO_PROYEK_TAGIHAN_JENIS':
            return {...state, dataComboProyekTagihanJenis: action.payload }
        case 'DATA_COMBO_PROYEK_BIAYA_JENIS':
            return {...state, dataComboProyekBiayaJenis: action.payload }
        
        case 'DATA_COMBO_WAKTU':
            return {...state, dataComboWaktu: action.payload }
        case 'DATA_COMBO_JENIS_PEMBIAYAAN':
            return {...state, dataComboJenisPembiayaan: action.payload }

        case 'DATA_COMBO_JENIS_IDENTITAS':
            return {...state, dataComboJenisIdentitas: action.payload }
        case 'DATA_COMBO_PROVINSI':
            return {...state, dataComboProvinsi: action.payload }
        case 'DATA_COMBO_KABKOT':
            return {...state, dataComboKabkot: action.payload }
        case 'DATA_COMBO_JENIS_KELAMIN':
            return {...state, dataComboJenisKelamin: action.payload }
        case 'DATA_COMBO_PEKERJAAN':
            return {...state, dataComboPekerjaan: action.payload }
        case 'DATA_COMBO_PENDIDIKAN':
            return {...state, dataComboPendidikan: action.payload }
        case 'DATA_COMBO_WARGA_NEGARA':
            return {...state, dataComboWargaNegara: action.payload }
        case 'DATA_COMBO_AGAMA':
            return {...state, dataComboAgama: action.payload }

        case 'DATA_COMBO_PERIODE_SIMPANAN_WAJIB':
            return {...state, dataComboPeriodeSimpananWajib: action.payload }
        case 'DATA_COMBO_ANGGOTA':
            return {...state, dataComboAnggota: action.payload }
        default:
            return state
    }
};

export default comboReducer;