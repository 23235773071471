const initialState = {
    dataSimpanans: [],
    dataSimpanan: null,
    modalViewSimpanan: false,
    modalUploadSimpanan: false,
    modalVerifikasiSimpanan: false,
    modalSimpanan: false,
    modalSimpananSaldo: false,
    formAddSimpanan: {
      Id: "",
      Simpananname: "",
      Password: "",
      Email: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Address: "",
      PhoneNumber: "",
      MobileNumber: "",
      Role: "",
    },
  };
  
  const simpananReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_SIMPANANS":
        return { ...state, dataSimpanans: action.payload };
      case "DATA_SIMPANAN":
        return { ...state, dataSimpanan: action.payload };
      case "ADD_SIMPANAN":
        return {
          ...state,
          formAddSimpanan: {
            ...state.formAddSimpanan,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_ADD_SIMPANAN":
        return { ...state, formAddSimpanan: initialState.formAddSimpanan };
      case "MODAL_VIEW_SIMPANAN":
        return { ...state, modalViewSimpanan: action.payload };
      case "MODAL_UPLOAD_SIMPANAN":
        return { ...state, modalUploadSimpanan: action.payload };
      case "MODAL_VERIFIKASI_SIMPANAN":
        return { ...state, modalVerifikasiSimpanan: action.payload };
      case "MODAL_SIMPANAN":
        return { ...state, modalSimpanan: action.payload };
      case "MODAL_SIMPANAN_SALDO":
        return { ...state, modalSimpananSaldo: action.payload };
      default:
        return state;
    }
  };
  
  export default simpananReducer;
  