const initialState = {
    formProfile: {
        Address: '',
        Email: '',
        FileImage: '',
        FirstName: '',
        IdUser: '',
        IdRole: '',
        LastLogin: '',
        LastName: '',
        MiddleName: '',
        MobileNumber: '',
        Password: '',
        PhoneNumber: '',
        Roles: '',
        StrStatus: '',
        Username: '',
        NoKk: '',
        Nik: '',
        Npwp: '',
        TempatLahir: '',
        TanggalLahir: '',
        JenisKelamin: '',
        Agama: '',
        IdStatusPerkawinan: '',
        NamaPasangan: '',
        JumlahAnak: '',
    },
    fileImageProfilePreview: ''
}

const ubahProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FORM_INFO_USER':
            return {
                ...state,
                formProfile: {
                    ...state.formProfile,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_INFO_USER':
            return { ...state, formProfile: initialState.formProfile }
        case 'FILE_IMAGE_PROFILE_PREVIEW':
            return { ...state, fileImageProfilePreview: action.payload }
        default:
            return state
    }
}

export default ubahProfileReducer;