import Swal from 'sweetalert2';

export const setImgPreview = (type, payload) => {
    return { type, payload };
}

export const loadingBar = (bool = true) => {
    if (bool) {
        Swal.fire({
            title: 'Sedang Memuat',
            html: 'silahkan tunggu sebentar...',
            showConfirmButton: false,
            onBeforeOpen () {
                Swal.showLoading ()
            },
            onAfterClose () {
                Swal.hideLoading()
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
        })
    } else {
        Swal.close()
    }
}