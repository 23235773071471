import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
  CSelect,
  CRow,
  CNavbar,
  CModal,
  CForm,
  CSwitch,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CTextarea,
  CContainer,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CAlert,
  CImg
} from '@coreui/react'
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { DataTable, ReactSelect, ReactDatePicker } from "src/components";
import { FUNCAngkaToRupiah, FUNCCleanAngkaRupiah, FUNCIndoDate, FUNCMonthToString, FUNCNumberFormat, FUNCSetFullName, FUNCYearToString } from "src/config";
import { setDataCombo, setSimpanansByUser, setSimpanans } from "src/config/redux/action";
import './ViewDetil.css';

const ViewSimpananDetil = ({data}) => {
    useEffect(() => {
        if(data?.id != 0){
            reset();
            showModalViewSimpanan();
            setHeadingForm("Lihat Detil Simpanan Anggota")
        }
    }, [data])
  const { register:register, handleSubmit:handleSubmit, formState: { errors:errors }, control:control, setValue:setValue, getValues:getValues, reset:reset } = useForm();
  const { dataSimpanan, modalViewSimpanan } = useSelector(
    (state) => state.simpananReducer
  );
  const { 
    dataComboTipeFile,
   } = useSelector((state) => state.comboReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if(dataSimpanan){
        setValue("id",dataSimpanan.id);
        setValue("nama",dataSimpanan.nama);
        setValue("noAnggota",dataSimpanan.noAnggota);
        setValue("email",dataSimpanan.email);
        setValue("statusAnggota",dataSimpanan.statusAnggota);
    }
  }, [dataSimpanan])


  const [headingForm, setHeadingForm] = useState(false);

  const showModalViewSimpanan = () => dispatch({ type: 'MODAL_VIEW_SIMPANAN', payload: !modalViewSimpanan });

  return (
    <div>
      {/* SECTION MODAL Anggota  */}
      <CModal
        show={modalViewSimpanan}
        onClose={showModalViewSimpanan}
        size={"xl"}
    >
        <CModalHeader closeButton>
            {headingForm}
        </CModalHeader>
        <CModalBody>
            <CContainer>
                <form className="form-horizontal">
                    <fieldset disabled aria-readonly>
                        <input {...register("id")} name="id" type={"hidden"} className="form-control"  />
                        <div className="form-row">
                            <div className="col-md-3">
                                <div className='form-group'>
                                    <CLabel>Nama Anggota</CLabel>
                                    <input name="nama" className="form-control" placeholder="Ketikkan Nama Anggota..." {...register("nama", { required: 'Kolom wajib diisi' })}  />
                                    {errors.nama && <span className="text-danger">{errors.nama?.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className='form-group'>
                                    <CLabel>Nomor Anggota</CLabel>
                                    <input name="noAnggota" className="form-control" placeholder="Ketikkan Nama Anggota..." {...register("noAnggota", { required: 'Kolom wajib diisi' })}  />
                                    {errors.noAnggota && <span className="text-danger">{errors.noAnggota?.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className='form-group'>
                                    <CLabel>Email</CLabel>
                                    <input name="email" className="form-control" placeholder="Ketikkan Email..." {...register("email", { required: 'Kolom wajib diisi' })}  />
                                    {errors.email && <span className="text-danger">{errors.email?.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className='form-group'>
                                    <CLabel>Status</CLabel>
                                    <input name="statusAnggota" className="form-control" placeholder="Ketikkan Status Anggota..." {...register("statusAnggota", { required: 'Kolom wajib diisi' })}  />
                                    {errors.statusAnggota && <span className="text-danger">{errors.statusAnggota?.message}</span>}
                                </div>
                            </div>
                        </div>
                        {dataSimpanan && <div className="form-group">
                            <div className='alert alert-primary mt-3'>
                                <h5>Simpanan Anggota</h5>
                            </div>
                            <div style={{whiteSpace:"nowrap",overflowx:"auto",overflowy:"auto",maxHeight:500}} className="table-responsive mb-3">
                                <table className="table table-striped table-bordered table-hover nowrap mb-3" style={{width:"100%"}}>
                                    <thead>
                                        <tr>
                                            <th>Rupiah Pokok</th>
                                            <th>Rupiah Wajib</th>
                                            <th className="alert-info">Saldo Total</th>
                                            <th>Tunggakan Pokok</th>
                                            <th>Tunggakan Wajib</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Rp. {FUNCNumberFormat(dataSimpanan.rpTotalPokok)}</td>
                                            <td>Rp. {FUNCNumberFormat(dataSimpanan.rpTotalWajib)}</td>
                                            <td className="alert-info">Rp. {FUNCNumberFormat(dataSimpanan.rpTotalPokok + dataSimpanan.rpTotalWajib)}</td>
                                            <td>Rp. {FUNCNumberFormat(dataSimpanan.tgkPokok)}</td>
                                            <td>Rp. {FUNCNumberFormat(dataSimpanan.tgkWajib)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        <div className="form-group">
                            <div className='alert alert-primary mt-3'>
                                <h5>Detil Simpanan Anggota</h5>
                            </div>
                            <div style={{whiteSpace:"nowrap",overflowx:"auto",overflowy:"auto",maxHeight:500}} className="table-responsive mb-3">
                                <table className="table table-striped table-bordered table-hover nowrap mb-3" style={{width:"100%"}}>
                                    <thead>
                                        <tr>
                                            <th>Ref No</th>
                                            <th>Jenis Simpanan</th>
                                            <th>Jumlah Tunggakan</th>
                                            <th>Jatuh Tempo</th>
                                            <th>Jumlah Dibayar</th>
                                            <th>Tanggal Bayar</th>
                                            <th>File Bukti Bayar</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {   
                                        dataSimpanan && dataSimpanan.details && dataSimpanan.details.map((item,i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.refNo}</td>
                                                <td>{item.jenisSimpanan.name}</td>
                                                <td>Rp. {item.rp}</td>
                                                <td>{FUNCIndoDate(item.tanggalJatuhTempo)}</td>
                                                <td>{item.rpBayar &&"Rp. " + item.rpBayar}</td>
                                                <td>{FUNCIndoDate(item.tanggalBayar)}</td>
                                                <td>{item.fileBuktiBayar != null ? <a href={item.fileBuktiBayar} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</td>
                                                <td>{item.status?.status} {item.status?.statusProses} {item.status?.catatan}</td>
                                            </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </CContainer>
        </CModalBody>
        <CModalFooter>
            <CButton color="danger" onClick={showModalViewSimpanan}>TUTUP</CButton>{' '}
        </CModalFooter>
    </CModal>
    {/* END SECTION MODAL Anggota  */}
    </div>
  );
};

export default ViewSimpananDetil;