import React from 'react';
import TimePicker from 'react-time-picker';

function ReactTimePicker({...props}) {
    return (
        <TimePicker
            className="form-control"
            {...props}
        />
    )
}

export default ReactTimePicker;
