const initialState = {
    dataRoles: [],
    dataRole: null,
    modalRole: false,
    formAddRole: {
        Id: '',
        Name: ''
    }
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_ROLES':
            return { ...state, dataRoles: action.payload }
        case 'DATA_ROLE':
            return { ...state, dataRole: action.payload }
        case 'ADD_ROLE':
            return {
                ...state,
                formAddRole: {
                    ...state.formAddRole,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_ADD_ROLE':
            return { ...state, formAddRole: initialState.formAddRole }
        case 'MODAL_ROLE':
            return { ...state, modalRole: action.payload }
        default:
            return state
    }
}

export default userReducer;