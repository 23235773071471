import firebase from 'firebase';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyBvevKos8qojgul5-ajisPErLQFaznhPT0",
    authDomain: "swg-asysyirkah.firebaseapp.com",
    projectId: "swg-asysyirkah",
    storageBucket: "swg-asysyirkah.appspot.com",
    messagingSenderId: "930587375808",
    appId: "1:930587375808:web:49a0713f679db6af515525",
    measurementId: "G-FVDB8E2QWK"
  });
}

