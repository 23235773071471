import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
  CSelect,
  CRow,
  CNavbar,
  CModal,
  CForm,
  CSwitch,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CTextarea,
  CContainer,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CAlert,
  CImg
} from '@coreui/react'
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { DataTable, ReactSelect, ReactDatePicker } from "src/components";
import { FUNCDateDmytoYmd, FUNCMonthToString, FUNCNumberFormat, FUNCSetFullName, FUNCYearToString } from "src/config";
import { apiAddProyekUsulan, apiEditroyekUsulan, apiVerifikasiUsulan, setDataCombo, setInvestorInvestasi, setInvestorInvestasis } from "src/config/redux/action";
import './ViewDetil.css';

const ViewInvestorInvestasiDetil = ({data}) => {
    useEffect(() => {
        if(data?.id != 0){
            reset();
            dispatch(setInvestorInvestasi(data.id));
            showModalViewInvestorInvestasi();
            dispatch({ type: 'MODAL_VERIFIKASI_INVESTASI', payload: !modalVerifikasiInvestasi});
            dispatch({ type: 'MODAL_UPLOAD_INVESTASI', payload: !modalUploadInvestasi});
            setHeadingForm("Lihat Detil Investasi Investor")
        }
    }, [data])
  const { register:register, handleSubmit:handleSubmit, formState: { errors:errors }, control:control, setValue:setValue, getValues:getValues, reset:reset } = useForm();
  
  const { dataInvestorInvestasi, modalViewInvestorInvestasi, modalVerifikasiInvestasi, modalUploadInvestasi } = useSelector(
    (state) => state.proyekBiayaReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if(dataInvestorInvestasi){
      setValue("id",dataInvestorInvestasi.id);
      setValue("refNo",dataInvestorInvestasi.refNo);
      setValue("namaProyek",dataInvestorInvestasi.namaProyek);
      setValue("namaProyekInvestor",dataInvestorInvestasi.namaProyekInvestor);
      setValue("namaNasabah",dataInvestorInvestasi.namaNasabah);
      setValue("atasNamaProyek",dataInvestorInvestasi.atasNamaProyek);
      setValue("tanggalJatuhTempo", new Date(FUNCDateDmytoYmd(dataInvestorInvestasi.tanggalJatuhTempo)));
      setValue("rp", FUNCNumberFormat(dataInvestorInvestasi.rp));

      dataInvestorInvestasi.rpBayar && setValue("rpBayar", FUNCNumberFormat(dataInvestorInvestasi.rpBayar));
      dataInvestorInvestasi.tanggalBayar && setValue("tanggalBayar", new Date(FUNCDateDmytoYmd(dataInvestorInvestasi.tanggalBayar)));
    }
  }, [dataInvestorInvestasi])


  const [headingForm, setHeadingForm] = useState(false);

  const showModalViewInvestorInvestasi = () => dispatch({ type: 'MODAL_VIEW_INVESTOR_INVESTASI', payload: !modalViewInvestorInvestasi });
  const onEventClose = () => {
    dispatch({ type: 'MODAL_VERIFIKASI_INVESTASI', payload: !modalVerifikasiInvestasi});
    dispatch({ type: 'MODAL_UPLOAD_INVESTASI', payload: !modalUploadInvestasi});
    showModalViewInvestorInvestasi();
  }
  return (
    <div>
      {/* SECTION MODAL INVESTASI INVESTOR  */}
      <CModal
        show={modalViewInvestorInvestasi}
        onClose={() => onEventClose()}
        size={"lg"}
    >
        <CModalHeader closeButton>
            {headingForm}
        </CModalHeader>
        <CModalBody>
            <CContainer>
                <form className="form-horizontal">
                    <fieldset disabled aria-readonly>
                        <input {...register("id")} name="id" type={"hidden"} className="form-control"  />
                        {dataInvestorInvestasi && <div className="form-group">
                            <div className="form-row">
                                <div className="col-md-4">
                                    <CLabel className={"font-weight-bold"}>Jenis Biaya</CLabel>
                                    <div>{dataInvestorInvestasi.jenisBiaya?.name}</div>
                                </div>
                                <div className="col-md-4">
                                    <CLabel className={"font-weight-bold"}>Status Biaya</CLabel>
                                    <div>{dataInvestorInvestasi.statusBiaya?.status} {dataInvestorInvestasi.statusBiaya?.statusProses} {dataInvestorInvestasi.statusBiaya?.catatan}</div>
                                </div>
                                <div className="col-md-4">
                                    <CLabel className={"font-weight-bold"}>File Bukti Bayar</CLabel>
                                    <div>{dataInvestorInvestasi.fileBuktiBayar != null ? <a href={dataInvestorInvestasi.fileBuktiBayar} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                                </div>
                            </div>
                        </div>}

                        <div className="form-row">
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <CLabel>Ref No</CLabel>
                                    <input name="atasNama" className="form-control" placeholder="Ketikkan Ref No..." {...register("refNo", { required: 'Kolom wajib diisi' })}  />
                                    {errors.refNo && <span className="text-danger">{errors.refNo?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Nama Proyek</CLabel>
                                    <input name="namaProyek" className="form-control" placeholder="Ketikkan Nama Proyek..." {...register("namaProyek", { required: 'Kolom wajib diisi' })}  />
                                    {errors.namaProyek && <span className="text-danger">{errors.namaProyek?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Atas Nama Proyek</CLabel>
                                    <input name="atasNamaProyek" className="form-control" placeholder="Ketikkan Atas Nama Proyek..." {...register("atasNamaProyek", { required: 'Kolom wajib diisi' })}  />
                                    {errors.atasNamaProyek && <span className="text-danger">{errors.atasNamaProyek?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Nama Proyek Investor</CLabel>
                                    <input name="namaProyekInvestor" className="form-control" placeholder="Ketikkan Nama Proyek Investor..." {...register("namaProyekInvestor", { required: 'Kolom wajib diisi' })}  />
                                    {errors.namaProyekInvestor && <span className="text-danger">{errors.namaProyekInvestor?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Nama Nasabah</CLabel>
                                    <input name="namaNasabah" className="form-control" placeholder="Ketikkan Nama Nasabah..." {...register("namaNasabah", { required: 'Kolom wajib diisi' })}  />
                                    {errors.namaNasabah && <span className="text-danger">{errors.namaNasabah?.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <CLabel>Rupiah Tunggakan</CLabel>
                                    <input name="rp" className="form-control" placeholder="Ketikkan Nilai Proyek..." {...register("rp", { required: 'Kolom wajib diisi' })}  />
                                    {errors.rp && <span className="text-danger">{errors.rp?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Tanggal Jatuh Tempo</CLabel>
                                    <Controller
                                        control={control}
                                        defaultValue={""}
                                        name="tanggalJatuhTempo"
                                        render={({field, value, name, ref }) => (
                                            <ReactDatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={getValues("tanggalJatuhTempo")}
                                                onChange={(date) => field.onChange(date)}
                                                placeholderText="- Pilih Tanggal -"
                                            />
                                        )}
                                        rules={{required:true}}
                                    />
                                    {errors.tanggalJatuhTempo && <span className="text-danger">{errors.tanggalJatuhTempo?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Rupiah Bayar</CLabel>
                                    <input name="rpBayar" className="form-control" placeholder="Ketikkan Rupiah Bayar..." {...register("rpBayar", { required: 'Kolom wajib diisi' })}  />
                                    {errors.rpBayar && <span className="text-danger">{errors.rpBayar?.message}</span>}
                                </div>
                                <div className='form-group'>
                                    <CLabel>Tanggal Bayar</CLabel>
                                    <Controller
                                        control={control}
                                        defaultValue={""}
                                        name="tanggalBayar"
                                        render={({field, value, name, ref }) => (
                                            <ReactDatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={getValues("tanggalBayar")}
                                                onChange={(date) => field.onChange(date)}
                                                placeholderText="- Pilih Tanggal -"
                                            />
                                        )}
                                        rules={{required:true}}
                                    />
                                    {errors.tanggalBayar && <span className="text-danger">{errors.tanggalBayar?.message}</span>}
                                </div>
                            </div>
                        </div>
                        
                    </fieldset>
                </form>
            </CContainer>
        </CModalBody>
        <CModalFooter>
            <CButton color="danger" onClick={() => onEventClose()}>TUTUP</CButton>{' '}
        </CModalFooter>
    </CModal>
    {/* END SECTION MODAL INVESTASI INVESTOR  */}
    </div>
  );
};

export default ViewInvestorInvestasiDetil;