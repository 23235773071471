import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
  CSelect,
  CRow,
  CNavbar,
  CModal,
  CForm,
  CSwitch,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CTextarea,
  CContainer,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CAlert,
  CImg
} from '@coreui/react'
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { DataTable, ReactSelect, ReactDatePicker } from "src/components";
import { FUNCDateDmytoYmd, FUNCIndoDate, FUNCMonthToString, FUNCNumberFormat, FUNCSetFullName, FUNCYearToString } from "src/config";
import { setDataCombo, setProyekInvestor } from "src/config/redux/action";
import './ViewDetil.css';

const ViewInvestorDetil = ({data}) => {
    useEffect(() => {
        if(data?.id != 0){
            reset();
            showModalViewInvestor();
            setHeadingForm("Lihat Detil Investor")
        }
    }, [data])
  const { register:register, handleSubmit:handleSubmit, formState: { errors:errors }, control:control, setValue:setValue, getValues:getValues, reset:reset } = useForm();
  
  const dataUser = JSON.parse(localStorage.getItem("SessionLogin"))

  const { dataProyekInvestor, modalViewInvestor } = useSelector(
    (state) => state.proyekReducer
  );
  const { 
    dataComboProyekTagihanJenis,
    dataComboJenisIdentitas,
    dataComboWaktu,
   } = useSelector((state) => state.comboReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setDataCombo("DATA_COMBO_PROYEK_TAGIHAN_JENIS"));
    dispatch(setDataCombo("DATA_COMBO_JENIS_IDENTITAS"));
  }, [dispatch]);

  useEffect(() => {
    if(dataProyekInvestor != undefined){
      setValue("id",dataProyekInvestor.id);
      
      setValue("firstName",dataProyekInvestor.investor?.firstName);
      setValue("middleName",dataProyekInvestor.investor?.middleName);
      setValue("lastName",dataProyekInvestor.investor?.lastName);
      setValue("email",dataProyekInvestor.investor?.email);
      setValue("address",dataProyekInvestor.investor?.address);
      setValue("phoneNumber",dataProyekInvestor.investor?.phoneNumber);
      setValue("mobileNumber",dataProyekInvestor.investor?.mobileNumber);
      setValue("nomorIdentitas",dataProyekInvestor.investor?.nomorIdentitas);
      setValue("idJenisIdentitas",{value:dataProyekInvestor.investor?.identitas.id,label:dataProyekInvestor.investor?.identitas.name});
    
      dataProyekInvestor?.tanggalMinat && setValue("tanggalMinat", new Date(FUNCDateDmytoYmd(dataProyekInvestor?.tanggalMinat)));
      dataProyekInvestor?.rpMinat && setValue("rpMinat",FUNCNumberFormat(dataProyekInvestor?.rpMinat));
      dataProyekInvestor?.rpBayar && setValue("rpBayar",FUNCNumberFormat(dataProyekInvestor?.rpBayar));
      dataProyekInvestor?.rpModal && setValue("rpModal",FUNCNumberFormat(dataProyekInvestor?.rpModal));
      

      setValue("nama",dataProyekInvestor.proyek?.nama);
      setValue("tujuan",dataProyekInvestor.proyek?.tujuan);
      setValue("obyek",dataProyekInvestor.proyek?.obyek);
      dataProyekInvestor.proyek?.rpBiaya && setValue("rpBiaya", FUNCNumberFormat(dataProyekInvestor.proyek?.rpBiaya));
      dataProyekInvestor.proyek?.minTanggal && setValue("minTanggal", new Date(dataProyekInvestor.proyek?.minTanggal));
      dataProyekInvestor.proyek?.maxTanggal && setValue("maxTanggal", new Date(dataProyekInvestor.proyek?.maxTanggal));
      setValue("atasNama",dataProyekInvestor.proyek?.atasNama);
      setValue("jabatan",dataProyekInvestor.proyek?.jabatan);
      dataProyekInvestor.proyek?.rp && setValue("rp", FUNCNumberFormat(dataProyekInvestor.proyek?.rp));
      setValue("keterangan",dataProyekInvestor.proyek?.keterangan);
      setValue("noAnggota",dataProyekInvestor.proyek?.noAnggota);
      setValue("namaAnggota",dataProyekInvestor.proyek?.namaAnggota);
      setValue("emailAnggota",dataProyekInvestor.proyek?.emailAnggota);

      setValue("idJenisTagihan", {value:dataProyekInvestor.proyek?.jenisTagihan?.id, label:dataProyekInvestor.proyek?.jenisTagihan?.name});
      dataProyekInvestor.proyek?.totalTagihan && setValue("totalRpTagihan",FUNCNumberFormat(dataProyekInvestor.proyek?.totalTagihan));
      setValue("lamaTagihan",dataProyekInvestor.proyek?.lamaTagihan);

      setValue("namaPemohon", dataProyekInvestor.proyek?.namaAnggota);
      setValue("statusAnggota", dataProyekInvestor.proyek?.statusAnggota);
      setValue("tujuan", dataProyekInvestor.proyek?.tujuan);
      setValue("histori", dataProyekInvestor.proyek?.histori);
      setValue("nilaiUsulan", FUNCNumberFormat(dataProyekInvestor.proyek?.rp));
      setValue("nilaiProyek", FUNCNumberFormat(dataProyekInvestor.proyek?.nilaiProyek));
      setValue("kebutuhanModal", FUNCNumberFormat(dataProyekInvestor.proyek?.kebutuhanModal));
      setValue("estimasiRoi", FUNCNumberFormat(dataProyekInvestor.proyek?.estimasiRoi));
      setValue("jangkaWaktu", dataProyekInvestor.proyek?.jangkaWaktu);
      dataProyekInvestor.proyek?.minTanggalProposal && setValue("minTanggalProposal", new Date(dataProyekInvestor.proyek?.minTanggalProposal));
      dataProyekInvestor.proyek?.maxTanggalProposal && setValue("maxTanggalProposal", new Date(dataProyekInvestor.proyek?.maxTanggalProposal));

      if (dataProyekInvestor.proyek?.murabahah){
          setValue("Murabahah.Obyek", dataProyekInvestor.proyek.murabahah.obyek);
          setValue("Murabahah.Kuantiti", dataProyekInvestor.proyek.murabahah.kuantiti);
          setValue("Murabahah.Harga", FUNCNumberFormat(dataProyekInvestor.proyek.murabahah.harga));
          setValue("Murabahah.NilaiDp", FUNCNumberFormat(dataProyekInvestor.proyek.murabahah.nilaiDp));
          dataProyekInvestor.proyek.murabahah.tanggalJatuhTempo && setValue("Murabahah.TanggalJatuhTempo", new Date(dataProyekInvestor.proyek.murabahah.tanggalJatuhTempo));
          setValue("Murabahah.JumlahAngsuran", dataProyekInvestor.proyek.murabahah.jumlahAngsuran);
          dataProyekInvestor.proyek.murabahah.tanggalAwalAngsuran && setValue("Murabahah.TanggalAwalAngsuran", new Date(dataProyekInvestor.proyek.murabahah.tanggalAwalAngsuran));
          setValue("Murabahah.ProyeksiAngsuran", FUNCNumberFormat(dataProyekInvestor.proyek.murabahah.proyeksiAngsuran));
          setValue("Murabahah.GracePeriod", dataProyekInvestor.proyek.murabahah.gracePeriod);
          setValue("Murabahah.Margin", dataProyekInvestor.proyek.murabahah.margin);
          setValue("Murabahah.ProyeksiBiayaPerolehan", FUNCNumberFormat(dataProyekInvestor.proyek.murabahah.proyeksiBiayaPerolehan));
          setValue("Murabahah.EstimasiBulanan", FUNCNumberFormat(dataProyekInvestor.proyek.murabahah.estimasiBulanan));
      }
      
      if (dataProyekInvestor.proyek?.musyarakah){
          setValue("Musyarakah.IdWaktuTransfer", {value:dataProyekInvestor.proyek.musyarakah.idWaktuTransfer,label:dataProyekInvestor.proyek.musyarakah.waktuTransfer.name});
          setValue("Musyarakah.JumlahTransfer", dataProyekInvestor.proyek.musyarakah.jumlahTransfer);
          dataProyekInvestor.proyek.musyarakah.tanggalMulaiTransfer && setValue("Musyarakah.TanggalMulaiTransfer", new Date(dataProyekInvestor.proyek.musyarakah.tanggalMulaiTransfer));
          setValue("Musyarakah.ProyeksiMargin", FUNCNumberFormat(dataProyekInvestor.proyek.musyarakah.proyeksiMargin));
          setValue("Musyarakah.NisbahPengelola", dataProyekInvestor.proyek.musyarakah.nisbahPengelola);
          setValue("Musyarakah.NisbahInvestor", dataProyekInvestor.proyek.musyarakah.nisbahInvestor);
          setValue("Musyarakah.EstimasiPengelola", FUNCNumberFormat(dataProyekInvestor.proyek.musyarakah.estimasiPengelola));
          setValue("Musyarakah.EstimasiInvestor", FUNCNumberFormat(dataProyekInvestor.proyek.musyarakah.estimasiInvestor));
          setValue("Musyarakah.Ujrah", FUNCNumberFormat(dataProyekInvestor.proyek.musyarakah.ujrah));
          setValue("Musyarakah.RpBiayaSendiri", FUNCNumberFormat(dataProyekInvestor.proyek.musyarakah.rpBiayaSendiri));
          dataProyekInvestor.proyek.musyarakah.tanggalJatuhTempoBiayaSendiri && setValue("Musyarakah.TanggalJatuhTempoBiayaSendiri", new Date(dataProyekInvestor.proyek.musyarakah.tanggalJatuhTempoBiayaSendiri));
      }
    }
  }, [dataProyekInvestor])


  const [headingForm, setHeadingForm] = useState(false);

  const showModalViewInvestor = () => dispatch({ type: 'MODAL_VIEW_INVESTOR', payload: !modalViewInvestor });

  return (
    <div>
      {/* SECTION MODAL INVESTOR  */}
      <CModal
        show={modalViewInvestor}
        onClose={showModalViewInvestor}
        size={"xl"}
    >
        <CModalHeader closeButton>
            {headingForm}
        </CModalHeader>
        <CModalBody>
            <CContainer>
                <form className="form-horizontal">
                    <fieldset disabled aria-readonly>
                        <input {...register("id")} name="id" type={"hidden"} className="form-control"  />
                        <div className="form-row">
                            <div className="col-md-3">
                                <CLabel className={"font-weight-bold"}>Status</CLabel>
                                <div>{dataProyekInvestor.currentStatus?.status} {dataProyekInvestor.currentStatus?.statusProses}</div>
                            </div>
                            <div className="col-md-3">
                                <CLabel className={"font-weight-bold"}>Catatan Status</CLabel>
                                <div>{dataProyekInvestor.currentStatus?.catatan}</div>
                            </div>
                            <div className="col-md-3">
                                {dataProyekInvestor && <div className="form-group">
                                    <CLabel className={"font-weight-bold"}>File Akad Wakalah</CLabel>
                                    <div>{dataProyekInvestor.fileAkadWakalahTtd != null ? <a href={dataProyekInvestor.fileAkadWakalahTtd} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                                </div>}
                            </div>
                            <div className="col-md-3">
                                {dataProyekInvestor && <div className="form-group">
                                    <CLabel className={"font-weight-bold"}>File Bukti Bayar</CLabel>
                                    <div>{dataProyekInvestor.investasi?.fileBuktiBayar != null ? <a href={dataProyekInvestor.investasi?.fileBuktiBayar} className="badge badge-info p-2" target={"_blank"}>Lihat File</a> : <div className="badge badge-danger p-2">Belum di Upload</div>}</div>
                                </div>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <div className='form-group'>
                                    <CLabel>Nama Lengkap</CLabel>
                                    <CRow>
                                        <CCol>
                                            <div>
                                                <input name="firstName" className="form-control" placeholder="-" {...register("firstName", { required: 'Kolom wajib diisi' })}  />
                                                {errors.firstName && <span className="text-danger">{errors.firstName?.message}</span>}
                                            </div>
                                        </CCol>
                                        <CCol>
                                            <div>
                                                <input name="middleName" className="form-control" placeholder="-" {...register("middleName")}  />
                                                {errors.middleName && <span className="text-danger">{errors.middleName?.message}</span>}
                                            </div>
                                        </CCol>
                                        <CCol>
                                            <div>
                                                <input name="lastName" className="form-control" placeholder="-" {...register("lastName")}  />
                                                {errors.lastName && <span className="text-danger">{errors.lastName?.message}</span>}
                                            </div>
                                        </CCol>
                                    </CRow>
                                </div>
                                <CRow>
                                    <CCol>
                                        <div className="form-group">
                                            <CLabel>Jenis Identitas</CLabel>
                                            <Controller
                                                control={control}
                                                name={`idJenisIdentitas`}
                                                render={({field, value, name, ref }) => (
                                                    <ReactSelect
                                                        inputRef={ref}
                                                        value={getValues(`idJenisIdentitas`)}
                                                        options={dataComboJenisIdentitas}
                                                        onChange={(val) => { field.onChange(val)}}
                                                    />
                                                )}
                                                rules={{required:false}}
                                            />
                                            {errors?.idJenisIdentitas && <span className="text-danger">{"kolom ini wajib di isi"}</span>}
                                        </div>
                                    </CCol>
                                    <CCol>
                                        <div className='form-group'>
                                            <CLabel>Nomor Identitas</CLabel>
                                            <input name="nomorIdentitas" className="form-control" placeholder="Ketikkan Nomor Identitas..." {...register("nomorIdentitas", { required: 'Kolom wajib diisi' })}  />
                                            {errors.nomorIdentitas && <span className="text-danger">{errors.nomorIdentitas?.message}</span>}
                                        </div>
                                    </CCol>
                                </CRow>
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <CLabel>Email</CLabel>
                                            <input name="email" className="form-control" placeholder="Ketikkan Email..." {...register("email", { required: 'Kolom wajib diisi' })}  />
                                            {errors.email && <span className="text-danger">{errors.email?.message}</span>}
                                        </div>
                                        <div className='form-group'>
                                            <CLabel>Alamat</CLabel>
                                            <textarea name="address" className="form-control" placeholder="Ketikkan Alamat Lengkap..." {...register("address", { required: 'Kolom wajib diisi' })}></textarea>
                                            {errors.address && <span className="text-danger">{errors.address?.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <CLabel>No Telpon</CLabel>
                                            <input name="phoneNumber" className="form-control" placeholder="Ketikkan No Telpon..." {...register("phoneNumber", { required: 'Kolom wajib diisi' })}  />
                                            {errors.phoneNumber && <span className="text-danger">{errors.phoneNumber?.message}</span>}
                                        </div>
                                        <div className='form-group'>
                                            <CLabel>No HP</CLabel>
                                            <input name="mobileNumber" className="form-control" placeholder="Ketikkan No HP..." {...register("mobileNumber", { required: 'Kolom wajib diisi' })}  />
                                            {errors.mobileNumber && <span className="text-danger">{errors.mobileNumber?.message}</span>}
                                        </div>
                                    </div>
                                </div>


                                <div className="form-row">
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <CLabel>Tanggal Minat </CLabel>
                                            <Controller
                                                control={control}
                                                defaultValue={""}
                                                name="tanggalMinat"
                                                render={({field, value, name, ref }) => (
                                                    <ReactDatePicker
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={getValues("tanggalMinat")}
                                                        onChange={(date) => field.onChange(date)}
                                                        placeholderText="- Pilih Tanggal -"
                                                    />
                                                )}
                                                rules={{required:true}}
                                            />
                                            {errors.tanggalMinat && <span className="text-danger">{errors.tanggalMinat?.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <CLabel>Rupiah Minat</CLabel>
                                            <input name="rpMinat" className="form-control" placeholder="-" {...register("rpMinat", { required: 'Kolom wajib diisi' })}  />
                                            {errors.rpMinat && <span className="text-danger">{errors.rpMinat?.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <CLabel>Rupiah Bayar</CLabel>
                                            <input name="rpBayar" className="form-control" placeholder="-" {...register("rpBayar", { required: 'Kolom wajib diisi' })}  />
                                            {errors.rpBayar && <span className="text-danger">{errors.rpBayar?.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <CLabel>Rupiah Modal</CLabel>
                                            <input name="rpModal" className="form-control" placeholder="-" {...register("rpModal", { required: 'Kolom wajib diisi' })}  />
                                            {errors.rpModal && <span className="text-danger">{errors.rpModal?.message}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {dataProyekInvestor?.fileAkadWakalahTtd != null && dataUser.idRole != 2  && <div className="col-md-6">
                                <div className="form-group">
                                    <CLabel className={"font-weight-bold"}>File Akad Wakalah yang sudah di TTD</CLabel>
                                    <iframe src={`https://docs.google.com/viewer?url=${dataProyekInvestor?.fileAkadWakalahTtd}&embedded=true`} style={{border:0}} height="600px" width="100%"></iframe>
                                    <a href={dataProyekInvestor?.fileAkadWakalahTtd} className="btn btn-primary btn-block text-white p-2" target={"_blank"}>Download File</a>
                                </div>
                            </div>}
                            {dataProyekInvestor?.investasi?.fileBuktiBayar != null && dataUser.idRole != 2 && <div className="col-md-6">
                                <div className="form-group">
                                    <CLabel className={"font-weight-bold"}>File Bukti Bayar</CLabel>
                                    <iframe src={`https://docs.google.com/viewer?url=${dataProyekInvestor?.investasi?.fileBuktiBayar}&embedded=true`} style={{border:0}} height="600px" width="100%"></iframe>
                                    <a href={dataProyekInvestor?.investasi?.fileBuktiBayar} className="btn btn-primary btn-block text-white p-2" target={"_blank"}>Download File</a>
                                </div>
                            </div>}
                        </div>
                    </fieldset>
                </form>
            </CContainer>
        </CModalBody>
        <CModalFooter>
            <CButton color="danger" onClick={showModalViewInvestor}>TUTUP</CButton>{' '}
        </CModalFooter>
    </CModal>
    {/* END SECTION MODAL INVESTOR  */}
    </div>
  );
};

export default ViewInvestorDetil;