// export const URLFE = 'http://103.143.170.115:96';
// export const URLSVC = 'http://103.143.170.115:94/api';
// export const URL = 'http://103.143.170.115:94';

export const URLFE = "https://member.asysyirkah.id";
export const URLSVC = "https://api.asysyirkah.id/api";
export const URL = "https://api.asysyirkah.id";

// export const URL = 'https://localhost:7274';
// export const URLSVC = 'https://localhost:7274/api';

// export const URL = 'http://localhost:5031';
// export const URLSVC = 'http://localhost:5031/api';

// export const URL = 'https://177c-103-121-18-2.ngrok-free.app';
// export const URLSVC = 'https://177c-103-121-18-2.ngrok-free.app/api';

// export const URL = 'https://0889-103-121-18-2.ngrok-free.app';
// export const URLSVC = 'https://0889-103-121-18-2.ngrok-free.app/api';
