import { http } from 'src/config';
import firebase from 'firebase';
import { FUNCDateToString } from 'src/config/function';
import { URLSVC, URL } from 'src/config/helper/urlService';
import Swal from 'sweetalert2';
import { setDataComboProv, setDataComboKab, setDataComboKec, setDataComboDesa, setDataComboPenghasilanTetap, setDataComboStatusKk, setDataComboJenisBantuan, setDataComboJenisKelamin, setDataComboAgama, setDataComboJenisPendidikan, setDataComboJenisPekerjaan, setDataStatusKawin, setDataComboWarga, setDataComboStatusKkNik, setDataJenisGoldar } from './comboAction';
import { setImgPreview, loadingBar } from './globalAction';
import { setFormInfoUser } from '.';

export const askForPermissioToReceiveNotifications = async () => {
    try {
        const messaging = firebase.messaging();
        const token = await messaging.getToken();
        sessionStorage.setItem("TokenFcm",token);
        return token;
    } catch (error) {
        console.error(error);
    }
}

export const setDataUserInfo = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Users/GetInfoUser`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                localStorage.setItem('DATA_USER', JSON.stringify(data.data))
                dispatch({type: 'DATA_USER_SESSION', payload: data.data});

                dispatch(setFormInfoUser('Username', data.data.username));
                dispatch(setFormInfoUser('Email', data.data.email));
                dispatch(setFormInfoUser('FirstName', data.data.firstName));
                dispatch(setFormInfoUser('MiddleName', data.data.middleName));
                dispatch(setFormInfoUser('LastName', data.data.lastName));
                dispatch(setFormInfoUser('MobileNumber', data.data.mobileNumber));
                dispatch(setFormInfoUser('PhoneNumber', data.data.phoneNumber));
                dispatch(setFormInfoUser('Address', data.data.address));
                // dispatch(setFormInfoUser('IdRole', data.data.roles[0].idRole));

                dispatch(setImgPreview('FILE_IMAGE_PROFILE_PREVIEW', `${data.data.FileImage}?${new Date().getTime()}`));

            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error');
            }
        })
    }
}

export const setWebDashboard = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Laporans/GetWebDashboard`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                dispatch({ type: 'DATA_WEB_DASHBOARD', payload: data })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setMaps = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Admins/GetMap`).then((res) => {
            let data = res.data
            if (data.isSuccess) {
                dispatch({ type: 'DATA_MAPS', payload: data })
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}
