const initialState = {
    formUbahPassword: {
        Username: '',
        OldPassword: '',
        NewPassword1: '',
        NewPassword2: '',
    },
}

const ubahPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FORM_UBAH_PASSWORD':
            return {
                ...state,
                formUbahPassword: {
                    ...state.formUbahPassword,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_UBAH_PASSWORD':
            return { ...state, formUbahPassword: initialState.formUbahPassword }
        default:
            return state
    }
}

export default ubahPasswordReducer;