import { http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';
import { setDataRoles } from '../pengaturan/roleAction';

export const setDataRoleApplication = (IdRole,dataComboApplTask) => {
    return (dispatch) => {
        loadingBar(true);
        dispatch({type: 'RESET_FORM_ADD_ROLE_APPLICATION'})
        http.get(`${URLSVC}/Users/GetApplTaskByRole?roleId=${IdRole}`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({ type: 'DATA_ROLE_APPLICATION', payload: data.data })

                var arrayAssignTasks = [];
                dataComboApplTask.map((item,index) => {
                    data.data.AssignTasks.map((itemAssignTask,index) => {
                        if(itemAssignTask.IdApplTask == item.value){
                            arrayAssignTasks.push({value:item.value,label:item.label});
                        }
                    });
                })
                dispatch(setFormRoleApplication('Id', data.data.IdRole))
                dispatch(setFormRoleApplication('RoleName', data.data.RoleName))
                dispatch(setFormRoleApplication('AssignTasks', arrayAssignTasks))

                dispatch({type: 'MODAL_ROLE_APPLICATION', payload: true})
                
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}

export const setFormRoleApplication = (formType, formValue) => {
    return {type: 'ADD_ROLE_APPLICATION', formType, formValue}
}

export const apiUpdateRoleApplication = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('roleId', iData.Id);

        var arrayRoleApplication = [];
        iData.AssignTasks.map((item,index) => {
            arrayRoleApplication.push(item.value);
        })
        fd.append('idApplTasks', arrayRoleApplication);
        
        http.post(`${URLSVC}/Users/AddTasksRole`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire('Success', `You have successfully updated data role (${iData.Name})`, 'success')
                dispatch({ type: 'MODAL_ROLE_APPLICATION', payload: false })
                dispatch(setDataRoles());
            } else {
                Swal.fire('Fail', `${data.returnMessage}`, 'error')
            }
        })
    }
}