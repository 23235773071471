const initialState = {
    dataProyeks:[],
    dataProyekPengajuanBiayas: [],
    dataProyekPengajuanBiaya: null,
    dataRefProyekSyarats: null,
    modalViewProyekPengajuanBiaya: false,
    modalProyekPengajuanBiaya: false,
  };
  
  const proyekPengajuanBiayaReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_PROYEKS":
        return { ...state, dataProyeks: action.payload };
      case "DATA_PROYEK_PENGAJUAN_BIAYAS":
        return { ...state, dataProyekPengajuanBiayas: action.payload };
      case "DATA_PROYEK_PENGAJUAN_BIAYA":
        return { ...state, dataProyekPengajuanBiaya: action.payload };
      case "RESET_FORM_ADD_PROYEK_PENGAJUAN_BIAYA":
        return { ...state, formAddProyekPengajuanBiaya: initialState.formAddProyekPengajuanBiaya };
      case "MODAL_VIEW_PROYEK_PENGAJUAN_BIAYA":
        return { ...state, modalViewProyekPengajuanBiaya: action.payload };
      case "MODAL_PROYEK_PENGAJUAN_BIAYA":
        return { ...state, modalProyekPengajuanBiaya: action.payload };
      default:
        return state;
    }
  };
  
  export default proyekPengajuanBiayaReducer;