const initialState = {
    dataUserSession:{},
    dataWebDashboard: [],
    dataMaps: [],
}

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_USER_SESSION':
            return { ...state, dataUserSession: action.payload }
        case 'DATA_WEB_DASHBOARD':
            return { ...state, dataWebDashboard: action.payload }
        case 'DATA_MAPS':
            return { ...state, dataMaps: action.payload }
        default:
            return state
    }
}

export default homeReducer;